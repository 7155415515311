import { Row, Col } from "antd";
import LeftScreen from "./LeftScreen/LeftScreen";
import RightScreen from "./RightScreen/RightScreen";

const InfoScreen = () =>
    // { navigateuserName, setNavigateUsername }
    {
        return (
            <div className="signIn-container" style={{ position: "relative" }}>
                <Row>
                    <Col span={12}>
                        <LeftScreen
                        // navigateuserName={navigateuserName} setNavigateUsername={setNavigateUsername}
                        />
                    </Col>
                    <Col span={12}>
                        <RightScreen />
                    </Col>
                </Row>
            </div>
        );
    };

export default InfoScreen;
