import React from "react";
import styles from "./index.module.css";
import fire from "../../../../../../assets/icons/fire.gif";
import star from "../../../../../../assets/icons/star.png";
import { getBrandName } from "../../../../../Builder/RightContainer/Preview/utils";

function index({ productInfo }) {
    const styleCoupon = {
        fontFamily: "Gilroy-Medium !important",
        fontSize: "10px",
        fontWeight: 400,
        lineHeight: "16px"
    };

    const calculateDiscountPercentage = (actualPrice, discountedPrice) => {
        if (actualPrice === 0) return 0;
        const discount = actualPrice - discountedPrice;
        return Math.floor((discount / actualPrice) * 100);
    };

    return (
        <div
            className={styles.wrapper}
            onClick={() => {
                window.open(productInfo.metaData.response?.short_link, "_self");
            }}
        >
            <img src={productInfo?.metaData?.response.image} alt="product image" className={styles.productImg} />
            {productInfo?.metaData.response.offerMessage && (
                <div className={styles.ribbon}>
                    <span styles={styleCoupon}>Coupon:</span>
                    {productInfo.metaData.response.offerMessage}
                </div>
            )}
            {parseFloat(productInfo?.metaData?.response.rating) > 1 && (
                <div className={styles.ratingContainer}>
                    <p>{productInfo.metaData.response.rating}</p>
                    <img src={star} className={styles.ratingStar} alt="rating star" />
                </div>
            )}
            <div className={styles.priceDetails}>
                <p className={styles.brandName}>{getBrandName(productInfo?.metaData.domain)}</p>
                <div className={styles.descripition}>{productInfo?.metaData?.response.web_title}</div>
                <div className={styles.price}>
                    {productInfo.metaData?.response?.price?.discounted_price && (
                        <span>
                            {productInfo.metaData?.response?.price?.symbol}
                            {productInfo.metaData?.response?.price?.discounted_price}
                        </span>
                    )}
                    {productInfo.metaData?.response?.price?.discounted_price < productInfo.metaData?.response?.price?.actual_price && (
                        <span className={styles.originalPrice}>
                            <del>
                                {productInfo.metaData?.response?.price?.symbol}
                                {productInfo.metaData?.response?.price?.actual_price}
                            </del>
                        </span>
                    )}
                    {calculateDiscountPercentage(
                        productInfo.metaData?.response.price?.actual_price,
                        productInfo.metaData?.response.price?.discounted_price
                    ) > 0 && (
                        <span className={styles.discount}>
                            &#40;
                            {calculateDiscountPercentage(
                                productInfo.metaData?.response.price?.actual_price,
                                productInfo.metaData?.response.price?.discounted_price
                            )}
                            % Off &#41;
                        </span>
                    )}
                </div>
            </div>
        </div>
    );
}

export default index;
