import React from "react";
import ReactDOM from "react-dom/client";
import ReactGA from "react-ga4";
import "./index.scss";
import App from "./App";
import { BrowserRouter as Router } from "react-router-dom";
import mixpanel from "mixpanel-browser";
import { initSentry } from "./Services/sentry";

ReactGA.initialize("G-8FFJDGKX5Z");
if (window.location.pathname.includes("/i/")) {
    document.title = window.location.pathname.split("/").pop();
    ReactGA.initialize([
        {
            trackingId: "G-8FFJDGKX5Z",
            gaOptions: {
                userId: window.location.pathname.split("/").pop()
            }
        }
    ]);
}

mixpanel.init("d34cf4b10bd1cbf873f952eff235e5eb", {
    persistence: "localStorage"
});

// initSentry();
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <Router>
        <App />
    </Router>
);
