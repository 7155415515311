// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DefaultImg_defaultImageContainer__ShjlA {\n    margin: auto;\n    background-color: #3c3838;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    z-index: 1;\n    aspect-ratio: 1;\n}\n\n.DefaultImg_defaultImageContent__x0QQI {\n    color: white;\n    text-align: center;\n    font-family: \"Gilroy-Medium\";\n    line-height: 16px;\n    font-weight: 200;\n}\n", "",{"version":3,"sources":["webpack://./src/components/Profile/DefaultImg/index.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,yBAAyB;IACzB,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,UAAU;IACV,eAAe;AACnB;;AAEA;IACI,YAAY;IACZ,kBAAkB;IAClB,4BAA4B;IAC5B,iBAAiB;IACjB,gBAAgB;AACpB","sourcesContent":[".defaultImageContainer {\n    margin: auto;\n    background-color: #3c3838;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    z-index: 1;\n    aspect-ratio: 1;\n}\n\n.defaultImageContent {\n    color: white;\n    text-align: center;\n    font-family: \"Gilroy-Medium\";\n    line-height: 16px;\n    font-weight: 200;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"defaultImageContainer": "DefaultImg_defaultImageContainer__ShjlA",
	"defaultImageContent": "DefaultImg_defaultImageContent__x0QQI"
};
export default ___CSS_LOADER_EXPORT___;
