import React, { useState, useRef } from "react";
import styles from "./index.module.css";
import searchIcon from "../../../../assets/icons/search_icon.png";
import arrow from "../../../../assets/wall-icons/back_arrow.png";
import { addEvent } from "../../../../Services/mixpanel";
import { MixpanelEvents } from "../../../../constants/mixpanelEvents";

const index = ({ isExpanded, setIsExpanded, searchValue, setSearchValue }) => {
    const searchInputRef = useRef(null);

    const handleIconClick = () => {
        setIsExpanded(true);
        searchInputRef.current.focus();
        addEvent({}, MixpanelEvents.SEARCH_ICON_CLICKED);
    };

    const handleBlur = () => {
        if (searchValue === "") {
            setIsExpanded(false);
        }
    };

    const handleChange = (e) => {
        setSearchValue(e.target.value);
    };

    const clearSearch = () => {
        setSearchValue("");
        searchInputRef.current.focus();
        addEvent({}, MixpanelEvents.SEARCH_CLEARED);
    };

    return (
        <div className={styles.container} style={{ flex: isExpanded ? "1" : "", width: isExpanded ? "100%" : "" }}>
            <div className={`${styles.searchBar} ${isExpanded ? styles.expanded : ""}`}>
                <img
                    src={arrow}
                    alt="Back Arrow"
                    className={styles.arrow}
                    onClick={() => {
                        setIsExpanded(false);
                        setSearchValue("");
                        addEvent({}, MixpanelEvents.SEARCH_CLOSED);
                    }}
                />
                <div className={styles.searchInputbBox}>
                    <input
                        type="text"
                        placeholder="Search in Faym Wall.."
                        ref={searchInputRef}
                        className={`${styles.searchInput} `}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={searchValue}
                    />
                    {searchValue && <div className={styles.cross} onClick={clearSearch}></div>}
                </div>
            </div>

            {!isExpanded && (
                <div className={styles.search} onClick={handleIconClick}>
                    <img src={searchIcon} alt="Search Icon" />
                </div>
            )}
        </div>
    );
};

export default index;
