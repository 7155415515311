import "./leftScreen.scss";
import faymLogo from "../../../assets/faymLogo.png";
import InfoForm from "../../Forms/InfoForm/InfoForm";
import { useLocation } from "react-router-dom";

const LeftScreen = () =>
    // { navigateuserName, setNavigateUsername }
    {
        const location = useLocation();
        const username = location.state;
        return (
            <div className="signIn-leftScreen-container">
                <div className="top-container">
                    <img draggable={false} src={faymLogo} alt="faym-logo" />
                    <h1 style={{ marginTop: "30px" }}>Choose Your Username</h1>
                    <p style={{ marginTop: "1px" }}>Add your Username to Personalize your Experience</p>
                </div>
                <div className="bottom-container">
                    <InfoForm
                        username={username}
                        // navigateuserName={navigateuserName} setNavigateUsername={setNavigateUsername}
                    />
                </div>
            </div>
        );
    };

export default LeftScreen;
