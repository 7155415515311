import React, { useState, useEffect } from "react";
import { Form, message } from "antd";
import FloatInput from "../../CommonV2/FloatInput/FloatInput.jsx";
import { Button } from "antd";
import "./mobileForm.scss";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { MobileNumberLogin } from "../../../Services/validations";
import { getAuth } from "firebase/auth";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { useNavigate } from "react-router-dom";
import { postAPIData, saveDataToLocal } from "../../../Services/utils";
import { addEvent } from "../../../Services/mixpanel.js";
import { MixpanelEvents, MixpanelPlatform } from "../../../constants/mixpanelEvents.js";
import { useDispatch } from "react-redux";
import { login } from "../../../redux/slice/authSlice.js";
import { useLocation } from "react-router-dom";

const firebaseConfig = {
    apiKey: "AIzaSyCprsqdh6gmVeeRr1iCVJZmXqJoHeGe2R8",
    authDomain: "faym-wall-builder.firebaseapp.com",
    projectId: "faym-wall-builder",
    storageBucket: "faym-wall-builder.appspot.com",
    messagingSenderId: "12137555081",
    appId: "1:12137555081:web:50ba80e362bd9a52bb5258",
    measurementId: "G-06SC91HS9R"
};
firebase.initializeApp(firebaseConfig);
const mAuth = firebase.auth();

const MobileForm = ({ loading, setLoading = () => {}, type }) => {
    const dispatch = useDispatch();
    const auth = getAuth();
    const navigate = useNavigate();
    const [otpVisible, setOtpVisible] = useState(false);
    const [mobileNum, setMobileNum] = useState("");
    const [verificationId, setVerificationId] = useState("");
    const [counter, setCounter] = useState(0);
    const {
        handleSubmit,
        control,
        setValue,
        formState: { errors }
    } = useForm({
        mode: "onSubmit",
        defaultValues: {
            mobile: "",
            mobileOTP: "",
            isOtpVisible: false
        },
        resolver: yupResolver(MobileNumberLogin)
    });
    const useQuery = () => {
        return new URLSearchParams(useLocation().search);
    };
    const paramValue = useQuery().get("username");

    const sendOTP = async (data) => {
        try {
            setCounter(30);
            setLoading(true);
            const userData = await postAPIData(
                {
                    url: "auth/send/otp",
                    data: { phoneNumber: data }
                },
                false
            );
            setMobileNum(data);
            setOtpVisible(true);
            setValue("isOtpVisible", true);
        } catch (error) {
            console.error(error);
            message.error(error.message);
        } finally {
            setLoading(false);
        }
    };
    const verifyOTP = async (data) => {
        try {
            setLoading(true);
            const userData = await postAPIData(
                {
                    url: "auth/verify/otp",
                    data: {
                        phoneNumber: data.mobile,
                        otp: data.mobileOTP
                    }
                },
                false
            );
            dispatch(
                login({
                    user: userData?.data,
                    idToken: userData.headers.idtoken
                })
            );
            localStorage.clear();
            if (userData?.data?.firstName && userData?.data?.userName) {
                saveDataToLocal(userData?.data?.firstName, "userName");
                addEvent(userData.data, MixpanelEvents.SIGN_IN_SUCCESSFULL, MixpanelPlatform.MOBILE);
                navigate("/dashboard");
            } else {
                addEvent({}, MixpanelEvents.LOADED_USERNAME_PAGE);
                navigate("/infoscreen", { state: paramValue });
            }
            setOtpVisible(false);
            setValue("isOtpVisible", false);
        } catch (err) {
            console.error(err);
            message.error(err.message);
        } finally {
            setLoading(false);
        }
    };
    const getFormData = (data) => {
        sendOTP(data?.mobile);
        addEvent(data, MixpanelEvents.SIGN_IN_INITIATED, MixpanelPlatform.MOBILE);
    };
    const getOtpData = (data) => {
        verifyOTP(data);
    };
    useEffect(() => {
        const timer = counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
        return () => clearInterval(timer);
    }, [counter]);
    return (
        <div className="signIn-form-container">
            {!otpVisible ? (
                <Form size="large" name="mobile_login" className="login-form" layout="vertical" onFinish={handleSubmit(getFormData)}>
                    <Form.Item name="mobile">
                        <FloatInput
                            control={control}
                            error={errors}
                            label="Mobile Number"
                            placeholder="Mobile Number"
                            name="mobile"
                            type="number"
                        />
                    </Form.Item>
                    <div id="recaptcha-container"></div>
                    <Form.Item className="sigIn-btn">
                        <Button type="primary" loading={loading} danger block htmlType="submit" className="login-form-button">
                            Continue with Phone Number
                        </Button>
                    </Form.Item>
                </Form>
            ) : (
                <Form size="large" name="otp_verification" className="login-form" layout="vertical" onFinish={handleSubmit(getOtpData)}>
                    <Form.Item name="mobileOTP">
                        <FloatInput
                            control={control}
                            error={errors}
                            label="Enter OTP"
                            placeholder="Enter OTP"
                            name="mobileOTP"
                            type="number"
                        />
                    </Form.Item>
                    <Form.Item className="resendCode-container">
                        <span>Didn't Receive SMS? </span>
                        {counter > 0 ? (
                            <span style={{ fontFamily: "Poppins" }}>
                                Resend OTP in <span style={{ color: "red" }}> 00:{counter.toString().padStart(2, "0")}</span>{" "}
                            </span>
                        ) : (
                            <a
                                className="resendOTP"
                                onClick={() => {
                                    sendOTP(mobileNum);
                                }}
                            >
                                Resend OTP
                            </a>
                        )}
                    </Form.Item>

                    <Form.Item className="sigIn-btn">
                        <Button type="primary" loading={loading} danger block htmlType="submit" className="login-form-button">
                            Continue
                        </Button>
                    </Form.Item>
                </Form>
            )}
        </div>
    );
};

export default MobileForm;
