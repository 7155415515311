// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "body.Modal_modalOpen__3d1HV {\n    overflow: hidden;\n}\n\n.Modal_modal__7tE18 {\n    position: fixed;\n    left: 0;\n    top: 0;\n    right: 0;\n    bottom: 0;\n    background-color: rgba(0, 0, 0, 0.5);\n    display: flex;\n    justify-content: center;\n    z-index: 26;\n    animation: Modal_popupFadeIn__9pfe7 0.2s ease-in-out;\n}\n\n@keyframes Modal_popupFadeIn__9pfe7 {\n    from {\n        opacity: 0;\n        transform: translateY(-20px);\n    }\n\n    to {\n        opacity: 1;\n        transform: translateY(0);\n    }\n}\n", "",{"version":3,"sources":["webpack://./src/components/CommonV2/Modal/index.module.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;AACpB;;AAEA;IACI,eAAe;IACf,OAAO;IACP,MAAM;IACN,QAAQ;IACR,SAAS;IACT,oCAAoC;IACpC,aAAa;IACb,uBAAuB;IACvB,WAAW;IACX,oDAAuC;AAC3C;;AAEA;IACI;QACI,UAAU;QACV,4BAA4B;IAChC;;IAEA;QACI,UAAU;QACV,wBAAwB;IAC5B;AACJ","sourcesContent":["body.modalOpen {\n    overflow: hidden;\n}\n\n.modal {\n    position: fixed;\n    left: 0;\n    top: 0;\n    right: 0;\n    bottom: 0;\n    background-color: rgba(0, 0, 0, 0.5);\n    display: flex;\n    justify-content: center;\n    z-index: 26;\n    animation: popupFadeIn 0.2s ease-in-out;\n}\n\n@keyframes popupFadeIn {\n    from {\n        opacity: 0;\n        transform: translateY(-20px);\n    }\n\n    to {\n        opacity: 1;\n        transform: translateY(0);\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalOpen": "Modal_modalOpen__3d1HV",
	"modal": "Modal_modal__7tE18",
	"popupFadeIn": "Modal_popupFadeIn__9pfe7"
};
export default ___CSS_LOADER_EXPORT___;
