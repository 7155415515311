import React, { useState } from "react";
import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";
import "./floatInput.scss";
import { Input } from "antd";
import { Controller } from "react-hook-form";
const FloatInput = ({ control, error, name, label, placeholder, type, required, addonBefore, username }) => {
    const [value, setValue] = useState(username || "");
    const [passwordVisible, setPasswordVisible] = useState(false); // State to track password visibility

    if (!placeholder) placeholder = label;

    const isOccupied = value && value.length;
    const labelClass = isOccupied ? "label as-label" : "label as-placeholder";
    const requiredMark = required ? <span className="text-danger">*</span> : null;

    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };
    return (
        <div className="float-label">
            {control ? (
                <>
                    {" "}
                    <Controller
                        control={control}
                        name={name}
                        render={({ field }) => {
                            return (
                                <>
                                    <Input
                                        className="float-input-field"
                                        onChange={(e) => {
                                            field.onChange(e?.target?.value);
                                            setValue(e.target.value);
                                        }}
                                        type={passwordVisible ? "text" : type || "text"}
                                        addonBefore={addonBefore}
                                        suffix={
                                            type === "password" && (
                                                <span onClick={togglePasswordVisibility} className="password-toggle-icon">
                                                    {passwordVisible ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                                                </span>
                                            )
                                        }
                                        defaultValue={username || ""}
                                    />
                                    <label className={labelClass}>
                                        {isOccupied ? label : placeholder} {requiredMark}
                                    </label>
                                </>
                            );
                        }}
                    />
                    {error?.[name ?? ""] && (
                        <span className={``} style={{ color: "red", fontSize: "15px" }}>
                            <>{error?.[name ?? ""]?.message.toString()}</>
                        </span>
                    )}
                </>
            ) : (
                <>
                    {" "}
                    <Input
                        type={passwordVisible ? "text" : type || "text"}
                        addonBefore={addonBefore}
                        suffix={
                            type === "password" && (
                                <span onClick={togglePasswordVisibility} className="password-toggle-icon">
                                    {passwordVisible ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                                </span>
                            )
                        }
                    />
                    <label className={labelClass}>
                        {isOccupied ? label : placeholder} {requiredMark}
                    </label>
                </>
            )}
        </div>
    );
};

export default FloatInput;
