import React from "react";
import styles from "./index.module.css";
import Product from "../Product";

function index({ products }) {
    return (
        <div className={styles.productsGrid}>
            {products?.map((e, i) => (
                <div className={styles.productCell} key={i}>
                    <Product productInfo={e} />
                </div>
            ))}
        </div>
    );
}

export default index;
