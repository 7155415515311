import React, { useEffect } from "react";
import magicVand from "../../../../../assets/magicVand.png";
import { setMagicButtonState } from "../../../../../redux/slice/dashboardSlice";
import styles from "./index.module.css";
import { useDispatch, useSelector } from "react-redux";
import { magicButtonStates } from "../../../../../enums";

const index = ({ handleKeyDown, handleOnChange, magicLinkButtonClick, setMagicButtonClick }) => {
    const dispatch = useDispatch();
    const { magicLinkInput } = useSelector((state) => state.root.dashboard);
    const placeholderAnimation = () => {
        let input = document.querySelector(`.${styles.input}`);
        let sliderBox = document.querySelector(`.${styles.sliderBox}`);
        let sliderItem = document.querySelector(`.${styles.sliderItem}`);
        let placeholder = document.querySelector(`.${styles.placeholder}`);

        let list = [
            "Social Media",
            "Products",
            "Reels",
            "YouTube Shorts",
            "YouTube Videos",
            "Storefronts",
            "Apps",
            "Websites, and more..."
        ];

        let i = 0;
        let intervals;
        if (sliderBox && sliderItem) {
            sliderBox.style.height = sliderItem.clientHeight + "px";
        }
        //function to hide the place holder
        input.onfocus = function () {
            placeholder.style.display = "none";
            clearInterval(intervals);
        };

        input.onblur = function () {
            if (input.value === "") {
                placeholder.style.display = "flex";
                i = 0;
                text_animation();
                setintervals();
            }
        };
        //functionality of animated text
        function setintervals() {
            intervals = setInterval(() => {
                text_animation();
            }, 2000);
        }

        function text_animation() {
            sliderItem.innerHTML = list[i];
            sliderItem.style.opacity = "0";
            sliderItem.style.top = "-10px"; // Initial position off the top of the screen

            setTimeout(() => {
                sliderItem.style.opacity = "1"; // Make the slider visible
                sliderItem.style.transition = ""; // Add transition effect
                sliderItem.style.top = "0px"; // Move the slider to the desired position
            }, 800);

            setTimeout(() => {
                sliderItem.style.transition = ""; // Reset transition property
                sliderItem.style.opacity = "0"; // Make the slider invisible again
                sliderItem.style.top = "10px"; // Move the slider further down
            }, 1700); // Adjust timing as needed

            i = (i + 1) % list.length;
        }
        input.focus();
        input.blur();
    };

    useEffect(() => {
        placeholderAnimation();
    }, []);

    useEffect(() => {
        let input = document.querySelector(`.${styles.input}`);
        if (!magicLinkInput && magicLinkButtonClick) {
            dispatch(setMagicButtonState(magicButtonStates.INITIAL));
            setMagicButtonClick(false);
            input.focus();
            input.blur();
        }
    }, [magicLinkInput]);

    return (
        <div
            style={{
                backgroundColor: "white",
                display: "flex",
                alignItems: "center",
                margin: "25px 20px 25px 20px",
                borderRadius: "5px",
                width: "65%",
                height: "30px",
                position: "relative"
            }}
        >
            <div
                style={{
                    backgroundImage: `url(${magicVand})`,
                    width: "16px",
                    height: "16px",
                    backgroundSize: "cover",
                    margin: "7px 10px 7px 7px"
                }}
            ></div>
            <input
                className={styles.input}
                onChange={(e) => handleOnChange(e)}
                onKeyDown={(e) => handleKeyDown(e)}
                value={magicLinkInput}
            />
            <div className={styles.placeholder}>
                <div className={styles.fixedBox}>
                    <p>Start adding links to your</p>
                </div>
                <div className={styles.sliderBox}>
                    <div className={styles.sliderItem}>Social Media</div>
                </div>
            </div>
        </div>
    );
};

export default index;
