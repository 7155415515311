import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    profileUpdate: null,
    storeSectionsOriginal: null,
    nonStoreSectionsOriginal: null,
    storeSectionsChanged: null,
    nonStoreSectionsChanged: null,
    postsOriginal: null,
    postsChanged: null,
    pinnedPostsOriginal: null,
    pinnedPostsChanged: null,
    linksOriginal: null,
    linksChanged: null,
    selectedSection: null,
    descriptionEditing: null,
    magicLinkInput: null,
    magicLinkInputCopy: null,
    magicLinkResponse: null,
    magicButtonState: null,
    selectedHeader: null,
    isSectionPickModalOpen: null,
    nonStoreAddedTemplateType: null,
    isSectionDeleteModalOpen: null,
    isSectionCreateModalOpen: null,
    selectedPreview: null,
    sectionsDiff: null,
    linksDiff: null,
    publishButtonEnabled: null,
    isPublishedSuccessModalOpen: null,
    pluginSelected: null,
    pluginsData: null,
    instaAuthModalOpen: null,
    instaAuth: null,
    publishLoading: null,
    isSectionDeleting: null,
    selectedCollection: null
};

const dashboardSlice = createSlice({
    name: "dashboard",
    initialState,
    reducers: {
        updateProfileData: (state, action) => {
            state.profileUpdate = { ...action.payload };
        },
        setStoreSectionsOriginal: (state, action) => {
            state.storeSectionsOriginal = action.payload;
        },
        setNonStoreSectionsOriginal: (state, action) => {
            state.nonStoreSectionsOriginal = action.payload;
        },
        setStoreSectionsChanged: (state, action) => {
            state.storeSectionsChanged = action.payload;
        },
        setNonStoreSectionsChanged: (state, action) => {
            state.nonStoreSectionsChanged = action.payload;
        },
        setPostsOriginal: (state, action) => {
            state.postsOriginal = action.payload;
        },
        setPostsChanged: (state, action) => {
            state.postsChanged = action.payload;
        },
        setPinnedPostsOriginal: (state, action) => {
            state.pinnedPostsOriginal = action.payload;
        },
        setPinnedPostsChanged: (state, action) => {
            state.pinnedPostsChanged = action.payload;
        },
        addLinkToStoreSections: (state, action) => {
            state.storeSectionsChanged = state.storeSectionsChanged.map((section) => {
                if (section.id == action.payload.section.id) {
                    return {
                        ...section,
                        links: [action.payload.link, ...section.links]
                    };
                }
                return section;
            });
        },
        addLinkToNonStoreSections: (state, action) => {
            state.nonStoreSectionsChanged = state.nonStoreSectionsChanged.map((section) => {
                if (section.id == action.payload.section.id) {
                    return {
                        ...section,
                        links: [action.payload.link, ...section.links]
                    };
                }
                return section;
            });
        },
        setLinksOriginal: (state) => {
            const storeSectionsOriginal = JSON.parse(JSON.stringify(state.storeSectionsOriginal)) ?? [];
            const nonStoreSectionsOriginal = JSON.parse(JSON.stringify(state.nonStoreSectionsOriginal));
            state.linksOriginal = [...storeSectionsOriginal.flatMap((e) => e?.links), ...nonStoreSectionsOriginal.flatMap((e) => e.links)];
        },
        setLinksChanged: (state) => {
            const storeSectionsChanged = JSON.parse(JSON.stringify(state?.storeSectionsChanged)) ?? [];
            const nonStoreSectionsChanged = JSON.parse(JSON.stringify(state.nonStoreSectionsChanged));
            state.linksChanged = [...storeSectionsChanged.flatMap((e) => e.links), ...nonStoreSectionsChanged.flatMap((e) => e.links)];
        },
        setSelectedSection: (state, action) => {
            state.selectedSection = action.payload;
        },
        setDescriptionEditing: (state, action) => {
            state.descriptionEditing = action.payload;
        },
        setMagicLinkInput: (state, action) => {
            state.magicLinkInput = action.payload;
        },
        setMagicLinkInputCopy: (state) => {
            state.magicLinkInputCopy = state.magicLinkInput;
        },
        setMagicLinkResponse: (state, action) => {
            state.magicLinkResponse = action.payload;
        },
        setMagicButtonState: (state, action) => {
            state.magicButtonState = action.payload;
        },
        setSelectedHeader: (state, action) => {
            state.selectedHeader = action.payload;
        },
        openSectionPickModal: (state) => {
            state.isSectionPickModalOpen = true;
        },
        closeSectionPickModal: (state) => {
            state.isSectionPickModalOpen = false;
        },
        setNonStoreAddedTemplateType: (state, action) => {
            state.nonStoreAddedTemplateType = action.payload;
        },
        openSectionDeleteModal: (state) => {
            state.isSectionDeleteModalOpen = true;
        },
        closeSectionDeleteModal: (state) => {
            state.isSectionDeleteModalOpen = false;
        },
        openSectionCreateModal: (state) => {
            state.isSectionCreateModalOpen = true;
        },
        closeSectionCreateModal: (state) => {
            state.isSectionCreateModalOpen = false;
        },
        setSelectedPreview: (state, action) => {
            state.selectedPreview = action.payload;
        },
        setSectionsDiff: (state, action) => {
            state.sectionsDiff = action.payload;
        },
        setLinksDiff: (state, action) => {
            state.linksDiff = action.payload;
        },
        setPublishButtonEnabled: (state, action) => {
            state.publishButtonEnabled = action.payload;
        },
        openPublishSuccessModal: (state) => {
            state.isPublishedSuccessModalOpen = true;
        },
        closePublishSuccessModal: (state) => {
            state.isPublishedSuccessModalOpen = false;
        },
        setPluginSelected: (state, action) => {
            state.pluginSelected = action.payload;
        },
        setPluginsData: (state, action) => {
            state.pluginsData = action.payload;
        },
        setInstaAuthModalOpen: (state) => {
            state.instaAuthModalOpen = true;
        },
        setInstaAuthModalClose: (state) => {
            state.instaAuthModalOpen = false;
        },
        setInstaAuth: (state, action) => {
            state.instaAuth = action.payload;
        },
        showPublishLoader: (state, action) => {
            state.publishLoading = action.payload;
        },
        setIsSectionDeleting: (state, action) => {
            state.isSectionDeleting = action.payload;
        },
        setSelectedCollection: (state, action) => {
            state.selectedCollection = action.payload;
        }
    }
});

export const {
    updateProfileData,
    setStoreSectionsOriginal,
    setNonStoreSectionsOriginal,
    setStoreSectionsChanged,
    setNonStoreSectionsChanged,
    setPostsOriginal,
    setPostsChanged,
    setLinksOriginal,
    setLinksChanged,
    setPinnedPostsOriginal,
    setPinnedPostsChanged,
    addLinkToStoreSections,
    addLinkToNonStoreSections,
    duplicateStoreSection,
    setSelectedSection,
    setDescriptionEditing,
    setMagicLinkInput,
    setMagicLinkInputCopy,
    setMagicLinkResponse,
    setMagicButtonState,
    setSelectedHeader,
    openSectionPickModal,
    closeSectionPickModal,
    setNonStoreAddedTemplateType,
    openSectionDeleteModal,
    closeSectionDeleteModal,
    openSectionCreateModal,
    closeSectionCreateModal,
    setSelectedPreview,
    setSectionsDiff,
    setLinksDiff,
    setPublishButtonEnabled,
    openPublishSuccessModal,
    closePublishSuccessModal,
    setPluginSelected,
    setPluginsData,
    setInstaAuthModalOpen,
    setInstaAuthModalClose,
    setInstaAuth,
    showPublishLoader,
    setIsSectionDeleting,
    setSelectedCollection
} = dashboardSlice.actions;
export const selectDashboard = (state) => state.dashboard;
export default dashboardSlice.reducer;
