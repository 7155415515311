import React from "react";
import Sublime from "./Headers/Sublime";
import Matrix from "./Headers/Matrix";
import Breeze from "./Headers/Breeze";
import styles from "./index.module.css";
import faymLogo from "../../../assets/icons/faym_logo.png";
import SocialLinks from "./LinksComponents/SocialMediaLinks";
import ShortsAndReelsLinks from "./LinksComponents/ShortsAndReelsLinks";
import AppsLinks from "./LinksComponents/AppsLinks";
import { useSelector } from "react-redux";
import { addEvent } from "../../../Services/mixpanel";
import { MixpanelEvents } from "../../../constants/mixpanelEvents";

const index = () => {
    const { user, linkInBioData } = useSelector((state) => state.root.wall);

    const renderThemeComponent = () => {
        switch (user?.theme.theme) {
            case "Sublime":
                return <Sublime />;
            case "Matrix":
                return <Matrix />;
            case "Breeze":
                return <Breeze />;
            default:
                return "";
        }
    };

    return (
        <div className={styles.container}>
            <img className={styles.linkInBioBackground} src={user?.theme.items[0].backgroundUrl} />
            <div className={styles.linkInBioPreview}>
                <div className={styles.header}></div>
                {renderThemeComponent()}
                {linkInBioData?.map((e, i) => {
                    const { templateType, links, title } = e;
                    switch (templateType) {
                        case "application":
                        case "podcast":
                        case "other":
                            if (links.length > 0) {
                                return <AppsLinks key={i} id={i} data={links} title={title} templateType={templateType} />;
                            }
                            break;

                        case "social_media":
                            if (links.length > 0) {
                                return <SocialLinks key={i} id={i} data={links} title={title} />;
                            }
                            break;

                        case "youtube_shorts":
                        case "insta_reels":
                            if (links.length > 0) {
                                return <ShortsAndReelsLinks key={i} id={i} data={links} title={title} type={templateType} />;
                            }
                            break;

                        default:
                            return <div key={i}></div>;
                    }
                })}
                <div className={styles.emptyDiv}></div>
                <div className={styles.faymFooter}>
                    <a
                        href="https://faym.co"
                        target="_blank"
                        rel="noopener noreferrer"
                        className={styles.aTag}
                        onClick={() => {
                            addEvent({}, MixpanelEvents.OPENED_WEBSITE);
                        }}
                    >
                        <img src={faymLogo} alt="faym logo" className={styles.faymLogo} />
                    </a>
                </div>
            </div>
        </div>
    );
};

export default index;
