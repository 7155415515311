// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MagicLink_magicLinkContainer__qu\\+C6 {\n    width: 78%;\n    display: flex;\n    align-items: center;\n    background-size: cover;\n    border-radius: 10px;\n    margin: 1.8125em 0;\n    margin-top: 2.1875em;\n}\n", "",{"version":3,"sources":["webpack://./src/components/Builder/LeftContainer/MagicLink/index.module.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,aAAa;IACb,mBAAmB;IACnB,sBAAsB;IACtB,mBAAmB;IACnB,kBAAkB;IAClB,oBAAoB;AACxB","sourcesContent":[".magicLinkContainer {\n    width: 78%;\n    display: flex;\n    align-items: center;\n    background-size: cover;\n    border-radius: 10px;\n    margin: 1.8125em 0;\n    margin-top: 2.1875em;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"magicLinkContainer": "MagicLink_magicLinkContainer__qu+C6"
};
export default ___CSS_LOADER_EXPORT___;
