import React from "react";
import styles from "./index.module.css";

const index = () => {
    return (
        <div className={styles.loaderContainer}>
            <div className={styles.loader} />
        </div>
    );
};

export default index;
