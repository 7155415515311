import { getAPIData } from "../utils";
import store from "../../redux/store";
import { updateUser, updateWaitlistStatus } from "../../redux/slice/authSlice";

export const getUserData = async () => {
    try {
        await getAPIData({
            url: "api/user"
        }).then((userResponse) => {
            store.dispatch(updateUser(userResponse?.data));
            store.dispatch(updateWaitlistStatus(userResponse?.data?.waitListStatus));
        });
    } catch (error) {
        console.error(error);
    }
};
