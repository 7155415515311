import "./navbar.scss";
import faymLogo from "../../../assets/faymLogo.png";
import profileImgInactive from "../../../assets/dashboard/userProfile.png";
import profileImgActive from "../../../assets/dashboard/profileActive.svg";
import dashboardLinkImg from "../../../assets/icons/link.svg";
import dashboardLinkImgInactive from "../../../assets/icons/dashboardInactive.svg";
import analyticsLinkImg from "../../../assets/icons/analytics.svg";
import analyticsLinkImgInactive from "../../../assets/icons/analyticsInactive.svg";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import DefaultImg from "../../Profile/DefaultImg";
const Navbar = ({ analytics, currPage }) => {
    const { user } = useSelector((state) => state.root.auth);
    const borderColor = currPage === "profile" ? "#E9474C" : "black";
    const navigate = useNavigate();
    return (
        <div className={analytics ? "analytics-navbar" : "dashboard-navbar"} style={{ fontSize: "14px" }}>
            <img style={{ cursor: "pointer" }} onClick={() => navigate("/dashboard")} src={faymLogo} alt="faym-logo" />
            <ul className="dashboard-navlinks">
                <li
                    onClick={() => {
                        navigate("/dashboard");
                    }}
                    style={{
                        color: `${currPage === "dashboard" ? "#E9474C" : "black"}`
                    }}
                >
                    <img src={currPage === "dashboard" ? dashboardLinkImg : dashboardLinkImgInactive} alt="dashboard-link" />
                    <span>Dashboard</span>
                </li>
                <li
                    // className="li-disabled"
                    style={{
                        color: `${currPage === "analytics" ? "#E9474C" : "black"}`
                    }}
                    onClick={() => {
                        navigate("/analytics");
                    }}
                >
                    <img src={currPage === "analytics" ? analyticsLinkImg : analyticsLinkImgInactive} alt="dashboard-analytics" />
                    <span>Analytics</span>
                </li>
                <li
                    style={{
                        color: `${currPage === "profile" ? "#E9474C" : "black"}`
                    }}
                    onClick={() => {
                        navigate("/profile");
                    }}
                >
                    {user.profileImg ? (
                        <img
                            src={user && user.profileImg ? user.profileImg : profileImgActive}
                            alt="profile-icon"
                            className="profile-image"
                            style={{ borderColor }}
                        />
                    ) : (
                        <div style={{ border: `1.5px solid ${borderColor}`, borderRadius: "50%", margin: "auto" }}>
                            <DefaultImg height={"25px"} width={"25px"} radius={"50%"} />
                        </div>
                    )}
                </li>
            </ul>
        </div>
    );
};
export default Navbar;
