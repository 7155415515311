import mixpanel from "mixpanel-browser";
import { MixpanelEvents } from "../constants/mixpanelEvents";

const addEvent = (data, eventType, platform) => {
    switch (eventType) {
        case MixpanelEvents.SIGN_IN_INITIATED:
        case MixpanelEvents.SIGN_IN_FAILED:
        case MixpanelEvents.SIGN_IN_SUCCESSFULL:
        case MixpanelEvents.LOADED_DASHBOARD_PAGE:
            _signUpEvent(data, eventType, platform);
            break;
        case MixpanelEvents.LOGOUT_CLICKED:
            _logOutEvent(data, eventType, platform);
            break;
        case MixpanelEvents.PUBLISHED_FAYMWALL:
        case MixpanelEvents.COPY_LINK_FAYMWALL:
        case MixpanelEvents.VIEW_FAYMWALL:
        case MixpanelEvents.LOADED_USERNAME_PAGE:
        case MixpanelEvents.LOADED_ANALYTICS_PAGE:
        case MixpanelEvents.LOADED_PROFILE_PAGE:
        case MixpanelEvents.LOADED_SIGNUP_PAGE:
        case MixpanelEvents.PRODUCT_LINK_CLICKED:
        case MixpanelEvents.FLIPKART_AFFILIATE_LINK_CLICKED:
        case MixpanelEvents.STORE_ICON_CLICKED:
        case MixpanelEvents.SEARCH_ICON_CLICKED:
        case MixpanelEvents.DOTD_CLICKED:
        case MixpanelEvents.PRODUCT_CATEGORIES_CLICKED:
        case MixpanelEvents.PRODUCT_BRANDS_CLICKED:
            _loadPageEvent(data, eventType);
            break;
        case MixpanelEvents.LOADED_FAYMWALL:
            _loadPageEvent(data, eventType);
            break;
        case MixpanelEvents.USER_IMAGE_UPLOADED:
        case MixpanelEvents.USER_PROFILE_UPDATED:
            _userProfileUpdateEvent(data, eventType);
            break;
        case MixpanelEvents.MAGIC_LINK:
            _trackMagicLinkEvent(data, eventType);
            break;
        case MixpanelEvents.PRODUCT_SHARE_CLICKED:
        case MixpanelEvents.PRODUCT_WHATSAPP_SHARE_CLICKED:
        case MixpanelEvents.PRODUCT_INSTAGRAM_SHARE_CLICKED:
        case MixpanelEvents.PRODUCT_TELEGRAM_SHARE_CLICKED:
        case MixpanelEvents.PRODUCT_FACEBOOK_SHARE_CLICKED:
        case MixpanelEvents.PRODUCT_LINK_COPY_CLICKED:
        case MixpanelEvents.SHARED_PRODUCT_PAGE_OPENED:
            _productShareClickEvent(data, eventType);
            break;
        default:
            break;
    }
};

const _addUser = (data) => {
    mixpanel.people.set({
        $distinct_id: data.userName,
        $name: data?.firstName,
        $email: data?.email,
        $phone_number: data?.phoneNumber
    });
};

const _signUpEvent = (data, eventType, platform) => {
    if (data.userName) {
        mixpanel.identify(data.userName);
    }
    mixpanel.track(eventType, {
        $phoneNumber: data?.phoneNumber,
        $email: data?.email,
        platform: platform
    });
    _addUser(data);
};

const _logOutEvent = (data, eventType, platform) => {
    mixpanel.track(eventType, { ...data, platform });
    mixpanel.reset();
};

const _loadPageEvent = (data, eventType) => {
    mixpanel.track(eventType, data);
};

const _userProfileUpdateEvent = (data, eventType) => {
    mixpanel.track(eventType, data);
};

const _trackMagicLinkEvent = (data, eventType) => {
    mixpanel.track(eventType, data);
};

const _productShareClickEvent = (data, eventType) => {
    mixpanel.track(eventType, data);
};

export { addEvent };
