import React from "react";
import { socialMediaImages } from "../../../../Builder/builderServices/utils";
import arrow from "../../../../../assets/wall-icons/arrow.svg";
import blackArrow from "../../../../../assets/wall-icons/black-arrow.svg";
import styles from "./index.module.css";
import { addEvent } from "../../../../../Services/mixpanel";
import { MixpanelEvents } from "../../../../../constants/mixpanelEvents";

function index({ data, title }) {
    function truncateString(str, maxLength) {
        if (str?.length > maxLength) {
            return str.substring(0, maxLength - 3) + "...";
        }
        return str;
    }

    return (
        <div className={styles.container}>
            <h1 className={styles.heading}>{title} </h1>

            {data?.map((e, i) => {
                if (e?.metaData?.response?.social_media) {
                    const socialMediaImageIndex = Object.keys(socialMediaImages).find((key) =>
                        e?.metaData?.response?.social_media?.includes(key)
                    );

                    const platformData = socialMediaImages[socialMediaImageIndex] || {};
                    return (
                        <div
                            onClick={() => {
                                window.open(e.url, "_blank");
                                addEvent({ url: e.url }, MixpanelEvents.LINK_IN_BIO_ITEM_CLICKED);
                            }}
                            className={styles.socialMediaContainer}
                            style={{
                                cursor: "pointer",
                                backgroundColor: platformData?.backgroundColor
                            }}
                            key={i}
                        >
                            <div className={styles.socialMediaDetails}>
                                <p style={{ color: platformData.title.includes("Snapchat") ? "black" : "" }}>{platformData.title}</p>
                                <img src={platformData.title.includes("Snapchat") ? blackArrow : arrow} alt="arrow img" />
                            </div>

                            <div className={styles.socialMediaIcon}>
                                {platformData.image && <img src={platformData.image || ""} alt="Social Media Icon" />}
                            </div>
                        </div>
                    );
                } else if (e?.metaData?.domain.includes("youtube.com")) {
                    return (
                        <div className={styles.youtubeContainer} key={i}>
                            <iframe title="youtube-video" src={`https://www.youtube.com/embed/${e.url}`} id="youtube-link"></iframe>
                            <div className={styles.videoDescripition}>
                                <p className={styles.videoText}>{truncateString(e.metaData.response.product_title, 78)}</p>
                                <div className={styles.ytButton}>
                                    <div span={6} className={styles.ytButtonAlign}>
                                        <a
                                            href={`https://www.youtube.com/@${e.metaData.response.channel}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <img
                                                src={"https://www.gstatic.com/youtube/img/branding/youtubelogo/svg/youtubelogo.svg"}
                                                alt="youtube-channel-logo"
                                            />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                }
            })}
        </div>
    );
}

export default index;
