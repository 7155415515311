import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Accordion from "./../../../CommonV2/Accordion";
import { SortableContainer, SortableElement, SortableHandle } from "react-sortable-hoc";
import { arrayMoveImmutable } from "array-move";
import styles from "./index.module.css";
import sectionDrag from "../../../../assets/sectionDrag.svg";
import MagicLink from "../MagicLink";
import Modal from "../../../CommonV2/Modal";
import { addLinkToStoreSectionsWrapper, addNewLinkWithNewStoreSection } from "../../../../Services/apiServices/linkService";
import {
    closeSectionDeleteModal,
    closeSectionPickModal,
    openSectionDeleteModal,
    setMagicLinkInput,
    closeSectionCreateModal,
    openSectionCreateModal,
    setStoreSectionsChanged,
    setLinksChanged
} from "../../../../redux/slice/dashboardSlice";
import addSection from "./../../../../assets/addSection.png";
import deleteSectionIcon from "./../../../../assets/deleteSection.png";
import { duplicateStoreSection, deleteSection, findDiff } from "../../builderServices/utils";
import { message } from "antd";
import Loader from "./../../../CommonV2/Loader";

const DragHandle = SortableHandle(() => <img className={styles.sectionDrag} src={sectionDrag} />);

const SortableItem = SortableElement(({ value, setSection }) => {
    const [isCollapsed, setIsCollapsed] = useState(false);

    const dispatch = useDispatch();
    return (
        <>
            <div className={styles.accordion}>
                <DragHandle />
                <Accordion section={value} isCollapsed={isCollapsed} setIsCollapsed={setIsCollapsed} />
                {!isCollapsed ? (
                    <div className={styles.sectionActions}>
                        <img className={styles.addSection} src={addSection} onClick={() => duplicateStoreSection(value)} />
                        <img
                            className={styles.deleteSection}
                            src={deleteSectionIcon}
                            onClick={() => {
                                dispatch(openSectionDeleteModal(true));
                                setSection(value);
                            }}
                        />
                    </div>
                ) : (
                    <div className={styles.sectionActions}>
                        <div className={styles.addSection}></div>
                        <div className={styles.deleteSection}></div>
                    </div>
                )}
            </div>
        </>
    );
});

const SortableList = SortableContainer(({ items, setSection }) => {
    return (
        <div className={styles.linksContainer}>
            {items.map((value, index) => (
                <SortableItem key={`item-${value.id}`} setSection={setSection} index={index} value={value} />
            ))}
        </div>
    );
});

const index = () => {
    const dispatch = useDispatch();
    const { storeSectionsChanged, isSectionPickModalOpen, magicLinkResponse, isSectionDeleteModalOpen, isSectionCreateModalOpen } =
        useSelector((state) => state.root.dashboard);
    const [list, setList] = useState(storeSectionsChanged);
    const [title, setTitle] = useState("");
    const [section, setSection] = useState();
    const onSortEnd = ({ oldIndex, newIndex }) => {
        setList(arrayMoveImmutable(list, oldIndex, newIndex));
        let storeSectionsChangedCopy = [...storeSectionsChanged];
        let tempSortId = storeSectionsChangedCopy[newIndex].sortId;
        if (newIndex > oldIndex) {
            storeSectionsChangedCopy = storeSectionsChangedCopy.map((section, i) => {
                if (i > oldIndex && i <= newIndex) {
                    return {
                        ...section,
                        sortId: storeSectionsChangedCopy[i - 1].sortId
                    };
                }
                return section;
            });
        } else if (oldIndex > newIndex) {
            storeSectionsChangedCopy = storeSectionsChangedCopy.map((section, i) => {
                if (i >= newIndex && i < oldIndex) {
                    return {
                        ...section,
                        sortId: storeSectionsChangedCopy[i + 1].sortId
                    };
                }
                return section;
            });
        }
        storeSectionsChangedCopy[oldIndex] = {
            ...storeSectionsChangedCopy[oldIndex],
            sortId: tempSortId
        };
        const item = storeSectionsChangedCopy.splice(oldIndex, 1)[0];
        storeSectionsChangedCopy.splice(newIndex, 0, item);
        dispatch(setStoreSectionsChanged(storeSectionsChangedCopy));
        dispatch(setLinksChanged());
        findDiff();
    };

    useEffect(() => {
        setList(storeSectionsChanged);
    }, [storeSectionsChanged]);

    return (
        <>
            <MagicLink screen="Store" />
            <SortableList items={list} setSection={setSection} onSortEnd={onSortEnd} useDragHandle />
            {storeSectionsChanged.length > 1 && (
                <Modal
                    showModal={isSectionPickModalOpen}
                    alignItems={"center"}
                    onClose={() => {
                        dispatch(closeSectionPickModal(false));
                        dispatch(setMagicLinkInput(""));
                    }}
                >
                    <div className={styles.sectionsListModal}>
                        <div className={styles.sectionsListHeading}>Choose a section</div>
                        <div className={styles.sectionsList}>
                            {storeSectionsChanged.map((e, i) => {
                                return (
                                    <div key={i} onClick={() => addLinkToStoreSectionsWrapper(e, magicLinkResponse)}>
                                        <div className={styles.sectionsListItem} key={i}>
                                            {e?.title}
                                        </div>
                                        <div className={styles.sectionsListItemSaperator}></div>
                                    </div>
                                );
                            })}
                            <div>
                                <div className={styles.sectionsListItem} onClick={() => dispatch(openSectionCreateModal(true))}>
                                    + Add New Section
                                </div>
                                <div className={styles.sectionsListItemSaperator}></div>
                            </div>
                        </div>
                    </div>
                </Modal>
            )}
            <Modal
                showModal={isSectionDeleteModalOpen}
                alignItems={"center"}
                onClose={() => {
                    dispatch(closeSectionDeleteModal(false));
                }}
            >
                <div className={styles.deleteModal}>
                    <div className={styles.sectionsListHeading}>Delete section</div>
                    <div className={styles.deleteModalContent}>
                        <div>Are you sure you want to delete this section?</div>
                        <div>This will be gone forever.</div>
                        <div className={styles.buttonGroup}>
                            <div className={styles.cancelButton}>Cancel</div>
                            <div
                                className={styles.deleteButton}
                                onClick={async () => {
                                    await deleteSection(section);
                                    message.success("Section Deleted");
                                }}
                            >
                                Delete
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
            <Modal showModal={isSectionCreateModalOpen} alignItems={"center"} onClose={() => dispatch(closeSectionCreateModal(false))}>
                <div className={styles.sectionsListModal}>
                    <div className={styles.sectionsListHeading}>Add Section Title</div>
                    <div className={styles.titleInputContent}>
                        <input
                            className={styles.titleInput}
                            onClick={(e) => e.stopPropagation()}
                            placeholder="Enter Title"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                        />
                        <div className={styles.buttonGroup}>
                            <div className={styles.cancelButton}>Cancel</div>
                            <div
                                className={styles.deleteButton}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    if (title.length > 0) {
                                        addNewLinkWithNewStoreSection(title);
                                        dispatch(closeSectionCreateModal(false));
                                        message.success("Added link to New Section");
                                    } else {
                                        message.info("Section Title is empty");
                                    }
                                }}
                            >
                                Done
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default index;
