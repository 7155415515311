import { getAPIData } from "../utils";
import store from "../../redux/store";
import { setInstaAuth, setPluginsData } from "../../redux/slice/dashboardSlice";

export const getPlugins = async () => {
    try {
        const pluginsRes = await getAPIData({ url: "api/dashboard/plugins" });
        store.dispatch(setPluginsData(pluginsRes.data));
        store.dispatch(setInstaAuth(pluginsRes.data.filter((e) => e.title === "INSTAGRAM")[0].status === "INTEGRATED"));
    } catch (err) {
        console.error(err);
    }
};
