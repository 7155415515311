import React, { useState, useEffect } from "react";
import styles from "./index.module.css";
import ProductGrid from "../ProductsGrid";
import { getBrandName } from "../../../Builder/RightContainer/Preview/utils";
import { addEvent } from "../../../../Services/mixpanel";
import { MixpanelEvents } from "../../../../constants/mixpanelEvents";

function Index({ searchValue, uniqueProductList, categories, brands }) {
    const [activeCategory, setActiveCategory] = useState("All");
    const [filteredProducts, setFilteredProducts] = useState([]);

    const normalizeString = (str) => (str ? str.replace(/\s+/g, "").toLowerCase() : "");

    useEffect(() => {
        const filterProducts = () => {
            let filtered = uniqueProductList;

            const normalizedSearchValue = normalizeString(searchValue.trim());

            if (normalizedSearchValue.length >= 3) {
                filtered = filtered.filter((product) => {
                    const normalizedTitle = normalizeString(product.metaData?.response?.web_title);
                    const normalizedCategory = normalizeString(product.metaData?.response?.categories?.join(" "));
                    const normalizedBrand = normalizeString(getBrandName(product.metaData?.domain));

                    return (
                        normalizedTitle.includes(normalizedSearchValue) ||
                        normalizedCategory.includes(normalizedSearchValue) ||
                        normalizedBrand.includes(normalizedSearchValue)
                    );
                });
            } else if (activeCategory !== "All") {
                filtered = filtered.filter((product) => product.metaData?.response?.categories?.includes(activeCategory));
            }

            setFilteredProducts(filtered);
        };

        filterProducts();
    }, [searchValue, activeCategory, uniqueProductList, brands]);

    return (
        <div className={styles.container}>
            {searchValue.trim().length < 3 && (
                <>
                    {categories?.length > 0 && (
                        <div className={styles.categoriesContainer}>
                            <button
                                className={`${styles.categoryButton} ${activeCategory === "All" ? styles.active : ""}`}
                                onClick={() => setActiveCategory("All")}
                            >
                                All
                            </button>
                            {categories.map((category, index) => (
                                <button
                                    key={index}
                                    className={`${styles.categoryButton} ${activeCategory === category.category ? styles.active : ""}`}
                                    onClick={() => {
                                        setActiveCategory(category.category);
                                        addEvent({}, MixpanelEvents.SELECTED_CATEGORY + `${category.category}`);
                                    }}
                                >
                                    <img src={category.icon} alt={category.category} className={styles.categoryIcon} />
                                    {category.category}
                                </button>
                            ))}
                        </div>
                    )}
                    {activeCategory === "All" && <h2 className={styles.heading}>Recommended Products</h2>}
                </>
            )}
            {filteredProducts.length > 0 && <ProductGrid products={filteredProducts} />}
            {filteredProducts.length === 0 && <p style={{ textAlign: "center" }}>No products found.</p>}
        </div>
    );
}

export default Index;
