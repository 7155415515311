import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isAuthenticated: false,
    user: null,
    idToken: null,
    refreshToken: null,
    themes: null,
    waitlistStatus: null
};

const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        login: (state, action) => {
            state.isAuthenticated = true;
            state.user = action.payload.user;
            state.idToken = action.payload.idToken;
            state.refreshToken = action.payload.refreshToken;
        },
        updateUser: (state, action) => {
            state.user = action.payload;
        },
        updateIdToken: (state, action) => {
            state.idToken = action.payload;
        },
        updateUserProperties: (state, action) => {
            state.user = { ...state.user, ...action.payload };
        },
        updateThemes: (state, action) => {
            state.themes = action.payload;
        },
        updateWaitlistStatus: (state, action) => {
            state.waitlistStatus = action.payload;
        },
        logout: (state) => {
            state.isAuthenticated = false;
            state.user = null;
            state.idToken = null;
            state.refreshToken = null;
        }
    }
});

export const { login, updateUser, updateIdToken, updateUserProperties, updateThemes, updateSocialData, updateWaitlistStatus, logout } =
    authSlice.actions;
export const selectAuth = (state) => state.auth;
export default authSlice.reducer;
