import React, { useEffect, useState, useRef } from "react";
import styles from "./index.module.css";
import defaultImage from "../../../../assets/wall-icons/UserIcon.png";
import arrow from "../../../../assets/wall-icons/back_arrow.png";
import shareIcon from "../../../../assets/icons/shareIcon.png";
import faym from "../../../../assets/icons/faym_logo.png";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import Product from "../Product/index";
import ShareModal from "../ShareBottomSheet/index.jsx";
import { useDispatch, useSelector } from "react-redux";
import {
    updateShareModalDescripition,
    updateShareModalImage,
    updateShareModalOpen,
    updateShareShortLink
} from "../../../../redux/slice/wallSlice.js";
import { getSharedCollection, getuserData, getProducts } from "../../../../Services/apiServices/wallService.js";
import { addEvent } from "../../../../Services/mixpanel.js";
import { MixpanelEvents } from "../../../../constants/mixpanelEvents.js";
import NotFoundScreen from "../../NotFoundScreen/index.jsx";

function index() {
    const { id } = useParams();
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { user, collectionData } = useSelector((state) => state.root.wall);
    const [userData, setUserData] = useState();
    const [products, setProducts] = useState();
    const [notFound, setNotFound] = useState(false);
    const scrollableDivRef = useRef(null);
    const [requestSent, setRequestSent] = useState(false);
    const [responselength, setResponseLength] = useState(10);

    const fetchData = async () => {
        try {
            if (user) {
                setUserData(user);
            }

            if (collectionData?.length) {
                let filterData = collectionData.filter((ele) => ele.id === id);
                setProducts(filterData[0]);
            } else {
                const response = await getSharedCollection(id);
                if (response.status === 200) {
                    setProducts(response?.data?.collections);
                    setUserData(response?.data?.user);
                } else {
                    userData?.userName ? navigate(`/i/${userData?.userName}`) : setNotFound(true);
                }
            }
        } catch (error) {
            console.error("Error in fetchData:", error);
        }
    };

    const handleBack = () => {
        if (location.state && location.state.from) {
            navigate(-1);
        } else {
            userData?.userName ? navigate(`/i/${userData?.userName}`) : setNotFound(true);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const handelScroll = async () => {
        const scrollableDiv = scrollableDivRef.current;
        const scrollTop = scrollableDiv.scrollTop;
        const scrollHeight = scrollableDiv.scrollHeight - scrollableDiv.clientHeight;
        const scrolledPercentage = (scrollTop / scrollHeight) * 100;
        if (scrolledPercentage >= 80 && !requestSent && responselength >= 10) {
            setRequestSent(true);
            let collectionLinksLength = collectionData?.filter((ele) => {
                return ele.id === id;
            });
            let responseDataLength = await getProducts(id, collectionLinksLength[0]?.links?.length);
            setResponseLength(responseDataLength);
            setTimeout(() => {
                setRequestSent(false);
            }, 2000);
        }
    };

    useEffect(() => {
        let filterData = collectionData.filter((ele) => ele.id === id);
        setProducts(filterData[0]);
    }, [collectionData]);

    return (
        <>
            {notFound ? (
                <NotFoundScreen />
            ) : (
                <div className={styles.wrapper}>
                    <div className={styles.collectionContainer} onScroll={handelScroll} ref={scrollableDivRef}>
                        <div className={styles.userWrapper}>
                            <img src={arrow} alt="back icon" className={styles.backIcon} onClick={handleBack} />
                            <p>{userData?.firstName}</p>
                            <img
                                src={userData?.profileImg ? userData?.profileImg : defaultImage}
                                alt="user profile pic"
                                className={styles.profileImg}
                            />
                        </div>
                        <div className={styles.collectionDescripitionContainer}>
                            <p>{products?.title}</p>
                            <img
                                src={shareIcon}
                                alt="share icon"
                                onClick={(event) => {
                                    try {
                                        event.stopPropagation();
                                        dispatch(updateShareModalOpen());
                                        dispatch(updateShareModalDescripition(products?.title));
                                        dispatch(updateShareModalImage(products?.links[0]?.metaData?.response?.image || ""));
                                        dispatch(updateShareShortLink(`${process.env.REACT_APP_URL}/collection/${products?.id}`));
                                        addEvent(
                                            { url: `${process.env.REACT_APP_URL}/collection/${e.id}` },
                                            MixpanelEvents.SHARING_COLLECTION
                                        );
                                    } catch (error) {
                                        console.error("Error handling share icon click:", error);
                                    }
                                }}
                            />
                        </div>
                        <div className={styles.productsGrid}>
                            {products?.links?.map((e, i) => (
                                <div className={styles.productCell} key={i}>
                                    <Product productInfo={e} />
                                </div>
                            ))}
                        </div>
                        <div className={styles.emptyDiv}></div>
                        <div className={styles.faymFooter}>
                            <img src={faym} alt="faym logo" className={styles.faymLogo} />
                        </div>
                    </div>
                    <ShareModal />
                </div>
            )}
        </>
    );
}

export default index;
