import { Navigate } from "react-router-dom";
import { getLocalData } from "../Services/utils";
import { message } from "antd";
import { useSelector } from "react-redux";
export const AfterLoginRoute = ({ children }) => {
    const { user, idToken } = useSelector((state) => state.root.auth);
    if (idToken && user?.userName && user?.firstName) {
        return <Navigate to={"/dashboard"} />;
    }
    return children;
};

export const ProtectedRouteInfo = ({ children }) => {
    const { user } = useSelector((state) => state.root.auth);
    if (!user) {
        message?.error("You are not authorized");
        return <Navigate to={"/"} />;
    }
    if (user?.userName) {
        return <Navigate to={"/dashboard"} />;
    }
    return children;
};
