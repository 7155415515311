import React from "react";
import Sublime from "./Headers/Sublime";
import Matrix from "./Headers/Matrix";
import Breeze from "./Headers/Breeze";
import styles from "./index.module.css";
import SocialLinks from "./LinksComponents/SocialMediaLinks";
import ShortsAndReelsLinks from "./LinksComponents/ShortsAndReelsLinks";
import AppsLinks from "./LinksComponents/AppsLinks";
import { useSelector } from "react-redux";
import FaymBranding from "./../../FaymBranding";

const index = () => {
    const { nonStoreSectionsChanged } = useSelector((state) => state.root.dashboard);
    const { user } = useSelector((state) => state.root.auth);

    const renderThemeComponent = () => {
        switch (user?.theme.theme) {
            case "Sublime":
                return <Sublime />;
            case "Matrix":
                return <Matrix />;
            case "Breeze":
                return <Breeze />;
            default:
                return "";
        }
    };

    return (
        <div className={styles.container}>
            <img className={styles.linkInBioBackground} src={user?.theme.items[0].backgroundUrl} />
            <div className={styles.linkInBioPreview}>
                <div className={styles.header}></div>
                {renderThemeComponent()}
                {nonStoreSectionsChanged?.map((e, i) => {
                    const { templateType, links, title } = e;
                    switch (templateType) {
                        case "application":
                        case "podcast":
                        case "other":
                            if (links.length > 0) {
                                return <AppsLinks key={i} id={i} data={links} title={title} templateType={templateType} />;
                            }
                            break;

                        case "social_media":
                            if (links.length > 0) {
                                return <SocialLinks key={i} id={i} data={links} title={title} />;
                            }
                            break;

                        case "youtube_shorts":
                        case "insta_reels":
                            if (links.length > 0) {
                                return <ShortsAndReelsLinks key={i} id={i} data={links} title={title} type={templateType} />;
                            }
                            break;

                        default:
                            return <div key={i}></div>;
                    }
                })}
                <div style={{ flex: 1 }}></div>
                <FaymBranding />
            </div>
        </div>
    );
};

export default index;
