import React from "react";
import styles from "./index.module.css";
import { useSelector } from "react-redux";
import { addEvent } from "../../../Services/mixpanel";
import { MixpanelEvents } from "../../../constants/mixpanelEvents";

const BottomNav = ({ selectedWall, setSelectedWall }) => {
    const { user } = useSelector((state) => state.root.wall);

    const handleSelect = (wall) => {
        setSelectedWall(wall);
        addEvent({ username: user.userName }, MixpanelEvents.OPENED_WALL + `${wall}`);
    };

    return (
        <div className={styles.bottomNavContainer}>
            <div className={styles.bottomNav}>
                <div
                    className={
                        selectedWall === "social"
                            ? `${styles.wallToggleButtonv2} ${styles.unselectedButtonv2}`
                            : `${styles.storeButton} ${styles.wallToggleButtonv2} ${styles.selectedButtonv2}`
                    }
                    onClick={
                        selectedWall === "social"
                            ? (e) => {
                                  e?.stopPropagation();
                                  handleSelect("store");
                              }
                            : () => {}
                    }
                >
                    <div
                        className={
                            selectedWall === "social"
                                ? `${styles.wallToggleButtonTextv2} ${styles.unselectedPropertiesv2}`
                                : styles.wallToggleButtonTextv2
                        }
                    >
                        Store
                    </div>
                </div>
                <div
                    className={
                        selectedWall === "store"
                            ? `${styles.wallToggleButtonv2} ${styles.unselectedButtonv2}`
                            : `${styles.socialButton} ${styles.wallToggleButtonv2} ${styles.selectedButtonv2}`
                    }
                    onClick={
                        selectedWall === "store"
                            ? (e) => {
                                  e?.stopPropagation();
                                  handleSelect("social");
                              }
                            : () => {}
                    }
                >
                    <div
                        className={
                            selectedWall === "store"
                                ? `${styles.wallToggleButtonTextv2} ${styles.unselectedPropertiesv2}`
                                : styles.wallToggleButtonTextv2
                        }
                    >
                        Socials
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BottomNav;
