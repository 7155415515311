import { getAPIData } from "../utils";
import store from "../../redux/store";
import { updateThemes } from "../../redux/slice/authSlice";

export const getThemesData = async () => {
    try {
        const themeResp = await getAPIData({
            url: "template/v2/themes"
        });
        store.dispatch(updateThemes(themeResp?.data));
    } catch (err) {
        console.error(err);
    }
};
