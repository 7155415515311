import { Col, Row, Form, Button, Input, message } from "antd";
import { useState, useEffect, useRef } from "react";
import "./index.css";
import Navbar from "../Builder/Navbar/Navbar";
import profileImg from "../../assets/dashboard/userProfile.png";
import userIcon from "../../assets/UserIcon.png";
import Modal from "./../CommonV2/Modal";
import { postAPIData } from "../../Services/utils";
import { useNavigate } from "react-router-dom";
import { MixpanelEvents } from "../../constants/mixpanelEvents.js";
import { addEvent } from "../../Services/mixpanel";
import logoutHeader from "../../assets/logoutHeader.png";
import { useDispatch } from "react-redux";
import { logout, updateUser } from "./../../redux/slice/authSlice.js";
import { useSelector } from "react-redux";
import { useImageUploader } from "../../customHooks/useImageUploader";
import Crop from "./cropper/CropperModal.jsx";
import DefaultImg from "./DefaultImg/index.jsx";

const Profile = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { user } = useSelector((state) => state.root.auth);
    const prevFirstName = user.firstName;
    const [firstName, setFirstName] = useState();
    const [showModal, setShowModal] = useState(false);
    const closeModalHandler = () => setShowModal(false);
    const [updateImageModalOpen, setUpdateImageModalOpen] = useState(false);
    const [isDisabled, setIsDisabled] = useState(true);

    const alignItems = "center";
    const { handleUploadChange, loading, uploadRef } = useImageUploader(setIsDisabled);
    useEffect(() => {
        setFirstName(user.firstName);
        addEvent({}, MixpanelEvents.LOADED_PROFILE_PAGE);
    }, [user.firstName]);
    const updateUserData = async (firstName) => {
        const obj = {
            firstName: firstName
        };
        if (prevFirstName !== firstName) {
            const userData = await postAPIData(
                {
                    url: "api/user/update_user",
                    data: obj
                },
                true
            );
            if (userData.data.message === "User updated successfully") {
                message.success("Name Updated Successfully");
                dispatch(updateUser(userData.data.user));
            } else {
                message.error("Could not be updated");
            }
        }
    };
    return (
        <Col className="profile-container" style={{ position: "relative" }}>
            <Navbar analytics={true} currPage={"profile"} />
            <Row
                style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    paddingBottom: "30px"
                }}
            >
                <img src={profileImg} alt="profile-icon" />

                <span
                    style={{
                        fontSize: "26px",
                        paddingBottom: "8px",
                        fontWeight: "bold",
                        paddingLeft: "10px"
                    }}
                >
                    Account Settings{" "}
                </span>
            </Row>
            <Col>
                <span style={{ fontSize: "14px" }}>Profile Settings </span>

                <div
                    style={{
                        borderRadius: "10px",
                        backgroundColor: "white",
                        padding: "14px",
                        marginTop: "10px",
                        marginBottom: "30px"
                    }}
                >
                    <Row
                        style={{
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "center"
                        }}
                    >
                        {user && user.profileImg ? (
                            <img src={user && user.profileImg ? user.profileImg : userIcon} alt="profile-icon" className="profile-img" />
                        ) : (
                            <div className="profile-img">
                                <DefaultImg height={"80px"} width={"80px"} radius={"40px"} fullName={user.firstName} />
                            </div>
                        )}
                        <Col>
                            <span
                                style={{
                                    fontSize: "14px",
                                    paddingBottom: "8px"
                                }}
                            >
                                Change your Profile Photo
                            </span>
                            <Col>
                                <button
                                    className="ModalButtons"
                                    loading={loading}
                                    onClick={() => {
                                        setUpdateImageModalOpen(true);
                                    }}
                                >
                                    Upload Image
                                </button>
                                {updateImageModalOpen && (
                                    <Modal
                                        showModal={updateImageModalOpen}
                                        setShowModal={setUpdateImageModalOpen}
                                        onClose={() => setUpdateImageModalOpen(false)}
                                        alignItems={alignItems}
                                    >
                                        <Crop
                                            uploadImage={handleUploadChange}
                                            closeModal={() => setUpdateImageModalOpen(false)}
                                            uploadRef={uploadRef}
                                        />
                                    </Modal>
                                )}
                            </Col>
                        </Col>
                    </Row>
                </div>
            </Col>
            <Col>
                <span style={{ fontSize: "14px" }}>My Details </span>
                <div
                    style={{
                        borderRadius: "10px",
                        backgroundColor: "white",
                        padding: "14px",
                        marginTop: "10px",
                        marginBottom: "30px"
                    }}
                >
                    <Form layout="vertical">
                        <Form.Item name="name">
                            <FloatLabel label={!prevFirstName ? "Name" : ""}>
                                <Input value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                            </FloatLabel>
                        </Form.Item>
                        <Form.Item name="email">
                            <FloatLabel label={!user?.email ? "Email" : ""}>
                                <Input value={user?.email} disabled={user?.email} />
                            </FloatLabel>
                        </Form.Item>
                        <Form.Item name="username">
                            <FloatLabel label={!user?.userName ? "Change username" : ""}>
                                <Input value={user?.userName} disabled={user?.userName} />
                            </FloatLabel>
                        </Form.Item>
                        <Form.Item name="Phone Number">
                            <FloatLabel label={!user?.phoneNumber ? "Phone Number" : ""}>
                                <Input value={user?.phoneNumber} disabled={user?.phoneNumber} />
                            </FloatLabel>
                        </Form.Item>
                    </Form>
                    <Button style={{ backgroundColor: "#E9474C", color: "white" }} block onClick={() => updateUserData(firstName)}>
                        Save Details
                    </Button>
                </div>
            </Col>
            <Col>
                <button
                    className="ModalButtons"
                    onClick={() => {
                        setShowModal(!showModal);
                    }}
                    style={{
                        marginTop: "55px"
                    }}
                    block
                >
                    Log Out
                </button>
                <Modal showModal={showModal} setShowModal={setShowModal} onClose={closeModalHandler} alignItems={alignItems}>
                    <div
                        style={{
                            width: "20.625rem",
                            height: "11.75rem",
                            backgroundColor: "white",
                            display: "flex",
                            flexDirection: "column",
                            borderRadius: "10px"
                        }}
                        onClick={(e) => e.stopPropagation()}
                    >
                        <div
                            style={{
                                backgroundImage: `url(${logoutHeader})`,
                                marginTop: "-25px",
                                alignSelf: "center",
                                width: "3.125rem",
                                height: "3.125rem",
                                backgroundSize: "contain",
                                backgroundRepeat: "no-repeat"
                            }}
                        ></div>
                        <div
                            style={{
                                width: "11.3125rem",
                                height: "3.25rem",
                                color: "#000",
                                alignSelf: "center",
                                textAlign: "center",
                                fontFamily: "Sora",
                                fontSize: "18px",
                                fontStyle: "normal",
                                fontWeight: "400",
                                lineHeight: "26px",
                                marginTop: "0.9375rem"
                            }}
                        >
                            Are you sure, <br />
                            you want to logout?
                        </div>
                        <div
                            style={{
                                alignSelf: "flex-end",
                                margin: "auto",
                                marginTop: "1.875rem",
                                display: "flex",
                                width: "272px",
                                justifyContent: "space-between"
                            }}
                        >
                            <button
                                style={{
                                    fontSize: "1rem",
                                    borderRadius: "999px",
                                    cursor: "pointer",
                                    fontFamily: "Sora",
                                    width: "7.875rem",
                                    height: "2.25rem",
                                    color: "black",
                                    border: "1px solid black",
                                    background: "white"
                                }}
                                onClick={closeModalHandler}
                            >
                                Cancel
                            </button>
                            <button
                                style={{
                                    fontSize: "1rem",
                                    borderRadius: "999px",
                                    cursor: "pointer",
                                    fontFamily: "Sora",
                                    width: "7.875rem",
                                    height: "2.25rem",
                                    color: "white",
                                    border: "1px solid #E32121",
                                    background: "#E32121"
                                }}
                                onClick={() => {
                                    localStorage.removeItem("IdToken");
                                    localStorage.removeItem("userName");
                                    localStorage.clear();
                                    dispatch(logout());
                                    addEvent({}, MixpanelEvents.LOGOUT_CLICKED);
                                    navigate("/");
                                }}
                            >
                                Logout
                            </button>
                        </div>
                    </div>
                </Modal>
            </Col>
        </Col>
    );
};

const FloatLabel = (props) => {
    const [focus, setFocus] = useState(false);
    const { children, label, value } = props;

    const labelClass = focus || (value && value.length !== 0) ? "label label-float" : "label";

    return (
        <div className="float-label" onBlur={() => setFocus(false)} onFocus={() => setFocus(true)}>
            {children}
            <label className={labelClass}>{label}</label>
        </div>
    );
};

export default Profile;
