import { Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import { getAPIData, getLocalData } from "../../../Services/utils";
import "./index.css";
const Summary = ({ userId }) => {
    const [overallData, setOverallData] = useState({});
    const [loading, setLoading] = useState(false);
    const getSummaryData = async () => {
        try {
            setLoading(true);
            const summaryResp = await getAPIData({
                url: `api/analytics/overall?id=${userId}`
            });
            setOverallData(summaryResp?.data);
        } catch (err) {
            console.error(err);
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        if (userId) getSummaryData();
    }, [userId]);
    return (
        <div className="analytics-summary-container">
            <Row className="analytics-header-row">
                <Col span={12}>
                    <p className="analytics-heading">Analytics</p>
                </Col>
                <Col span={12} className="analytics-filter">
                    {/* <Select
            defaultValue="This Month"
            style={{ width: 120 }}
            onChange={handleChange}
            options={[
              { value: 'This Month', label: 'This Month' },
              { value: 'Past 3 Month', label: 'Past 3 Month' },
              { value: 'Past 6 Month', label: 'Past 6 Month' },
            ]}
          /> */}
                </Col>
            </Row>
            <Row className="analytics-body">
                <Col span={6} xs={24} md={6} className="views-col">
                    <Row>
                        <Col span={12} md={24} className="analytics-label">
                            <span style={{ color: "#FF9191" }}>&#9679;</span>
                            Views:
                        </Col>
                        <Col span={12} md={24} className="analytics-value">
                            {overallData?.totalViews || 0}
                            {/* {overallData ? overallData.totalViews : 0} */}
                        </Col>
                    </Row>
                </Col>
                <Col span={6} xs={24} md={6} className="clicks-col">
                    <Row>
                        <Col span={12} md={24} className="analytics-label ">
                            <span style={{ color: "#91DEFF" }}>&#9679;</span>
                            Clicks:
                        </Col>
                        <Col span={12} md={24} className="analytics-value">
                            {overallData?.totalClicks || 0}
                        </Col>
                    </Row>
                </Col>
                <Col span={6} xs={24} md={6} className="ctr-col">
                    <Row>
                        <Col span={12} md={24} className="analytics-label">
                            <span style={{ color: "#F691FF" }}>&#9679;</span>
                            CTR:
                        </Col>
                        <Col span={12} md={24} className="analytics-value">
                            {/* {overallData?.totalCTR
                                ? overallData?.totalCTR === "NaN"
                                    ? 0
                                    : overallData?.totalCTR
                                : 0} */}
                            {Math.round(overallData?.totalViews / overallData?.totalClicks) || ""}
                        </Col>
                    </Row>
                </Col>
                <Col span={6} xs={24} md={6} className="revenue-col">
                    <Row>
                        <Col span={12} md={24} className="analytics-label">
                            <span style={{ color: "#91FFB6" }}>&#9679;</span>
                            Engagement:
                        </Col>
                        <Col span={12} md={24} className="analytics-value">
                            {overallData?.totalViews + overallData?.totalClicks || 0}
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    );
};

export default Summary;
