import { deepCopy, getAPIData } from "../utils";
import store from "../../redux/store";
import {
    setStoreSectionsOriginal,
    setNonStoreSectionsOriginal,
    setStoreSectionsChanged,
    setNonStoreSectionsChanged,
    setLinksOriginal,
    setPostsOriginal,
    setPostsChanged,
    setIsSectionDeleting
} from "../../redux/slice/dashboardSlice";
import { postAPIData } from "../utils";
import { deleteSectionFromRedux } from "../../components/Builder/builderServices/utils";

function selectDashboard(state) {
    return state.root.dashboard;
}

let dashboardData;
function listener() {
    dashboardData = selectDashboard(store.getState());
}
store.subscribe(listener);

export const getLinkInBioData = async () => {
    try {
        await getAPIData({
            url: "api/store/linkinbio"
        }).then((linksResponse) => {
            const nonAffiliateSections = linksResponse.data.data?.filter((e) => e.links.length > 0);
            store.dispatch(setNonStoreSectionsOriginal(nonAffiliateSections));
            store.dispatch(setNonStoreSectionsChanged(deepCopy(nonAffiliateSections)));
            store.dispatch(setLinksOriginal());
        });
    } catch (error) {
        console.error(error);
    }
};

export const getCollectionsData = async () => {
    try {
        await getAPIData({
            url: "api/store/collection"
        }).then((linksResponse) => {
            const affiliateSections = linksResponse.data.data?.filter((e) => e.links.length > 0);
            store.dispatch(setStoreSectionsOriginal(affiliateSections));
            store.dispatch(setStoreSectionsChanged(deepCopy(affiliateSections)));
            store.dispatch(setLinksOriginal());
        });
    } catch (error) {
        console.error(error);
    }
};

export const getPostsData = async (pinned) => {
    try {
        await getAPIData({
            url: `api/store/post?pinned=${pinned}`
        }).then((linksResponse) => {
            const posts = linksResponse.data.data?.slice();
            if (pinned) {
                store.dispatch(setPostsOriginal(posts));
                store.dispatch(setPostsChanged(deepCopy(posts)));
            } else {
                let pinnedPosts = dashboardData.postsOriginal?.slice();
                store.dispatch(setPostsOriginal([...pinnedPosts, ...posts]));
                store.dispatch(setPostsChanged(deepCopy([...pinnedPosts, ...posts])));
            }
        });
    } catch (error) {
        console.error(error);
    }
};

export const deleteSectionFromDB = async (section) => {
    try {
        store.dispatch(setIsSectionDeleting(true));
        await postAPIData(
            {
                url: "api/user/v2/deleteTemplate",
                data: {
                    templateDataList: [section._id]
                }
            },
            true
        ).then(() => deleteSectionFromRedux(section, section.templateType === "affiliate_link"));
        store.dispatch(setIsSectionDeleting(false));
    } catch (error) {
        console.error(error);
        store.dispatch(setIsSectionDeleting(false));
    }
};

export const addAnalytics = async (data) => {
    try {
        await postAPIData({ url: "api/analytics/add", data: { data } }, false);
    } catch (error) {
        console.log(error);
    }
};
