import { getAPIData } from "../utils";
import store from "../../redux/store";
import {
    updateWallUser,
    updateLinkInBio,
    updatePostData,
    updatecollectionData,
    updatePinnedPostData,
    updateFilterstData,
    updateCollectionProducts
} from "../../redux/slice/wallSlice";

//get user data
export const getuserData = async (username) => {
    try {
        const response = await getAPIData({ url: `v2/wall/${username}` });
        store.dispatch(updateWallUser(response.data.data));
        return response.data;
    } catch (err) {
        console.error(err);
    }
};

// get user link in bio(socials) data
export const getLinkInBioData = async (username) => {
    try {
        await getAPIData({
            url: `v2/wall/linkInBio/${username}`
        }).then((response) => {
            store.dispatch(updateLinkInBio(response.data.data));
        });
    } catch (err) {
        console.error(err);
    }
};

// get user filter data
export const getFilterData = async (username) => {
    try {
        await getAPIData({
            url: `v2/wall/misc/${username}`
        }).then((response) => {
            store.dispatch(updateFilterstData(response?.data.data));
        });
    } catch (err) {
        console.error(err);
    }
};

// get user pinned post data
export const getPinnedPostData = async (username) => {
    try {
        await getAPIData({
            url: `v2/wall/posts/${username}?pinned=true`
        }).then((response) => {
            store.dispatch(updatePinnedPostData(response?.data.data));
        });
    } catch (err) {
        console.error(err);
    }
};

// get user collection data
export const getCollectionData = async (username, offset, limit) => {
    try {
        offset = offset ? offset : 0;
        const response = await getAPIData({ url: `v2/wall/collections/${username}?limit=${limit}&offset=${offset}` });
        store.dispatch(updatecollectionData(response?.data.data));
        return response?.data?.data?.length;
    } catch (err) {
        console.error(err);
    }
};

// get notPinned post data
export const getPostData = async (username, offset, limit) => {
    try {
        offset = offset ? offset : 0;
        const response = await getAPIData({ url: `v2/wall/posts/${username}?pinned=false&limit=${limit}&offset=${offset}` });
        store.dispatch(updatePostData(response?.data.data));
        return response?.data?.data?.length;
    } catch (err) {
        console.error(err);
    }
};

export const getSharedPost = async (id) => {
    try {
        const response = await getAPIData({ url: `v2/wall/post/${id}` });
        return response?.data;
    } catch (error) {
        console.error(error);
    }
};

export const getSharedCollection = async (id) => {
    try {
        const response = await getAPIData({ url: `v2/wall/collection/${id}` });
        return response?.data;
    } catch (error) {
        console.error(error);
    }
};

function select(state) {
    return state.root.wall;
}
let wallData;
function listener() {
    wallData = select(store.getState());
}
store.subscribe(listener);

export const getProducts = async (parentId, offset) => {
    try {
        let response = await getAPIData({ url: `v2/wall/products?parentId=${parentId}&limit=50&offset=${offset}` });
        if (response.data.data.length) {
            let collection = wallData?.collectionData?.filter((ele) => {
                return ele?.id === parentId;
            });
            let remainingCollection = wallData?.collectionData?.filter((ele) => {
                return ele?.id !== parentId;
            });
            let updatedCollection = { ...collection[0], links: [...collection[0].links, ...response.data.data] };
            remainingCollection.push(updatedCollection);
            remainingCollection = remainingCollection.sort((a, b) => b.sortId - a.sortId);
            store.dispatch(updateCollectionProducts(remainingCollection));
            return response.data.data.length;
        }
    } catch (error) {
        console.error(error);
    }
};
