const MixpanelEvents = {
    SIGN_IN_INITIATED: "SIGN_IN_INITIATED",
    SIGN_IN_SUCCESSFULL: "SIGN_IN_SUCCESSFULL",
    SIGN_IN_FAILED: "SIGN_IN_FAILED",
    LOADED_USERNAME_PAGE: "LOADED_USERNAME_PAGE",
    LOADED_DASHBOARD_PAGE: "LOADED_DASHBOARD_PAGE",
    LOADED_ANALYTICS_PAGE: "LOADED_ANALYTICS_PAGE",
    LOADED_PROFILE_PAGE: "LOADED_PROFILE_PAGE",
    PUBLISHED_FAYMWALL: "PUBLISHED_FAYMWALL",
    COPY_LINK_FAYMWALL: "COPY_LINK_FAYMWALL",
    VIEW_FAYMWALL: "VIEW_FAYMWALL",
    LOGOUT_CLICKED: "LOGOUT_CLICKED",
    LOADED_SIGNUP_PAGE: "LOADED_SIGNUP_PAGE",
    USER_IMAGE_UPLOADED: "USER_IMAGE_UPLOADED",
    USER_PROFILE_UPDATED: "USER_PROFILE_UPDATED",
    LOADED_FAYMWALL: "LOADED_FAYMWALL",
    MAGIC_LINK: "MAGIC_LINK",
    LINK_IN_BIO_ITEM_CLICKED: "LINK_IN_BIO_ITEM_CLICKED",
    PRODUCT_LINK_CLICKED: "PRODUCT_LINK_CLICKED",
    FLIPKART_AFFILIATE_LINK_CLICKED: "FLIPKART_AFFILIATE_LINK_CLICKED",
    PRODUCT_SHARE_CLICKED: "PRODUCT_SHARE_CLICKED",
    PRODUCT_WHATSAPP_SHARE_CLICKED: "PRODUCT_WHATSAPP_SHARE_CLICKED",
    PRODUCT_INSTAGRAM_SHARE_CLICKED: "PRODUCT_INSTAGRAM_SHARE_CLICKED",
    PRODUCT_TELEGRAM_SHARE_CLICKED: "PRODUCT_TELEGRAM_SHARE_CLICKED",
    PRODUCT_FACEBOOK_SHARE_CLICKED: "PRODUCT_FACEBOOK_SHARE_CLICKED",
    PRODUCT_LINK_COPY_CLICKED: "PRODUCT_LINK_COPY_CLICKED",
    SHARED_PRODUCT_PAGE_OPENED: "SHARED_PRODUCT_PAGE_OPENED",
    STORE_ICON_CLICKED: "STORE_ICON_CLICKED",
    SEARCH_ICON_CLICKED: "SEARCH_ICON_CLICKED",
    SEARCH_CLEARED: "SEARCH_CLEARED",
    DOTD_CLICKED: "DOTD_CLICKED",
    PRODUCT_CATEGORIES_CLICKED: "PRODUCT_CATEGORIES_CLICKED",
    PRODUCT_BRANDS_CLICKED: "PRODUCT_BRANDS_CLICKED",
    OPENED_WALL: "OPENED_WALL",
    OPENED_WEBSITE: "OPENED_WEBSITE",
    DEALS_FILTER_CLICKED: "DEALS_FILTER_CLICKED",
    BRANDS_FILTER_CLICKED: "BRANDS_FILTER_CLICKED",
    CATEGORY_FILTER_CLICKED: "CATEGORY_FILTER_CLICKED",
    SEARCH_CLOSED: "SEARCH_CLOSED",
    SELECTED_CATEGORY: "SELECTED_CATEGORY",
    SELECTED_PLATFORM: "SELECTED_PLATFORM",
    SELECTED_DEAL: "SELECTED_DEAL",
    PLAYED_PINNED_REEL: "PLAYED_PINNED_REEL",
    SHARING_PINNED_REEL: "SHARING_PINNED_REEL",
    PLAYED_REEL: "PLAYED_REEL",
    SHARING_REEL: "SHARING_REEL",
    SHARING_COLLECTION: "SHARING_COLLECTION",
    OPENED_COLLECTION: "OPENED_COLLECTION",
    VIDEO_SOUND: "VIDEO_SOUND",
    COPY_SHORT_LINK: "COPY_SHORT_LINK"
};

const MixpanelEventProperties = {
    INITIATED: "Initiated",
    SUCCESSFULL: "Successful"
};

const MixpanelPlatform = {
    MOBILE: "MOBILE",
    GOOGLE: "GOOGLE"
};

const MixpanelSharables = {
    post: "POST",
    p: "PRODUCT",
    collection: "COLLECTION"
};

export { MixpanelEvents, MixpanelEventProperties, MixpanelPlatform, MixpanelSharables };
