import React, { useEffect } from "react";
import styles from "./index.module.css";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedHeader } from "../../../../../redux/slice/dashboardSlice";

const BottomNav = ({}) => {
    const dispatch = useDispatch();
    const { selectedHeader } = useSelector((state) => state.root.dashboard);

    return (
        <div className={styles.bottomNavContainer}>
            <div className={styles.bottomNav} style={{ bottom: "13%" }}>
                <div
                    className={
                        selectedHeader === "LinkInBio"
                            ? `${styles.wallToggleButtonv2} ${styles.unselectedButtonv2}`
                            : `${styles.storeButton} ${styles.wallToggleButtonv2} ${styles.selectedButtonv2}`
                    }
                    onClick={
                        selectedHeader === "LinkInBio"
                            ? (e) => {
                                  e?.stopPropagation();
                                  dispatch(setSelectedHeader("Collections"));
                              }
                            : () => {}
                    }
                >
                    <div
                        className={
                            selectedHeader === "LinkInBio"
                                ? `${styles.wallToggleButtonTextv2} ${styles.unselectedPropertiesv2}`
                                : styles.wallToggleButtonTextv2
                        }
                    >
                        Store
                    </div>
                </div>
                <div
                    className={
                        selectedHeader === "Posts" || selectedHeader === "Collections"
                            ? `${styles.wallToggleButtonv2} ${styles.unselectedButtonv2}`
                            : `${styles.socialButton} ${styles.wallToggleButtonv2} ${styles.selectedButtonv2}`
                    }
                    onClick={
                        selectedHeader === "Posts" || selectedHeader === "Collections"
                            ? (e) => {
                                  e?.stopPropagation();
                                  dispatch(setSelectedHeader("LinkInBio"));
                              }
                            : () => {}
                    }
                >
                    <div
                        className={
                            selectedHeader === "Posts" || selectedHeader === "Collections"
                                ? `${styles.wallToggleButtonTextv2} ${styles.unselectedPropertiesv2}`
                                : styles.wallToggleButtonTextv2
                        }
                    >
                        Socials
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BottomNav;
