import { configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from "redux-persist";
import storage from "redux-persist/lib/storage";
import rootReducer from "./rootReducer";

const persistConfig = {
    key: "root",
    storage,
    whitelist: ["auth", "dashboard", "analytics"]
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
    reducer: { root: persistedReducer },
    // There should not be any non-serializable values in state or actions
    // If using Redux-Persist, you should specifically ignore all the action types it dispatches
    // Says the documentation
    // Below code does the same
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
            }
        })
});

// store.subscribe(() => {
//     localStorage.setItem("reduxState", JSON.stringify(store.getState()));
// });
export const persistor = persistStore(store);
export default store;
