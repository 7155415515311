import { createSlice, current } from "@reduxjs/toolkit";

const initialState = {
    user: null,
    filtersData: null,
    pinnedPostData: null,
    postData: [],
    collectionData: [],
    linkInBioData: null,
    shareModal: false,
    shareShortLink: null,
    shareModalImage: null,
    shareModalDescripition: null,
    productsData: null
};

const wallSlice = createSlice({
    name: "wall",
    initialState,
    reducers: {
        updateWallUser: (state, action) => {
            state.user = action.payload;
        },
        updatePostData: (state, action) => {
            return { ...state, postData: [...state?.postData, ...action.payload] };
        },
        updatecollectionData: (state, action) => {
            return { ...state, collectionData: [...state?.collectionData, ...action.payload] };
        },
        updateLinkInBio: (state, action) => {
            state.linkInBioData = action.payload;
        },
        updatePinnedPostData: (state, action) => {
            state.pinnedPostData = action.payload;
        },
        updateFilterstData: (state, action) => {
            state.filtersData = action.payload;
        },
        updateShareModalOpen: (state) => {
            state.shareModal = true;
        },
        updateShareModalClose: (state) => {
            state.shareModal = false;
        },
        updateShareShortLink: (state, action) => {
            state.shareShortLink = action.payload;
        },
        updateShareModalImage: (state, action) => {
            state.shareModalImage = action.payload;
        },
        updateShareModalDescripition: (state, action) => {
            state.shareModalDescripition = action.payload;
        },
        updateProductsData: (state, action) => {
            state.productsData = action.payload;
        },
        updateCollectionProducts: (state, action) => {
            state.collectionData = action.payload;
        }
    }
});

export const {
    updateWallUser,
    updateLinkInBio,
    updatePostData,
    updatecollectionData,
    updatePinnedPostData,
    updateFilterstData,
    updateShareModalOpen,
    updateShareModalClose,
    updateShareShortLink,
    updateShareModalImage,
    updateShareModalDescripition,
    updateProductsData,
    updateCollectionProducts
} = wallSlice.actions;
export const selectWall = (state) => state.wall;
export default wallSlice.reducer;
