import * as Sentry from "@sentry/react";

export const initSentry = () => {
    Sentry.init({
        dsn: "https://1eb0267281c6331b9a39dec594cea3e4@o4506319488221184.ingest.sentry.io/4506433072726016",
        integrations: [
            new Sentry.BrowserTracing({
                // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
                tracePropagationTargets: ["localhost", /^https:\/\/faym\.co/]
            }),
            new Sentry.Replay()
        ],
        // Performance Monitoring
        tracesSampleRate: 1.0, //  Capture 100% of the transactions
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
};
