import { postAPIData } from "../utils";
import store from "../../redux/store";
import { openPublishSuccessModal, setPublishButtonEnabled, showPublishLoader } from "../../redux/slice/dashboardSlice";
import { getCollectionsData, getLinkInBioData, getPostsData } from "./dashboardService";
import { getUserData } from "./userService";

function selectDashboard(state) {
    return state.root.dashboard;
}
let dashboardData;
function listener() {
    dashboardData = selectDashboard(store.getState());
}

store.subscribe(listener);

export const publishFaymWall = async () => {
    try {
        store.dispatch(showPublishLoader(true));
        const res = await postAPIData(
            {
                url: "api/dashboard/v2/links",
                data: {
                    sectionDataList: dashboardData.sectionsDiff.filter((e) => e.links.length > 0),
                    profileData: dashboardData.profileUpdate,
                    linksDataList: dashboardData.linksDiff
                }
            },
            true
        );
        if (res.data) {
            store.dispatch(showPublishLoader(false));
            store.dispatch(setPublishButtonEnabled(!res.data));
            store.dispatch(openPublishSuccessModal(true));
            getUserData();
            getLinkInBioData();
            getCollectionsData();
            getPostsData(true);
            getPostsData(false);
        }
    } catch (error) {}
};
