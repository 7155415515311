import React from "react";
import styles from "./index.module.css";

function index({ images }) {
    if (images.length >= 4) {
        return (
            <div className={styles.imageGrid}>
                <div className={styles.gridColumn}>
                    <div className={styles.flexColumn}>
                        <div className={styles.imageContainer} style={{ height: "60%" }}>
                            <img src={images[0]} alt="Product 1" className={styles.image} />
                        </div>
                        <div className={styles.imageContainer} style={{ height: "40%" }}>
                            <img src={images[2]} alt="Product 2" className={styles.image} />
                        </div>
                    </div>
                </div>
                <div className={styles.gridColumn}>
                    <div className={styles.imageContainer} style={{ height: "40%" }}>
                        <img src={images[3]} alt="Product 4" className={styles.image} />
                    </div>
                    <div className={styles.imageContainer} style={{ height: "60%" }}>
                        <img src={images[1]} alt="Product 3" className={styles.image} />
                    </div>
                </div>
            </div>
        );
    } else if (images.length === 3) {
        return (
            <div className={styles.imageGrid}>
                <>
                    <div className={styles.gridColumn}>
                        <div className={styles.imageContainer} style={{ height: "100%" }}>
                            <img src={images[0]} alt="Product 1" className={styles.image} />
                        </div>
                    </div>
                    <div className={styles.gridColumn}>
                        <div className={styles.flexColumn}>
                            <div className={styles.imageContainer} style={{ height: "50%" }}>
                                <img src={images[1]} alt="Product 2" className={styles.image} />
                            </div>
                            <div className={styles.imageContainer} style={{ height: "50%" }}>
                                <img src={images[2]} alt="Product 3" className={styles.image} />
                            </div>
                        </div>
                    </div>
                </>
            </div>
        );
    } else if (images.length === 2) {
        return (
            <div className={styles.imageGrid}>
                <>
                    <div className={styles.gridColumn}>
                        <div className={styles.imageContainer} style={{ height: "100%" }}>
                            <img src={images[0]} alt="Product 1" className={styles.image} />
                        </div>
                    </div>
                    <div className={styles.gridColumn}>
                        <div className={styles.imageContainer} style={{ height: "100%" }}>
                            <img src={images[1]} alt="Product 1" className={styles.image} />
                        </div>
                    </div>
                </>
            </div>
        );
    } else if (images.length === 1) {
        return (
            <div key={index} className={styles.singleImageContainer}>
                <img src={images[0]} alt={`Product ${index + 1}`} className={styles.image} />
            </div>
        );
    } else {
        return <div>No images available</div>;
    }
}

export default index;
