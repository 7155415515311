import React, { useEffect } from "react";
import "./modal.css";

const Modal = ({ showModal, children, onClose, alignItems }) => {
    useEffect(() => {
        const body = document.querySelector("body");
        if (showModal) body.style.overflow = "hidden";
        else body.style.overflow = "auto";
    }, [showModal]);

    const body = document.querySelector("body");
    if (showModal) body.style.overflow = "hidden";
    else body.style.overflow = "auto";
    if (!showModal) {
        return null;
    }
    return (
        <div
            className="modal"
            onClick={onClose}
            style={{
                alignItems: `${alignItems}`
            }}
        >
            {children}
        </div>
    );
};

export default Modal;
