import { Button, message } from "antd";
import googleIcon from "../../../assets/icons/google.svg";
import { useNavigate } from "react-router-dom";
import { postAPIData } from "../../../Services/utils";
import { addEvent } from "../../../Services/mixpanel.js";
import { MixpanelEvents, MixpanelPlatform } from "../../../constants/mixpanelEvents.js";
import { useDispatch } from "react-redux";
import { login } from "./../../../redux/slice/authSlice";
import { signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { auth } from "./../../../config/firebaseLogin.js";

const GoogleSignInButton = ({ loading, setLoading }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const handleGoogleSignIn = async () => {
        try {
            setLoading(true);
            const provider = new GoogleAuthProvider();
            const resp = await signInWithPopup(auth, provider);
            const paramValue = "";
            const obj = {
                idToken: resp?._tokenResponse?.idToken
            };
            const userData = await postAPIData(
                {
                    url: "auth/google/login",
                    data: obj
                },
                false
            );
            dispatch(
                login({
                    user: userData?.data,
                    idToken: userData.headers.idtoken,
                    email: userData.data.email
                })
            );
            localStorage.clear();
            if (userData?.data?.firstName && userData?.data?.userName) {
                addEvent(userData.data, MixpanelEvents.SIGN_IN_SUCCESSFULL, MixpanelPlatform.GOOGLE);
                navigate("/dashboard");
            } else {
                addEvent({}, MixpanelEvents.LOADED_USERNAME_PAGE);
                navigate("/infoscreen", { state: paramValue });
            }
        } catch (err) {
            message.error(err?.message || "Something went wrong");
        } finally {
            setLoading(false);
        }
    };
    return (
        <Button loading={loading} block className="google-login" onClick={() => handleGoogleSignIn()}>
            <img draggable={false} src={googleIcon} alt="google-icon" />
            Continue with Google{" "}
        </Button>
    );
};

export default GoogleSignInButton;
