export const getBrandName = (url) => {
    try {
        if (!url.startsWith("http://") && !url.startsWith("https://")) {
            url = "http://" + url;
        }
        const parsedUrl = new URL(url);
        const hostname = parsedUrl.hostname;
        const parts = hostname.split(".");
        return parts.length > 2 ? parts[parts.length - 2] : parts[0];
    } catch (error) {
        console.error("Invalid URL:", error);
        return null;
    }
};

export const storeProductsData = (postData, collectionData, pinnedPostData) => {
    const products = [];
    if (type === "post") {
        data?.forEach((post) => {
            if (post.products && post.products.length > 0) {
                post.products.forEach((product) => {
                    products.push(product);
                });
            }
        });
    }

    if (type === "collection") {
        data?.forEach((collection) => {
            if (collection.links.length > 0 && collection.links) {
                collection.links.forEach((product) => {
                    products.push(product);
                });
            } else {
                console.log("collection empty");
            }
        });
    }

    return [...oldLinks, ...products];
};
