import React, { useState, useEffect, useRef } from "react";
import Tooltip from "./../../../CommonV2/Tooltip";
import styles from "./index.module.css";
import accordionCollapse from "./../../../../assets/accordionCollapse.png";
import accordionExpand from "./../../../../assets/accordionExpand.png";
import imageUpload from "./../../../../assets/imageUpload.png";
import { useDispatch, useSelector } from "react-redux";
import { updateUserProperties } from "../../../../redux/slice/authSlice";
import CropperModal from "../../../Profile/cropper/CropperModal";
import Modal from "./../../../CommonV2/Modal";
import { useImageUploader } from "../../../../customHooks/useImageUploader";
import selectedThemeIcon from "../../../../assets/selectedThemeIcon.png";
import { updateProfileData } from "../../../../redux/slice/dashboardSlice";
import { findDiff } from "../../builderServices/utils";

const index = () => {
    const dispatch = useDispatch();
    const { user, themes } = useSelector((state) => state.root.auth);
    const [updateImageModalOpen, setUpdateImageModalOpen] = useState(false);
    const [selectedHeader, setSelectedHeader] = useState("Profile");
    const [isCollapsed, setIsCollapsed] = useState(false);
    const [bio, setBio] = useState(user.bio);
    const { handleUploadChange, uploadRef } = useImageUploader();
    const currentThemeItemRow = useRef(null);
    const scrollToElement = useRef(null);

    const handleUpdateTheme = (theme, item) => {
        dispatch(
            updateUserProperties({
                theme: { ...user.theme, theme: theme, items: [item] }
            })
        );
        dispatch(updateProfileData({ theme: item._id, bio: user.bio }));
        findDiff();
    };

    const handleBioChange = (event) => {
        setBio(event.target.value);
        dispatch(updateUserProperties({ bio: event.target.value }));
        dispatch(updateProfileData({ bio: event.target.value, theme: user.theme.items[0]._id }));
        findDiff();
    };

    const scrollToCurrentTheme = async () => {
        let scrollLeft = 0;
        if (currentThemeItemRow.current && scrollToElement.current) {
            const containerRect = currentThemeItemRow.current.getBoundingClientRect();
            const selectedElementRect = scrollToElement.current.getBoundingClientRect();
            scrollLeft = selectedElementRect.left - containerRect.left;
        }
        await currentThemeItemRow.current?.scroll({
            top: 0,
            left: scrollLeft,
            behavior: "smooth"
        });
    };

    useEffect(() => {
        scrollToCurrentTheme();
    }, []);

    return (
        <div className={styles.accordion}>
            <div className={styles.accordionHead} onClick={() => setIsCollapsed(!isCollapsed)}>
                <div className={styles.headerButtonGroup}>
                    <div
                        className={`${styles.headerButton} ${selectedHeader === "Profile" && styles.headerButtonSelected}`}
                        onClick={(event) => {
                            event.stopPropagation();
                            setSelectedHeader("Profile");
                        }}
                    >
                        Profile
                    </div>
                    <div
                        className={`${styles.headerButton} ${selectedHeader === "Themes" && styles.headerButtonSelected}`}
                        onClick={(event) => {
                            event.stopPropagation();
                            setSelectedHeader("Themes");
                        }}
                    >
                        Themes
                    </div>
                </div>
                <img className={styles.headerAccordionCollapse} src={isCollapsed ? accordionExpand : accordionCollapse} />
            </div>
            <div className={!isCollapsed ? styles.accordionContent : `${styles.accordionContent} ${styles.collapsed}`}>
                {!isCollapsed && (
                    <>
                        {selectedHeader === "Profile" ? (
                            <div>
                                <div className={styles.firstNameText}>{user.firstName}</div>
                                <input
                                    className={styles.bioInput}
                                    placeholder="Add a Little Bio About Yourself"
                                    value={bio}
                                    onChange={handleBioChange}
                                />
                            </div>
                        ) : (
                            <div>
                                <div className={styles.themesSectionItem}>
                                    <div className={styles.themesSectionItemHeadingContainer}>
                                        <div className={styles.themesSectionItemHeading}>Choose your Faym Profile Photo</div>
                                        <Tooltip info="Choose a Striking Image: This will be displayed at the top profile section of your Faym Wall." />
                                    </div>
                                    <div className={styles.uploadImageButton} onClick={() => setUpdateImageModalOpen(true)}>
                                        Upload Picture
                                        <img className={styles.uploadImageImg} src={imageUpload} />
                                    </div>
                                </div>
                                {updateImageModalOpen ? (
                                    <Modal
                                        showModal={updateImageModalOpen}
                                        setShowModal={setUpdateImageModalOpen}
                                        onClose={() => {
                                            setUpdateImageModalOpen(false);
                                        }}
                                        alignItems={"center"}
                                    >
                                        <CropperModal
                                            uploadImage={handleUploadChange}
                                            closeModal={() => setUpdateImageModalOpen(false)}
                                            uploadRef={uploadRef}
                                        />
                                    </Modal>
                                ) : (
                                    ""
                                )}
                                <div className={styles.themesSectionItem}>
                                    <div className={styles.themesSectionItemHeadingContainer}>
                                        <div className={styles.themesSectionItemHeading}>Choose Theme</div>
                                        <Tooltip info="Header will be displayed as the Top Section of your Faym Wall. Choose a  template that aligns with your style, brand, or personal aesthetic." />
                                    </div>

                                    <div className={styles.themes}>
                                        {themes?.map((e, i) => {
                                            return (
                                                <div id={e.theme} key={i}>
                                                    <p className={styles.themeHeading}>{e?.theme}</p>
                                                    <div
                                                        className={styles.backgrounds}
                                                        ref={user.theme === e.theme ? currentThemeItemRow : null}
                                                    >
                                                        {e.items?.map((item) => {
                                                            return (
                                                                <div
                                                                    key={item._id}
                                                                    id={item.title}
                                                                    onClick={() => {
                                                                        handleUpdateTheme(e.theme, item);
                                                                    }}
                                                                >
                                                                    <div
                                                                        className={styles.themeItemContainer}
                                                                        ref={item._id === user.theme?.items[0]._id ? scrollToElement : null}
                                                                    >
                                                                        <div
                                                                            className={styles.themeItem}
                                                                            style={{
                                                                                backgroundImage: `url(${item.appDisplayImgUrl})`,
                                                                                border:
                                                                                    item._id === user.theme?.items[0]._id
                                                                                        ? "3px solid #FF4D4E"
                                                                                        : ""
                                                                            }}
                                                                        >
                                                                            {item._id === user.theme?.items[0]._id && (
                                                                                <img
                                                                                    className={styles.iconCenter}
                                                                                    src={selectedThemeIcon}
                                                                                />
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                    <p className={styles.themeItemName}>{item.title}</p>
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>
                        )}
                    </>
                )}
            </div>
        </div>
    );
};

export default index;
