import { v4 as uuidv4 } from "uuid";
import store from "./../../../redux/store";
import {
    setStoreSectionsChanged,
    setNonStoreSectionsChanged,
    setLinksChanged,
    setLinksDiff,
    setSectionsDiff,
    setPublishButtonEnabled
} from "../../../redux/slice/dashboardSlice";
import heart_icon from "../../../assets/icons/section-icons/heart.svg";
import heart_icon_filled from "../../../assets/icons/section-icons/heart_filled.svg";
import apps_icon from "../../../assets/icons/section-icons/apps.svg";
import apps_icon_filled from "../../../assets/icons/section-icons/apps_filled.svg";
import affiliate_icon from "../../../assets/icons/section-icons/affiliate.svg";
import affiliate_icon_filled from "../../../assets/icons/section-icons/affiliate_filled.svg";
import instagram_reels_icon from "../../../assets/icons/section-icons/instagram_reels.svg";
import instagram_reels_icon_filled from "../../../assets/icons/section-icons/instagram_reels_filled.svg";
import store_icon from "../../../assets/leftStoreIcon.svg";
import other_links from "../../../assets/icons/section-icons/others.svg";
import other_links_filled from "../../../assets/icons/section-icons/others_filled.svg";
import youtube_shorts from "../../../assets/icons/section-icons/youtube_shorts.svg";
import youtube_shorts_filled from "../../../assets/icons/section-icons/youtube_shorts_filled.svg";
import plugins from "../../../assets/plugins.svg";
import plugins_filled from "../../../assets/pluginsFilled.svg";
import store_filled from "../../../assets/storeFilled.svg";
import { deleteSectionFromDB } from "../../../Services/apiServices/dashboardService";
import snap from "../../../assets/wall-icons/snap.png";
import fb from "../../../assets/wall-icons/fb.png";
import insta from "../../../assets/wall-icons/insta.png";
import linkedin from "../../../assets/wall-icons/LinkedIn.png";
import pinterest from "../../../assets/wall-icons/Pinterest.png";
import reddit from "../../../assets/wall-icons/reddit.png";
import telegram from "../../../assets/wall-icons/Telegram.png";
import yt from "../../../assets/wall-icons/YT.png";
import whatsapp from "../../../assets/wall-icons/WhatsApp.png";
import gmail from "../../../assets/wall-icons/gmail.png";
import yahoo from "../../../assets/wall-icons/yahoo.png";
import outlook from "../../../assets/wall-icons/outlook.png";
import twitter from "../../../assets/wall-icons/Twitter.png";
export const socialMediaImages = {
    instagram: {
        image: insta,
        backgroundColor: "#F96381",
        title: "Connect with me on Instagram"
    },
    facebook: {
        image: fb,
        backgroundColor: "#3B5999",
        title: "Follow me on Facebook"
    },
    twitter: {
        image: twitter,
        backgroundColor: "#2E3C47",
        title: "Check out My Latest Tweets"
    },
    snapchat: {
        image: snap,
        backgroundColor: "#EEE400",
        title: "Add me on Snapchat"
    },
    youtube: {
        image: yt,
        backgroundColor: "#DA3838",
        title: " Subscribe to My YouTube "
    },
    telegram: {
        image: telegram,
        backgroundColor: "#44A6DA",
        title: "Join me on Telegram"
    },
    linkedin: {
        image: linkedin,
        backgroundColor: "#1175AD",
        title: "Connect with me on LinkedIn"
    },
    pinterest: {
        image: pinterest,
        backgroundColor: "#E54141",
        title: " Check Out My Pinterest Board"
    },
    reddit: {
        image: reddit,
        backgroundColor: "#DF7C49",
        title: "Follow me on Reddit"
    },
    whatsapp: {
        image: whatsapp,
        backgroundColor: "#25D366",
        title: "ping me on WhatsApp"
    },
    gmail: {
        image: gmail,
        backgroundColor: "#EA4335",
        title: "Get in touch"
    },
    outlook: {
        image: outlook,
        backgroundColor: "#0079D8",
        title: "Get in touch"
    },
    yahoo: {
        image: yahoo,
        backgroundColor: "#B153C9",
        title: "Get in touch"
    },
    wa: {
        image: whatsapp,
        backgroundColor: "#25D366",
        title: "Ping me on WhatsApp"
    },
    t: {
        image: telegram,
        backgroundColor: "#44A6DA",
        title: "Join me on Telegram"
    }
};

function selectAuth(state) {
    return state.root.auth;
}

function selectDashboard(state) {
    return state.root.dashboard;
}

let userData;
let dashboardData;
function listener() {
    userData = selectAuth(store.getState());
    dashboardData = selectDashboard(store.getState());
}

store.subscribe(listener);

export const iconMap = {
    "Add Social Media": heart_icon,
    "Affiliate Links": affiliate_icon,
    Apps: apps_icon,
    "Instagram Reels": instagram_reels_icon,
    "Other Links": other_links,
    "Youtube Shorts": youtube_shorts,
    Plugins: plugins,
    Store: store_icon
};

export const filledIconMap = {
    "Add Social Media": heart_icon_filled,
    "Affiliate Links": affiliate_icon_filled,
    Apps: apps_icon_filled,
    "Instagram Reels": instagram_reels_icon_filled,
    "Other Links": other_links_filled,
    "Youtube Shorts": youtube_shorts_filled,
    Plugins: plugins_filled,
    Store: store_filled
};

export const sectionTemplateMap = {
    application: {
        headingDesc: "Apps",
        headingTitle: "Apps",
        leftIcon: "addSocialIcon",
        title: "My Recommended Apps",
        templateType: "application",
        links: []
    },
    youtube_shorts: {
        headingDesc: "Youtube Shorts",
        headingTitle: "Youtube Shorts",
        leftIcon: "youtubeIcon",
        title: "Must see YouTube Shorts",
        templateType: "youtube_shorts",
        links: []
    },
    insta_reels: {
        headingDesc: "Instagram Reels",
        headingTitle: "Instagram Reels",
        leftIcon: "affiliateIcon",
        title: "Featured Instagram Reels",
        templateType: "insta_reels",
        links: []
    },
    other: {
        headingDesc: "Others",
        headingTitle: "Other Links",
        leftIcon: "affiliateIcon",
        title: "Explore More",
        templateType: "other",
        links: []
    },
    social_media: {
        headingDesc: "Add Social Media",
        headingTitle: "Add Social Media",
        leftIcon: "addSocialIcon",
        title: "Check out my social media",
        templateType: "social_media",
        links: []
    },
    affiliate_link: {
        headingDesc: "Affiliate Links",
        headingTitle: "Affiliate Links",
        leftIcon: "affiliateIcon",
        title: "My Top Products",
        templateType: "affiliate_link",
        links: []
    },
    store: {
        headingDesc: "Store",
        headingTitle: "Store",
        leftIcon: "storeIcon",
        title: "Store",
        templateType: "store",
        links: []
    }
};

function getTemplate(templateType, title) {
    const template = sectionTemplateMap[templateType];
    if (title !== null && title !== undefined) {
        template.title = title;
    }
    return template;
}

export function createNewSection(userId, templateType, parentId, title, nodeType, sortId) {
    const template = getTemplate(templateType, title);
    return {
        parentId: parentId,
        nodeType: nodeType,
        templateType: templateType,
        title: title || template.title,
        id: uuidv4(),
        sortId: sortId,
        userId: userId,
        createdAt: null,
        updatedAt: null,
        headingDesc: template.headingDesc,
        headingTitle: template.headingTitle,
        leftIcon: template.leftIcon,
        isDeleted: false,
        links: []
    };
}

export const duplicateNonStoreSection = (existingSection) => {
    let title = `${existingSection.title} copy`.length <= 35 ? `${existingSection.title} copy` : existingSection.title;
    let newSection = createNewSection(userData.user.id, existingSection.templateType, null, title, "section", existingSection.sortId + 1);
    const adjustedStoreSections = dashboardData.storeSectionsChanged.map((element) => {
        if (element.sortId >= newSection.sortId) {
            return { ...element, sortId: element.sortId + 1 };
        }
        return element;
    });
    const adjustedNonStoreSections = dashboardData.nonStoreSectionsChanged.map((element) => {
        if (element.sortId >= newSection.sortId) {
            return { ...element, sortId: element.sortId + 1 };
        }
        return element;
    });
    let index = adjustedNonStoreSections.findIndex((element) => element.id === existingSection.id);
    if (index === -1) {
        index = 0;
    }
    const newNonStoreSections = [...adjustedNonStoreSections.slice(0, index), newSection, ...adjustedNonStoreSections.slice(index)];
    store.dispatch(setStoreSectionsChanged(adjustedStoreSections));
    store.dispatch(setNonStoreSectionsChanged(newNonStoreSections));
    store.dispatch(setLinksChanged());
};

export const duplicateStoreSection = (existingSection) => {
    let title = `${existingSection.title} copy`.length <= 35 ? `${existingSection.title} copy` : existingSection.title;
    let newSection = createNewSection(userData.id, existingSection.templateType, null, title, "section", existingSection.sortId + 1);
    const adjustedStoreSections = dashboardData.storeSectionsChanged.map((element) => {
        if (element.sortId >= newSection.sortId) {
            return { ...element, sortId: element.sortId + 1 };
        }
        return element;
    });
    const adjustedNonStoreSections = dashboardData.nonStoreSectionsChanged.map((element) => {
        if (element.sortId >= newSection.sortId) {
            return { ...element, sortId: element.sortId + 1 };
        }
        return element;
    });
    let index = adjustedStoreSections.findIndex((element) => element.id === existingSection.id);
    if (index === -1) {
        index = 0;
    }
    const newStoreSections = [...adjustedStoreSections.slice(0, index), newSection, ...adjustedStoreSections.slice(index)];
    store.dispatch(setStoreSectionsChanged(newStoreSections));
    store.dispatch(setNonStoreSectionsChanged(adjustedNonStoreSections));
    store.dispatch(setLinksChanged());
};

export const deleteSectionFromRedux = (section, isStoreSection) => {
    const adjustedStoreSections = dashboardData.storeSectionsChanged.map((element) => {
        if (element.sortId > section.sortId) {
            return { ...element, sortId: element.sortId - 1 };
        }
        return element;
    });
    const adjustedNonStoreSections = dashboardData.nonStoreSectionsChanged.map((element) => {
        if (element.sortId > section.sortId) {
            return { ...element, sortId: element.sortId - 1 };
        }
        return element;
    });
    if (isStoreSection) {
        const index = adjustedStoreSections.findIndex((element) => element.id === section.id);
        if (index !== -1) {
            adjustedStoreSections.splice(index, 1);
        }
    } else {
        const index = adjustedNonStoreSections.findIndex((element) => element.id === section.id);
        if (index !== -1) {
            adjustedNonStoreSections.splice(index, 1);
        }
    }
    store.dispatch(setStoreSectionsChanged(adjustedStoreSections));
    store.dispatch(setNonStoreSectionsChanged(adjustedNonStoreSections));
    store.dispatch(setLinksChanged());
    findDiff();
};

export const deleteSection = async (section) => {
    if (section._id) {
        await deleteSectionFromDB(section);
    } else {
        deleteSectionFromRedux(section, section.templateType === "affiliate_link");
    }
};

export const findDiff = () => {
    // Calculate Links Difference
    let linksDiff = dashboardData.linksDiff?.map((e) => e) || [];

    dashboardData.linksChanged?.forEach((element) => {
        const { id, description, sortId, isDeleted, isVisible } = element;
        if (!id) {
            updateArr(linksDiff, element);
            return;
        }

        const oldElementIndex = dashboardData.linksOriginal.findIndex((e) => e.id === id);
        if (oldElementIndex === -1) {
            updateArr(linksDiff, element);
            return;
        }

        const oldElement = dashboardData.linksOriginal[oldElementIndex];
        if (
            oldElement.description !== description ||
            oldElement.sortId !== sortId ||
            oldElement.isDeleted !== isDeleted ||
            oldElement.isVisible !== isVisible
        ) {
            updateArr(linksDiff, element);
        }
    });

    // Calculate Store Sections Difference
    let storeSectionDiff = dashboardData.sectionsDiff?.filter((e) => e.templateType === "affiliate_link") || [];

    dashboardData.storeSectionsChanged?.forEach((element) => {
        const { id, title, sortId } = element;
        if (!id) {
            updateArr(storeSectionDiff, element);
            return;
        }

        const oldElementIndex = dashboardData.storeSectionsOriginal.findIndex((e) => e.id === id);
        if (oldElementIndex === -1) {
            updateArr(storeSectionDiff, element);
            return;
        }

        const oldElement = dashboardData.storeSectionsOriginal[oldElementIndex];
        if (oldElement?.title !== title || oldElement.sortId !== sortId) {
            updateArr(storeSectionDiff, element);
        }
    });

    // Calculate Non Store Sections Difference
    let nonStoreSectionDiff = dashboardData.sectionsDiff?.filter((e) => e.templateType !== "affiliate_link") || [];

    dashboardData.nonStoreSectionsChanged.forEach((element) => {
        const { id, title, sortId } = element;
        if (!id) {
            updateArr(nonStoreSectionDiff, element);
            return;
        }

        const oldElementIndex = dashboardData.nonStoreSectionsOriginal.findIndex((e) => e.id === id);
        if (oldElementIndex === -1) {
            updateArr(nonStoreSectionDiff, element);
            return;
        }

        const oldElement = dashboardData.nonStoreSectionsOriginal[oldElementIndex];
        if (oldElement?.title !== title || oldElement.sortId !== sortId) {
            updateArr(nonStoreSectionDiff, element);
        }
    });

    function updateArr(arr, element) {
        const index = arr.findIndex((e) => e.id === element.id);
        if (index !== -1) {
            arr[index] = element;
        } else {
            arr.push(element);
        }
    }

    let sectionsDiff = [...nonStoreSectionDiff, ...storeSectionDiff];
    if (linksDiff.length + storeSectionDiff.length + nonStoreSectionDiff.length > 0 || dashboardData.profileUpdate) {
        store.dispatch(setPublishButtonEnabled(true));
    } else {
        store.dispatch(setPublishButtonEnabled(false));
    }
    store.dispatch(setLinksDiff(linksDiff));
    store.dispatch(setSectionsDiff(sectionsDiff));
};

export const storeIcon = (color) => (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M4.05301 5.04167V4.125H17.9442V5.04167H4.05301ZM4.12359 17.875V12.375H2.99609V11.4583L4.05301 6.875H17.9442L19.002 11.4583V12.375H17.8736V17.875H16.9569V12.375H12.3736V17.875H4.12359ZM5.04026 16.9583H11.4569V12.375H5.04026V16.9583ZM3.92193 11.4583H18.0753L17.2246 7.79167H4.77259L3.92193 11.4583Z"
            fill={color}
        />
    </svg>
);
export const exploreIcon = (color) => (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="prime:link">
            <path
                id="Vector"
                d="M6.62672 16.7292C5.65528 16.7324 4.72227 16.3499 4.03255 15.6658C3.33234 14.9219 2.954 13.9318 2.97975 12.9105C3.0055 11.8892 3.43325 10.9193 4.17005 10.2117L7.61672 6.7375C8.36589 5.96414 9.39099 5.51916 10.4675 5.5C10.9511 5.50322 11.4293 5.60207 11.8745 5.79086C12.3197 5.97964 12.7232 6.25464 13.0617 6.6C13.7715 7.34826 14.1555 8.34742 14.1298 9.37842C14.104 10.4094 13.6705 11.3881 12.9242 12.1L11.7692 13.255C11.7063 13.3225 11.6304 13.3767 11.546 13.4143C11.4617 13.4519 11.3707 13.4721 11.2784 13.4737C11.1861 13.4753 11.0944 13.4584 11.0088 13.4238C10.9231 13.3892 10.8454 13.3377 10.7801 13.2725C10.7148 13.2072 10.6633 13.1294 10.6288 13.0438C10.5942 12.9582 10.5772 12.8665 10.5788 12.7742C10.5805 12.6819 10.6007 12.5908 10.6382 12.5065C10.6758 12.4222 10.73 12.3463 10.7975 12.2833L11.9159 11.0917C12.3969 10.6403 12.6802 10.0172 12.7042 9.35807C12.7282 8.69889 12.491 8.05688 12.0442 7.57167C11.5557 7.14569 10.9228 6.92264 10.2752 6.9482C9.6276 6.97377 9.01427 7.24601 8.56088 7.70917L5.14172 11.1742C4.66975 11.6245 4.39149 12.2408 4.36583 12.8926C4.34017 13.5444 4.56911 14.1806 5.00422 14.6667C5.24335 14.903 5.53156 15.0838 5.84839 15.1963C6.16523 15.3088 6.50293 15.3501 6.83755 15.3175C6.9267 15.3087 7.01672 15.3176 7.10241 15.3437C7.18811 15.3699 7.26777 15.4127 7.3368 15.4698C7.40584 15.5269 7.46288 15.5971 7.50463 15.6764C7.54638 15.7557 7.57201 15.8424 7.58005 15.9317C7.59798 16.1127 7.5434 16.2935 7.42828 16.4344C7.31316 16.5753 7.14688 16.6648 6.96588 16.6833L6.62672 16.7292Z"
                fill={color}
            />
            <path
                id="Vector_2"
                d="M11.5306 16.4997C11.047 16.4965 10.5689 16.3977 10.1237 16.2089C9.67844 16.0201 9.27496 15.7451 8.93646 15.3997C8.22672 14.6515 7.84263 13.6523 7.86841 12.6213C7.89418 11.5903 8.32771 10.6116 9.07396 9.89975L10.229 8.74475C10.2919 8.6772 10.3678 8.62303 10.4521 8.58545C10.5365 8.54787 10.6275 8.52767 10.7198 8.52604C10.8121 8.52441 10.9038 8.54139 10.9894 8.57597C11.075 8.61055 11.1528 8.66201 11.2181 8.7273C11.2834 8.79258 11.3348 8.87035 11.3694 8.95595C11.404 9.04156 11.421 9.13325 11.4193 9.22556C11.4177 9.31787 11.3975 9.40891 11.3599 9.49324C11.3223 9.57758 11.2682 9.65348 11.2006 9.71642L10.0823 10.9081C9.60131 11.3595 9.318 11.9825 9.29397 12.6417C9.26993 13.3009 9.50713 13.9429 9.95396 14.4281C10.4424 14.8541 11.0753 15.0771 11.7229 15.0515C12.3706 15.026 12.9839 14.7537 13.4373 14.2906L16.8931 10.8256C17.36 10.3704 17.6315 9.7515 17.6503 9.09974C17.6691 8.44798 17.4338 7.81445 16.994 7.33308C16.7548 7.09676 16.4666 6.91595 16.1498 6.80347C15.8329 6.69099 15.4952 6.64961 15.1606 6.68225C15.0715 6.69109 14.9814 6.68217 14.8958 6.65603C14.8101 6.62988 14.7304 6.58702 14.6614 6.52992C14.5923 6.47281 14.5353 6.4026 14.4935 6.32333C14.4518 6.24407 14.4262 6.15731 14.4181 6.06808C14.4002 5.88702 14.4548 5.70624 14.5699 5.56535C14.685 5.42445 14.8513 5.33494 15.0323 5.31642C15.5659 5.26111 16.1051 5.32356 16.612 5.49937C17.1188 5.67517 17.5809 5.96006 17.9656 6.33392C18.6658 7.07782 19.0442 8.06799 19.0184 9.08927C18.9927 10.1106 18.5649 11.0804 17.8281 11.7881L14.3815 15.2622C13.6323 16.0356 12.6072 16.4806 11.5306 16.4997Z"
                fill={color}
            />
        </g>
    </svg>
);
