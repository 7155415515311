import React, { useState, useEffect } from "react";
import styles from "./index.module.css";
import { useSelector } from "react-redux";
import ProductGrid from "../ProductsGrid";
import { addEvent } from "../../../../Services/mixpanel";
import { MixpanelEvents } from "../../../../constants/mixpanelEvents";

function index({ categories }) {
    const { productsData } = useSelector((state) => state.root.wall);
    const [filteredProducts, setFilteredProducts] = useState([]);
    const [clickedIndex, setClickedIndex] = useState(null);
    const [selectedCategory, setSelectedCategory] = useState(null);

    const handleClick = (index, category) => {
        setClickedIndex(index);
        setSelectedCategory(category);
    };

    useEffect(() => {
        if (selectedCategory) {
            const filtered = productsData?.filter((product) => {
                return product?.metaData?.response?.categories?.includes(selectedCategory);
            });
            setFilteredProducts(filtered);
        } else {
            setFilteredProducts([]);
        }
    }, [selectedCategory, productsData]);

    let midIndex = 0;
    let firstHalf = [];
    let secondHalf = [];

    if (categories.length > 8) {
        midIndex = Math.ceil(categories.length / 2);
        firstHalf = categories.slice(0, midIndex);
        secondHalf = categories.slice(midIndex);
    } else {
        firstHalf = categories.slice();
    }

    return (
        <div className={styles.container}>
            <div className={styles.scrollContainer}>
                <div className={styles.row}>
                    {firstHalf.map((item, index) => (
                        <div
                            className={`${styles.imageItem} ${clickedIndex === index ? styles.clicked : ""}`}
                            key={index}
                            onClick={() => {
                                handleClick(index, item.category);
                                addEvent({}, (MixpanelEvents.SELECTED_CATEGORY = `${item.category}`));
                            }}
                        >
                            <img src={item.icon} alt={item.category} />
                            <div className={styles.circle}></div>
                            <div className={styles.imageText}>{item.category}</div>
                        </div>
                    ))}
                </div>
                {secondHalf.length > 0 && (
                    <div className={styles.row}>
                        {secondHalf.map((item, index) => (
                            <div
                                className={`${styles.imageItem} ${clickedIndex === index + midIndex ? styles.clicked : ""}`}
                                key={index + midIndex}
                                onClick={() => {
                                    handleClick(index + midIndex, item.category);
                                    addEvent({}, (MixpanelEvents.SELECTED_CATEGORY = `${item.category}`));
                                }}
                            >
                                <img src={item.icon} alt={item.category} />
                                <div className={styles.circle}></div>
                                <div className={styles.imageText}>{item.category}</div>
                            </div>
                        ))}
                    </div>
                )}
            </div>
            {filteredProducts && <ProductGrid products={filteredProducts} />}
        </div>
    );
}

export default index;
