import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";

import styles from "./index.module.css";
import accordionCollapse from "./../../../../assets/accordionCollapse.png";
import accordionExpand from "./../../../../assets/accordionExpand.png";
import { useDispatch, useSelector } from "react-redux";
import pluginsEmpty from "./../../../../assets/plugins.svg";
import pluginsFilled from "./../../../../assets/pluginsFilled.svg";
import { setInstaAuthModalClose, setInstaAuthModalOpen, setPluginSelected } from "../../../../redux/slice/dashboardSlice";
import Modal from "./../../../CommonV2/Modal";
import { postAPIData } from "../../../../Services/utils";
import Inbox from "./inbox";

const index = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const { user } = useSelector((state) => state.root.auth);
    const { pluginSelected, pluginsData, instaAuthModalOpen, instaAuth } = useSelector((state) => state.root.dashboard);
    const dispatch = useDispatch();
    const [isCollapsed, setIsCollapsed] = useState(false);

    const instaAuthHandler = () => {
        // const url = `https://www.facebook.com/v19.0/dialog/oauth?client_id=1354273788382350&display=page&extras=%7B%22setup%22%3A%7B%22channel%22%3A%22IG_API_ONBOARDING%22%7D%7D&redirect_uri=https%3A%2F%2Ffaym.co%2Fdashboard&response_type=token&state=${user._id}&scope=email%2Cpublic_profile%2Cbusiness_management%2Cinstagram_basic%2Cinstagram_manage_comments%2Cinstagram_manage_messages%2Cpages_show_list%2Cpages_read_engagement%2Cpages_messaging%2Cpages_messaging_subscriptions%2Cpages_manage_metadata%2Cpages_read_user_content`;
        const url = `https://www.instagram.com/oauth/authorize?enable_fb_login=0&force_authentication=1&client_id=1578040092808921&redirect_uri=https%3A%2F%2Fapp.faym.co/dashboard/&response_type=code&scope=business_basic%2Cbusiness_manage_messages%2Cbusiness_manage_comments&logger_id=33155cf7-70f2-43e3-9bab-92119ad3a63d&state=basic:${user._id}`;
        window.open(url, "_self");
        setInstaAuthModalClose();
    };
    const handleMetaAuth = async () => {
        const urlObject = new URL(window.location.href);
        let requestBody = {
            platform: "instagram",
            userId: user._id,
            accessToken: null,
            error: {}
        };
        if (urlObject.hash) {
            requestBody.accessToken = urlObject.hash.split("&")[0].split("=")[1];
        }

        if (urlObject.searchParams.has("error")) {
            requestBody.error = {
                error: urlObject.searchParams.get("error"),
                errorCode: urlObject.searchParams.get("error_code"),
                errorDescription: urlObject.searchParams.get("error_description"),
                errorReason: urlObject.searchParams.get("error_reason")
            };
        }
        await postAPIData({
            url: "v1/meta/update/token",
            data: requestBody
        }).then(() => window.open(urlObject.origin + urlObject.pathname, "_self"));
    };

    const handleInstagramAuth = async () => {
        const urlObject = new URL(window.location.href);
        let requestBody = {
            platform: "instagram",
            userId: null,
            accessToken: null,
            code: null,
            error: {},
            redirect_uri: "https://app.faym.co/dashboard/"
        };

        const fullUrlArray = window.location.href.split("?")[1].split("&");

        requestBody.userId = fullUrlArray[fullUrlArray.length - 1].split("=")[1].replace("#_", "").split(":")[1];
        requestBody.code = fullUrlArray[0].split("=")[1] || "";

        if (urlObject.searchParams.has("error")) {
            requestBody.error = {
                error: urlObject.searchParams.get("error"),
                errorCode: urlObject.searchParams.get("error_code"),
                errorDescription: urlObject.searchParams.get("error_description"),
                errorReason: urlObject.searchParams.get("error_reason")
            };
        }

        postAPIData({
            url: "v1/meta/instagram/update/token",
            data: requestBody
        }).then(() => {
            window.open(urlObject.origin + urlObject.pathname, "_self");
        });
    };

    // useEffect(() => {
    //     if (window.location.href.includes("access_token") || window.location.href.includes("error")) {
    //         handleMetaAuth();
    //     }
    // }, []);

    useEffect(() => {
        if (window.location.href.includes("?")) {
            const fullUrlArray = window.location.href.split("?")[1].split("&");
            if (fullUrlArray[fullUrlArray.length - 1].split("=")[1].split(":")[0] == "basic") {
                handleInstagramAuth();
            } else if (window.location.href.includes("access_token") || window.location.href.includes("error")) {
                handleMetaAuth();
            }
        }
    }, []);
    return (
        <div
            className={styles.accordion}
            onClick={(event) => {
                event.stopPropagation();
                dispatch(setPluginSelected(true));
            }}
        >
            <div className={styles.accordionHead} onClick={() => setIsCollapsed(!isCollapsed)}>
                <div className={styles.accordionHeadingContent}>
                    <img src={pluginSelected ? pluginsFilled : pluginsEmpty} alt="icon" />
                    <div>Plugins</div>
                </div>
                <img className={styles.headerAccordionCollapse} src={isCollapsed ? accordionExpand : accordionCollapse} alt="arrow" />
            </div>
            {!isCollapsed && (
                <div className={styles.accordionContent}>
                    {pluginsData.map((e, i) => {
                        return (
                            <div
                                className={styles.pluginItem}
                                onClick={e.title === "INSTAGRAM" ? () => dispatch(setInstaAuthModalOpen()) : () => {}}
                                key={i}
                            >
                                <img src={e.image} key={i} className={styles.pluginImage} />
                                <div className={styles.pluginDetails}>
                                    <div className={styles.pluginTitle}>{e.title}</div>
                                    <div className={styles.pluginDescription}>List all product recommendations</div>
                                    <div className={styles.pluginStatus}>{e.status}</div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            )}
            <Modal showModal={instaAuthModalOpen} onClose={() => dispatch(setInstaAuthModalClose())} alignItems="center">
                <div onClick={(e) => e.stopPropagation()}>
                    {instaAuth ? (
                        ["660d2b9ff4ce1df777ee62f8"].includes(user._id) ? (
                            <Inbox />
                        ) : (
                            <div className={styles.instaWaitlistModalContent} onClick={(e) => e.stopPropagation()}>
                                <div className={styles.instaWaitlistModalHeader}></div>
                                <div className={styles.instaWaitlistModalHeading}>Integrate Instagram for</div>
                                <li className={styles.instaWaitlistModalDescription}>Automated link sharing over DM’s</li>
                                <li className={styles.instaWaitlistModalDescription}>
                                    Fetch all the latest reels and post on Faym wall automatically
                                </li>
                                <li className={styles.instaWaitlistModalDescription}>
                                    Updates the followers and posts on Instagram section
                                </li>
                                <div className={styles.instaWaitlistModalButtons}>
                                    <div
                                        className={styles.storeWaitlistModalClose}
                                        style={{ border: " 1px solid red", color: "red" }}
                                        onClick={async () => {
                                            postAPIData(
                                                {
                                                    url: "v1/meta/disintegrate",
                                                    data: {
                                                        userId: user._id
                                                    }
                                                },
                                                true
                                            ).then(() => {
                                                window.location.reload();
                                            });
                                            dispatch(setInstaAuthModalClose());
                                        }}
                                    >
                                        Disintegrate Now
                                    </div>
                                </div>
                            </div>
                        )
                    ) : (
                        <div className={styles.instaWaitlistModalContent} onClick={(e) => e.stopPropagation()}>
                            <div className={styles.instaWaitlistModalHeader}></div>
                            <div>
                                <div className={`${styles.instaWaitlistModalHeading} ${styles.heading2}`}>
                                    Before integrating Instagram, ensure these criteria are met:
                                </div>
                                <ol style={{ marginLeft: "23px" }}>
                                    <li className={styles.instaWaitlistModalPreRequisites}>Your Faym Store Plugin is Integrated. </li>
                                    <li className={styles.instaWaitlistModalPreRequisites}>
                                        You possess a Facebook account.{"  "}
                                        <a
                                            href="https://www.facebook.com/help/188157731232424"
                                            target="_blank"
                                            className={styles.instaModalLearnMore}
                                        >
                                            Learn More
                                        </a>
                                    </li>
                                    <li className={styles.instaWaitlistModalPreRequisites}>
                                        You own a Facebook page.{"  "}
                                        <a
                                            href="https://www.facebook.com/help/104002523024878"
                                            target="_blank"
                                            className={styles.instaModalLearnMore}
                                        >
                                            Learn More
                                        </a>
                                    </li>
                                    <li className={styles.instaWaitlistModalPreRequisites}>
                                        You maintain an Instagram professional account.{"  "}
                                        <a
                                            href="https://help.instagram.com/502981923235522"
                                            target="_blank"
                                            className={styles.instaModalLearnMore}
                                        >
                                            Learn More
                                        </a>
                                    </li>
                                    <li className={styles.instaWaitlistModalPreRequisites}>
                                        Your Instagram account is linked to your Facebook page.{"  "}
                                        <a
                                            href="https://help.instagram.com/570895513091465"
                                            target="_blank"
                                            className={styles.instaModalLearnMore}
                                        >
                                            Learn More
                                        </a>
                                    </li>
                                </ol>
                            </div>
                            <div className={styles.instaWaitlistModalHeading}>Integrate Instagram for</div>
                            <li className={styles.instaWaitlistModalDescription}>Automated link sharing over DM’s</li>
                            <li className={styles.instaWaitlistModalDescription}>
                                Fetch all the latest reels and post on Faym wall automatically
                            </li>
                            <li className={styles.instaWaitlistModalDescription}>Updates the followers and posts on Instagram section</li>
                            <div className={styles.instaWaitlistModalButtons}>
                                <div className={styles.storeWaitlistModalClose} onClick={instaAuthHandler}>
                                    Integrate Now
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </Modal>
        </div>
    );
};

export default index;
