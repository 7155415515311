import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { getAPIData, postAPIData } from "../../Services/utils";
import gif1 from "../../assets/gif1.gif";
import gif2 from "../../assets/gif2.gif";
import gif3 from "../../assets/gif3.gif";
import failedInstagram from "../../assets/failedInstagram.gif";

const InstaAuthComponent = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [metaResponse, setMetaResponse] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [isSuccess, setIsSuccess] = useState(false);
    const [isError, setIsError] = useState(false);
    const [seconds, setSeconds] = useState(10);

    const fetchFullUrl = async (code, userId, flow) => {
        getAPIData({ url: "api/user", token: code })
            .then((response) => {
                if (response.status === 200 && response.data) {
                    const instaAuthHandler = () => {
                        let url;
                        if (flow === "basic") {
                            url = `https://www.instagram.com/oauth/authorize?enable_fb_login=0&force_authentication=1&client_id=1354273788382350&redirect_uri=https://app.faym.co/plugin/instagram/&response_type=code&scope=business_basic%2Cbusiness_manage_messages%2Cbusiness_manage_comments&logger_id=33155cf7-70f2-43e3-9bab-92119ad3a63d&state=basic:${userId}`;
                        } else {
                            url = `https://www.facebook.com/v19.0/dialog/oauth?client_id=1354273788382350&auth_type=reauthenticate&display=touch&extras=%7B%22setup%22%3A%7B%22channel%22%3A%22IG_API_ONBOARDING%22%7D%7D&redirect_uri=https%3A%2F%2Ffaym.co%2Fplugin%2Finstagram&response_type=token&scope=email%2Cpublic_profile%2Cbusiness_management%2Cinstagram_basic%2Cinstagram_manage_comments%2Cinstagram_manage_messages%2Cpages_show_list%2Cpages_read_engagement%2Cpages_messaging%2Cpages_messaging_subscriptions%2Cpages_manage_metadata%2Cpages_read_user_content&state=${userId}`;
                        }

                        window.open(url, "_self");
                    };
                    instaAuthHandler();
                }
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
            });
    };

    const handleMetaAuth = async () => {
        const urlObject = new URL(window.location.href);
        let requestBody = {
            platform: "instagram",
            userId: null,
            accessToken: null,
            error: {}
        };
        let flowType = null;
        const fullUrlArray = urlObject.hash.split("&");
        if (urlObject.hash) {
            requestBody.accessToken = urlObject.hash.split("&")[0].split("=")[1];
            requestBody.userId = fullUrlArray[fullUrlArray.length - 1].split("=")[1].split(":")[1];
            flowType = fullUrlArray[fullUrlArray.length - 1].split("=")[1].split(":")[0];
        }
        if (urlObject.searchParams.has("error")) {
            requestBody.error = {
                error: urlObject.searchParams.get("error"),
                errorCode: urlObject.searchParams.get("error_code"),
                errorDescription: urlObject.searchParams.get("error_description"),
                errorReason: urlObject.searchParams.get("error_reason")
            };
        }
        postAPIData({
            url: "v1/meta/update/token",
            data: requestBody
        }).then((response) => {
            if (requestBody.error?.error && requestBody.error?.errorDescription) {
                setIsError(true);
            } else {
                setIsError(false);
                setIsLoading(false);
                setIsSuccess(true);
                window.close();
            }
        });
    };

    const handleInstagramAuth = async () => {
        const urlObject = new URL(window.location.href);
        let requestBody = {
            platform: "instagram",
            userId: null,
            accessToken: null,
            code: null,
            error: {},
            redirect_uri: "https://app.faym.co/plugin/instagram/"
        };

        const fullUrlArray = window.location.href.split("?")[1].split("&");
        let flowType = null;

        requestBody.userId = fullUrlArray[fullUrlArray.length - 1].split("=")[1].replace("#_", "").split(":")[1];
        requestBody.code = fullUrlArray[0].split("=")[1] || "";
        flowType = fullUrlArray[fullUrlArray.length - 1].split("=")[1].split(":")[0];

        if (urlObject.searchParams.has("error")) {
            requestBody.error = {
                error: urlObject.searchParams.get("error"),
                errorCode: urlObject.searchParams.get("error_code"),
                errorDescription: urlObject.searchParams.get("error_description"),
                errorReason: urlObject.searchParams.get("error_reason")
            };
        }

        postAPIData({
            url: "v1/meta/instagram/update/token",
            data: requestBody
        }).then((response) => {
            if (requestBody.error?.error && requestBody.error?.errorDescription) {
                setIsError(true);
            } else {
                setIsError(false);
                setIsLoading(false);
                setIsSuccess(true);
                window.close();
                setTimeout(() => {
                    window.open("https://app.faym.co/plugin/instagram/?result=success", "_self");
                }, 7000);
            }
        });
    };

    useEffect(() => {
        const fullUrlArray = window.location.href.split("?")[1].split("&");
        if (searchParams.get("token")) {
            fetchFullUrl(searchParams.get("token"), searchParams.get("userId"), searchParams.get("flow"));
        } else if (fullUrlArray[fullUrlArray.length - 1].split("=")[1].split(":")[0] == "basic") {
            handleInstagramAuth();
        } else if (new URL(window.location.href).hash) {
            handleMetaAuth();
        }
    }, []);

    useEffect(() => {
        if (seconds > 0) {
            const timer = setInterval(() => {
                setSeconds((prevSeconds) => prevSeconds - 1);
            }, 1000);
            return () => clearInterval(timer);
        }
    }, [seconds]);

    return (
        <div className="container">
            {isError && !isSuccess && (
                <div className="container">
                    <div className="gifs">
                        <img src={failedInstagram} alt="" className="img" />
                    </div>

                    <div style={{ marginTop: "30px", width: "300px", textAlign: "center" }}>
                        We're having trouble connecting your Instagram. Please try again or contact support@faym.co.📞
                    </div>
                </div>
            )}

            {isLoading && !isError && (
                <div>
                    <div
                        style={{
                            margin: "auto",
                            width: "50px",
                            height: "50px",
                            border: "5px solid #f3f3f3",
                            borderTop: "5px solid #b60b18",
                            borderRadius: "50%",
                            animation: "spin 2s linear infinite"
                        }}
                    ></div>
                    <div style={{ marginTop: "30px", width: "300px", textAlign: "center" }}>
                        Sit back & relax while we connect your Instagram with Faym. 🔗
                    </div>
                </div>
            )}
            {!isLoading && isSuccess && (
                <div className="container">
                    <div className="gifs">
                        <img src={gif1} alt="" className="img" />
                        <img src={gif2} alt="" className="img" />
                        <img src={gif3} alt="" className="img" />
                    </div>
                    <div className="heading">Yay, Instagram Connected!</div>
                    <div className="text">
                        <div>Add product featuring reels to automatically</div>
                        <div>land product links to DMs.</div>
                    </div>
                    <div className="text" style={{ color: "red" }}>
                        Redirecting in {seconds.toString().padStart(2, "0")} seconds
                    </div>
                    <br />
                    {seconds == 0 && (
                        <>
                            <div
                                className="text1"
                                style={{ color: "black", fontSize: "12px", marginTop: "30%", fontFamily: "Gilroy-Medium" }}
                            >
                                If not redirected automatically, please close
                            </div>
                            <div className="text2" style={{ color: "black", fontSize: "12px", fontFamily: "Gilroy-Medium" }}>
                                this window and return to Faym app.
                            </div>
                        </>
                    )}
                </div>
            )}
            {!isLoading && !isSuccess && (
                <div>
                    <p>Something went wrong!</p>
                    <div style={{ fontSize: "2em" }}>❌</div>
                </div>
            )}

            {/* Keyframes for the spinner */}
            <style>
                {`
              @font-face {
                font-family: Gilroy-Bold;
                src: url(../../assets/fonts/Gilroy-Bold.woff), url(../../assets/fonts/Gilroy-Bold.woff2);
              }
              @keyframes spin {
                0% { transform: rotate(0deg); }
                100% { transform: rotate(360deg); }
              }
              *{
                margin: 0px;
                padding: 0px;
                }
                .container{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 100vh;
                    margin: 0;
                    flex-direction: column;
                }
                .gifs {
                    position: relative;
                    width: 100px;
                    height: 100px;
                }
                .gifs img {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                }
                .heading{
                    font-family: Gilroy-SemiBold;
                    font-size: 22px;
                    font-weight: 400;
                    line-height: 28px;
                    text-align: center;
                    color: #1B1818;
                }
                .text{
                    font-family: Gilroy-Medium;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 20px;
                    text-align: center;
                    color: #323232;
                }
            `}
            </style>
        </div>
    );
};

export default InstaAuthComponent;
