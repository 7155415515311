import SignUp from "./components/SignUp/SignUp";
import { useEffect } from "react";
import ReactGA from "react-ga4";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import InfoScreen from "./components/InfoScreen/InfoScreen";
import ProtectedRoute from "./components/ProtectedRoute";
import Dashboard from "./components/Builder";
import MobileDashboard from "./components/Builder/MobileDashboard";
import { Navigate } from "react-router-dom";
import axios from "axios";
import { message } from "antd";
import Analytics from "./components/Analytics";
import Profile from "./components/Profile";
import { AfterLoginRoute, ProtectedRouteInfo } from "./components/AfterLoginRoute";
import ShortUrlComponent from "./components/Wall3/shortUrlComponent";
import { Provider } from "react-redux";
import { store, persistor } from "./redux/store";
import { PersistGate } from "redux-persist/integration/react";
import EmailVerification from "./components/EmailVerification/EmailVerification";
import AppRedirect from "./components/AppRedirect/AppRedirect";
import InstaAuthComponent from "./components/Utils/instaAuth";
import CollectionPage from "./components/Wall3/Store/CollectionPage";
import PostPage from "./components/Wall3/Store/PostPage";
import "./global.css";
import Wall3 from "./components/Wall3";

function App() {
    const location = useLocation();
    const navigate = useNavigate();
    const isMobileScreen = window.matchMedia("(max-width: 767px)").matches;
    const DashboardComponent = isMobileScreen ? MobileDashboard : Dashboard;
    const AnalyticsComponent = isMobileScreen ? MobileDashboard : Analytics;
    const InfoComponent = isMobileScreen ? MobileDashboard : InfoScreen;
    const ProfileComponent = isMobileScreen ? MobileDashboard : Profile;
    const SignUpComponent = isMobileScreen ? MobileDashboard : SignUp;

    useEffect(() => {
        if (window.location.pathname.includes("/i/")) {
            document.title = window.location.pathname.split("/").pop();
            ReactGA.send({
                hitType: "pageview",
                page: window.location.pathname.split("/").pop()
            });
        }
    }, [location]);

    if (process.env.NODE_ENV !== "development") {
        console.log = () => {};
        console.debug = () => {};
        console.info = () => {};
        console.warn = () => {};
    }

    axios.interceptors.response.use(
        (response) => {
            return response;
        },
        (error) => {
            if (error?.response?.status === 401) {
                message?.error("You are not authorized");
                localStorage.removeItem("IdToken");
                localStorage.removeItem("userName");
                navigate("/");
            }
            return Promise.reject(error);
        }
    );
    return (
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <Routes>
                    <Route index element={<Navigate replace to="login" />} />
                    <Route
                        path="login"
                        element={
                            <AfterLoginRoute>
                                <SignUpComponent />
                            </AfterLoginRoute>
                        }
                    />
                    <Route
                        path="/dashboard"
                        element={
                            <ProtectedRoute>
                                <DashboardComponent />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="/infoscreen"
                        element={
                            <ProtectedRouteInfo>
                                <InfoComponent />
                            </ProtectedRouteInfo>
                        }
                    />
                    <Route
                        path="/analytics"
                        element={
                            <ProtectedRoute>
                                <AnalyticsComponent />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="/profile"
                        element={
                            <ProtectedRoute>
                                <ProfileComponent />
                            </ProtectedRoute>
                        }
                    />
                    <Route path="/i/:username" element={<Wall3 />} />
                    <Route path="/post/:id" element={<PostPage />} />
                    <Route path="/collection/:id" element={<CollectionPage />} />
                    <Route path="/p/:code" element={<ShortUrlComponent />} />
                    <Route path="/email_verification?" element={<EmailVerification />} />
                    <Route path="/download" element={<AppRedirect />} />
                    <Route path="/plugin/instagram" element={<InstaAuthComponent />} />
                </Routes>
            </PersistGate>
        </Provider>
    );
}

export default App;
