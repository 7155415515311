import {
    openSectionPickModal,
    setMagicButtonState,
    setMagicLinkResponse,
    addLinkToStoreSections,
    addLinkToNonStoreSections,
    setMagicLinkInput,
    setNonStoreAddedTemplateType,
    setStoreSectionsChanged,
    setNonStoreSectionsChanged,
    setLinksChanged
} from "../../redux/slice/dashboardSlice";
import { getScrapperAPI } from "../utils";
import { magicButtonStates } from "../../enums";
import store from "../../redux/store";
import { v4 as uuidv4 } from "uuid";
import { createNewSection, findDiff, sectionTemplateMap } from "../../components/Builder/builderServices/utils";

function selectAuth(state) {
    return state.root.auth;
}

function selectDashboard(state) {
    return state.root.dashboard;
}

let userData;
let dashboardData;
function listener() {
    userData = selectAuth(store.getState());
    dashboardData = selectDashboard(store.getState());
}

store.subscribe(listener);

export const getLinkData = async (magicLink) => {
    const templatesToCheck = ["other", "social_media", "insta_reels", "youtube_shorts", "application"];
    try {
        store.dispatch(setMagicButtonState(magicButtonStates.LOADING));
        const res = await getScrapperAPI("resolve2?url=" + encodeURIComponent(magicLink) + "&username=" + userData.user.userName);
        if (res.status === 200) {
            if (
                (dashboardData.selectedHeader === "Collections" && res.data.section != "affiliate_link") ||
                (dashboardData.selectedHeader === "LinkInBio" && res.data.section === "affiliate_link")
            ) {
                store.dispatch(setMagicButtonState(magicButtonStates.INVALID));
            } else {
                store.dispatch(setMagicButtonState(magicButtonStates.INITIAL));
                store.dispatch(setMagicLinkResponse(res.data));
                if (dashboardData.selectedHeader === "Collections") {
                    if (dashboardData.storeSectionsChanged.length > 1) {
                        store.dispatch(openSectionPickModal(true));
                    } else if (dashboardData.storeSectionsChanged.length === 1) {
                        addLinkToStoreSectionsWrapper(dashboardData.storeSectionsChanged[0], dashboardData.magicLinkResponse);
                    } else {
                        addNewLinkWithNewStoreSection("");
                    }
                } else if (dashboardData.selectedHeader === "LinkInBio") {
                    if (
                        dashboardData.nonStoreSectionsChanged.filter(
                            (e) =>
                                templatesToCheck.includes(dashboardData.magicLinkResponse.section) &&
                                e.templateType === dashboardData.magicLinkResponse.section
                        ).length > 1
                    ) {
                        store.dispatch(setNonStoreAddedTemplateType(dashboardData.magicLinkResponse.section));
                        store.dispatch(openSectionPickModal(true));
                    } else if (
                        dashboardData.nonStoreSectionsChanged.filter(
                            (e) =>
                                templatesToCheck.includes(dashboardData.magicLinkResponse.section) &&
                                e.templateType === dashboardData.magicLinkResponse.section
                        ).length === 1
                    ) {
                        addLinkToNonStoreSectionsWrapper(
                            dashboardData.nonStoreSectionsChanged.filter(
                                (e) => e.templateType === dashboardData.magicLinkResponse.section
                            )[0],
                            dashboardData.magicLinkResponse
                        );
                    } else {
                        addNewLinkWithNewNonStoreSection("");
                    }
                }
            }
        } else {
            store.dispatch(setMagicButtonState(magicButtonStates.FAILED));
        }
    } catch (error) {
        console.error(error);
        store.dispatch(setMagicButtonState(magicButtonStates.FAILED));
    }
};

export const addNewLinkWithNewStoreSection = (title) => {
    let maxSortId = 0;
    dashboardData.storeSectionsChanged.forEach((element) => {
        if (maxSortId < element.sortId) {
            maxSortId = element.sortId;
        }
    });
    dashboardData.nonStoreSectionsChanged.forEach((element) => {
        if (maxSortId < element.sortId) {
            maxSortId = element.sortId;
        }
    });
    let newSection = createNewSection(
        userData.user.id,
        "affiliate_link",
        null,
        title.length > 0 ? title : sectionTemplateMap.affiliate_link.title,
        "section",
        maxSortId + 1
    );
    let adjustedStoreSections = [newSection, ...dashboardData.storeSectionsChanged];
    store.dispatch(setStoreSectionsChanged(adjustedStoreSections));
    store.dispatch(setLinksChanged());
    addLinkToStoreSectionsWrapper(newSection, dashboardData.magicLinkResponse);
};

export const addNewLinkWithNewNonStoreSection = (title) => {
    let maxSortId = 0;
    dashboardData.storeSectionsChanged?.forEach((element) => {
        if (maxSortId < element.sortId) {
            maxSortId = element.sortId;
        }
    });
    dashboardData.nonStoreSectionsChanged.forEach((element) => {
        if (maxSortId < element.sortId) {
            maxSortId = element.sortId;
        }
    });
    let newSection = createNewSection(
        userData.user.id,
        dashboardData.magicLinkResponse.section,
        null,
        title.length > 0 ? title : sectionTemplateMap[`${dashboardData.magicLinkResponse.section}`].title,
        "section",
        maxSortId + 1
    );
    let adjustedNonStoreSections = [newSection, ...dashboardData.nonStoreSectionsChanged];
    store.dispatch(setNonStoreSectionsChanged(adjustedNonStoreSections));
    store.dispatch(setLinksChanged());
    addLinkToNonStoreSectionsWrapper(newSection, dashboardData.magicLinkResponse);
};

export const addLinkToStoreSectionsWrapper = (section, link) => {
    link = {
        metaData: { ...link },
        parentId: section.id,
        sortId:
            section.links.length > 1
                ? section.links.map((item) => parseInt(item.sortId, 10)).reduce((a, b) => (a > b ? a : b), 0) + 1
                : section.links.length + 1,
        id: uuidv4(),
        url: dashboardData.magicLinkInputCopy,
        isDeleted: false,
        isVisible: true
    };
    store.dispatch(addLinkToStoreSections({ section: section, link: link }));
    store.dispatch(setLinksChanged());
    store.dispatch(setMagicButtonState(magicButtonStates.SUCCESS));
    findDiff();
    setTimeout(() => {
        store.dispatch(setMagicButtonState(magicButtonStates.INITIAL));
        store.dispatch(setMagicLinkInput(""));
    }, 2000);
};

export const addLinkToNonStoreSectionsWrapper = (section, link) => {
    link = {
        metaData: { ...link },
        parentId: section.id,
        sortId:
            section.links.length > 1
                ? section.links.map((item) => parseInt(item.sortId, 10)).reduce((a, b) => (a > b ? a : b), 0) + 1
                : section.links.length + 1,
        id: uuidv4(),
        url: dashboardData.magicLinkInputCopy,
        isDeleted: false,
        isVisible: true
    };
    store.dispatch(addLinkToNonStoreSections({ section: section, link: link }));
    store.dispatch(setLinksChanged());
    store.dispatch(setMagicButtonState(magicButtonStates.SUCCESS));
    findDiff();
    setTimeout(() => {
        store.dispatch(setMagicButtonState(magicButtonStates.INITIAL));
        store.dispatch(setMagicLinkInput(""));
    }, 2000);
};
