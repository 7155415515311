import React from "react";
import profile from "../../../../../assets/profile.jpg";
import styles from "./index.module.css";
import { useSelector } from "react-redux";
import DefaultImg from "../../../../Profile/DefaultImg";
import { useLocation } from "react-router-dom";

const Matrix = () => {
    const { user } = useSelector((state) => state.root.wall);
    return (
        <div className={styles.profile}>
            <div className={styles.photo}>
                {user && user.profileImg ? (
                    <>
                        <img className={styles.profileImg} src={user?.profileImg || profile} alt="Userimage" />
                    </>
                ) : (
                    <DefaultImg width={"4em"} radius={"50%"} fontSize={"2rem"} />
                )}
                {/* NOTE: Do not remove the below img tag as it will disturb the header architecture for backend integration. */}
                <img />
            </div>
            <div className={styles.profileInfo}>
                <div>
                    <h1 className={styles.heading}>{user?.firstName}</h1>
                </div>
                <div>
                    <p className={styles.bio}>{user.bio}</p>
                </div>
            </div>
        </div>
    );
};

export default Matrix;
