import React, { useEffect, useState } from "react";
import { Form } from "antd";
import FloatInput from "../../CommonV2/FloatInput/FloatInput";
import { Button } from "antd";
import "./infoForm.scss";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";

import { useNavigate } from "react-router-dom";
import { InfoFormValidation } from "../../../Services/validations";
import { getLocalData, postAPIData, saveDataToLocal } from "../../../Services/utils";
import TimerModal from "../../common/Modal/Modal";
import { useDispatch } from "react-redux";
import { updateUser } from "../../../redux/slice/authSlice";
import { useSelector } from "react-redux";

const InfoForm = ({ username }) =>
    // { navigateuserName, setNavigateUsername }
    {
        const dispatch = useDispatch();
        const [loading, setLoading] = useState(false);
        const [showModal, setShowModal] = useState(false);
        const [timer, setTimer] = useState(3);
        const [checkUniqueUserName, setCheckUniqueUserName] = useState(false);
        // const userName = getLocalData("userName");
        const { idToken, refreshToken } = useSelector((state) => state.root.auth);
        const {
            handleSubmit,
            control,
            formState: { errors }
        } = useForm({
            mode: "onSubmit",
            defaultValues: {
                firstName: "",
                username: username || ""
            },
            resolver: yupResolver(InfoFormValidation)
        });
        const postInfoData = async (data) => {
            try {
                setLoading(true);
                const resp = await postAPIData(
                    {
                        url: "api/user/create_username",
                        data
                    },
                    true
                );
                dispatch(updateUser(resp?.data));
                if (resp.data.message === "Username already taken") {
                    setCheckUniqueUserName(true);
                    return;
                }
                setShowModal(true);
                // saveDataToLocal(resp?.data?.firstName, "userName");
            } catch (err) {
                console.error(err);
            } finally {
                setLoading(false);
            }
        };

        useEffect(() => {
            setTimeout(() => {
                setCheckUniqueUserName(false);
            }, 1000);
        }, [checkUniqueUserName]);

        const getFormData = (data) => {
            postInfoData(data);
        };
        return (
            <div className="signIn-form-container">
                {showModal && <TimerModal show={showModal} setShow={setShowModal} timer={timer} setTimer={setTimer} />}
                <Form size="large" name="user_info" className="login-form" layout="vertical" onFinish={handleSubmit(getFormData)}>
                    <Form.Item name="firstName">
                        <FloatInput control={control} error={errors} label="Full Name" placeholder="Full Name" name="firstName" />
                    </Form.Item>
                    <Form.Item name="username" className="username-input">
                        <FloatInput
                            control={control}
                            error={errors}
                            addonBefore="Faym.co/"
                            name="username"
                            placeholder="username"
                            username={username}
                        />
                    </Form.Item>
                    {checkUniqueUserName ? <p className="dup-username">Username already taken</p> : ""}
                    <Form.Item className="sigIn-btn">
                        <Button type="primary" loading={loading} danger block htmlType="submit" className="login-form-button">
                            Continue
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        );
    };

export default InfoForm;
