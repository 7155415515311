// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Platform_gridContainer__ed7zo {\n    display: grid;\n    grid-template-columns: repeat(3, 1fr);\n    gap: 0.5em;\n    position: relative;\n}\n\n.Platform_imageItem__kxqgb {\n    position: relative;\n    cursor: pointer;\n}\n\n.Platform_imageItem__kxqgb img {\n    width: 100%;\n    height: auto;\n    object-fit: contain;\n}\n\n.Platform_imageItem__kxqgb:not(:last-child)::before {\n    content: \"\";\n    position: absolute;\n    top: 30%;\n    bottom: 30%;\n    left: calc(100% + 5px);\n    min-width: 0.75px;\n    max-width: 1px;\n    background-color: black;\n}\n\n.Platform_imageItem__kxqgb:nth-child(3n)::before {\n    content: none;\n}\n", "",{"version":3,"sources":["webpack://./src/components/Wall3/Store/Platform/index.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,qCAAqC;IACrC,UAAU;IACV,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,eAAe;AACnB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,kBAAkB;IAClB,QAAQ;IACR,WAAW;IACX,sBAAsB;IACtB,iBAAiB;IACjB,cAAc;IACd,uBAAuB;AAC3B;;AAEA;IACI,aAAa;AACjB","sourcesContent":[".gridContainer {\n    display: grid;\n    grid-template-columns: repeat(3, 1fr);\n    gap: 0.5em;\n    position: relative;\n}\n\n.imageItem {\n    position: relative;\n    cursor: pointer;\n}\n\n.imageItem img {\n    width: 100%;\n    height: auto;\n    object-fit: contain;\n}\n\n.imageItem:not(:last-child)::before {\n    content: \"\";\n    position: absolute;\n    top: 30%;\n    bottom: 30%;\n    left: calc(100% + 5px);\n    min-width: 0.75px;\n    max-width: 1px;\n    background-color: black;\n}\n\n.imageItem:nth-child(3n)::before {\n    content: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"gridContainer": "Platform_gridContainer__ed7zo",
	"imageItem": "Platform_imageItem__kxqgb"
};
export default ___CSS_LOADER_EXPORT___;
