import React from "react";
import styles from "./index.module.css";
import ProfileAndTheme from "./ProfileAndTheme";
import LinkInBio from "./LinkInBio";
import Posts from "./Posts";
import Collections from "./Collections";
import { setSelectedHeader } from "../../../redux/slice/dashboardSlice";
import { useSelector, useDispatch } from "react-redux";
import postsHeader from "./../../../assets/postsHeader.png";
import collectionsHeader from "./../../../assets/collectionsHeader.png";
import linkInBioHeader from "./../../../assets/linkInBioHeader.png";
import Plugins from "./Plugins";

const index = () => {
    const { selectedHeader } = useSelector((state) => state.root.dashboard);
    const dispatch = useDispatch();
    return (
        <div className={styles.container}>
            <ProfileAndTheme />
            <div className={styles.storeLinkInBioNavBar}>
                <div
                    className={styles.navBarButton}
                    onClick={() => {
                        dispatch(setSelectedHeader("Posts"));
                    }}
                >
                    <div className={styles.navButtonText}>
                        <img className={styles.headerIcon} src={postsHeader} />
                        Posts
                    </div>
                    <div className={selectedHeader === "Posts" ? styles.navButtonBottomBarSelected : styles.navButtonBottomBar}></div>
                </div>
                <div
                    className={styles.navBarButton}
                    onClick={() => {
                        dispatch(setSelectedHeader("Collections"));
                    }}
                >
                    <div className={styles.navButtonText}>
                        <img className={styles.headerIcon} src={collectionsHeader} />
                        Collections
                    </div>
                    <div className={selectedHeader === "Collections" ? styles.navButtonBottomBarSelected : styles.navButtonBottomBar}></div>
                </div>
                <div
                    className={styles.navBarButton}
                    onClick={() => {
                        dispatch(setSelectedHeader("LinkInBio"));
                    }}
                >
                    <div className={styles.navButtonText}>
                        <img className={styles.headerIcon} src={linkInBioHeader} />
                        Link In Bio
                    </div>
                    <div className={selectedHeader === "LinkInBio" ? styles.navButtonBottomBarSelected : styles.navButtonBottomBar}></div>
                </div>
            </div>
            {selectedHeader === "Posts" ? (
                <Posts />
            ) : selectedHeader === "Collections" ? (
                <Collections />
            ) : selectedHeader === "LinkInBio" ? (
                <LinkInBio />
            ) : (
                <></>
            )}
            <Plugins />
        </div>
    );
};

export default index;
