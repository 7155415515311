import React, { useRef, useState } from "react";
import styles from "./index.module.css";
import bg from "../../../../assets/collection_bg.png";
import share from "../../../../assets/icons/shareIcon.png";
import { getCollectionData } from "../../../../Services/apiServices/wallService";
import { useDispatch } from "react-redux";
import {
    updateShareModalDescripition,
    updateShareModalImage,
    updateShareModalOpen,
    updateShareShortLink
} from "../../../../redux/slice/wallSlice";
import { useNavigate } from "react-router-dom";
import CollectionGrid from "../CollectionGrid";
import { addEvent } from "../../../../Services/mixpanel";
import { MixpanelEvents } from "../../../../constants/mixpanelEvents";

function index({ data, userName, limit }) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const scrollableDivRef = useRef(null);
    const [responselength, setResponseLength] = useState(data?.length);
    const [requestSent, setRequestSent] = useState(false);

    const extractImages = (data) => {
        if (!data || !Array.isArray(data.links)) {
            return [];
        }

        return data.links
            .map((link) => {
                if (link.metaData && link.metaData.response && link.metaData.response.image) {
                    return link.metaData.response.image;
                }
                return null;
            })
            .filter((image) => image !== null);
    };

    const handelScroll = async () => {
        const scrollableDiv = scrollableDivRef.current;
        const scrollLeft = scrollableDiv.scrollLeft;
        const scrollWidth = scrollableDiv.scrollWidth - scrollableDiv.clientWidth;
        const scrolledPercentage = (scrollLeft / scrollWidth) * 100;

        if (scrolledPercentage >= 80 && !requestSent && responselength >= limit) {
            setRequestSent(true);
            let responseDataLength = await getCollectionData(userName, data?.length, limit);
            setResponseLength(responseDataLength);
            setTimeout(() => {
                setRequestSent(false);
            }, 2000);
        }
    };

    return (
        <div className={styles.container}>
            <img src={bg} alt="bg img" className={styles.background} />
            <h1 className={styles.containerHeading}>My Curated Collections</h1>
            <div className={styles.previewScrollContainer} ref={scrollableDivRef} onScroll={handelScroll}>
                {data?.map((e, i) => {
                    return (
                        e?.links?.length >= 1 && (
                            <div className={styles.collectionPreviewContainer} key={i}>
                                <img
                                    src={share}
                                    alt="share icon"
                                    className={styles.share}
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        dispatch(updateShareModalOpen());
                                        dispatch(updateShareModalDescripition(e.title));
                                        dispatch(updateShareModalImage(extractImages(e)[0]));
                                        dispatch(updateShareShortLink(`${process.env.REACT_APP_URL}/collection/${e.id}`));
                                        addEvent(
                                            { url: `${process.env.REACT_APP_URL}/collection/${e.id}` },
                                            MixpanelEvents.SHARING_COLLECTION
                                        );
                                    }}
                                />
                                <CollectionGrid images={extractImages(e)} />
                                {e.links.length > 0 && (
                                    <div className={styles.moreItems}>
                                        <span>{`${e?.linkCount ? e?.linkCount : e?.links?.length} item(s)`}</span>
                                    </div>
                                )}
                                <div className={styles.title}>{e.title}</div>
                                <button
                                    className={styles.viewButton}
                                    onClick={() => {
                                        navigate(`/collection/${e.id}`, { state: e });
                                        addEvent(
                                            { url: `${process.env.REACT_APP_URL}/collection/${e.id}` },
                                            MixpanelEvents.OPENED_COLLECTION
                                        );
                                    }}
                                >
                                    View Collection
                                </button>
                            </div>
                        )
                    );
                })}
            </div>
        </div>
    );
}

export default index;
