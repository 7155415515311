import React from "react";
import styles from "./index.module.css";
import postIcon from "./../../../../../../assets/wall-icons/post_icon.png";
import ReelIcon from "./../../../../../../assets/wall-icons/reel_icon.png";
import playButton from "./../../../../../../assets/wall-icons/play_button.png";

function index({ post, selectedPost, setPlayPost }) {
    const handleClick = (id) => {
        setPlayPost(id);
    };
    const url = post?.content.media_type === "IMAGE" ? post.content.media_url : post.content.thumbnail_url;
    return (
        <div className={styles.wrapper}>
            {selectedPost != post.id && <img src={url} alt="post image" className={styles.postImg} />}
            {selectedPost === post.id && (
                <video
                    onClick={(e) => {
                        e.stopPropagation();
                        handleClick(null);
                    }}
                    poster={url}
                    src={post.content.media_url}
                    autoPlay
                    loop
                    className={styles.postImg}
                />
            )}
            <div className={styles.overlay}></div>
            <div className={styles.contentType}>
                <img className={styles.contentTypeImg} src={post?.content.media_type === "IMAGE" ? postIcon : ReelIcon} />
            </div>
            {selectedPost != post.id && post?.content.media_type != "IMAGE" && (
                <div className={styles.playButton} onClick={() => handleClick(post.id)}>
                    <img className={styles.playButtonImg} src={playButton} />
                </div>
            )}
            <div className={styles.scroller}>
                {post.products.map((e, i) => {
                    return (
                        <img
                            key={i}
                            src={e?.metaData.response.image}
                            alt="product image"
                            className={styles.productImg}
                            style={{
                                marginLeft: i === 0 ? "0.32em" : undefined,
                                marginRight: i === post.products.length - 1 ? "0.32em" : undefined
                            }}
                        />
                    );
                })}
            </div>
            {post.products.length - 4 > 0 && (
                <div className={styles.moreItems}>
                    <span>{`${post.products.length - 4}+`}</span>
                </div>
            )}
            <div className={styles.descripition}>{post?.caption}</div>
        </div>
    );
}

export default index;
