import React, { useEffect } from "react";
import styles from "./index.module.css";
import loading from "./../../../../../assets/loading.gif";
import added from "./../../../../../assets/added.gif";
import { magicButtonStates } from "../../../../../enums";
import { setMagicButtonState } from "../../../../../redux/slice/dashboardSlice";
import { useDispatch, useSelector } from "react-redux";

const MagicLinkButton = ({ handleOnClick }) => {
    const { magicButtonState } = useSelector((state) => state.root.dashboard);
    const dispatch = useDispatch();

    const changeInvalidLinkUI = () => {
        dispatch(setMagicButtonState(magicButtonStates.INPROCESS));
    };

    const changeToInitialState = () => {
        dispatch(setMagicButtonState(magicButtonStates.INITIAL));
    };

    useEffect(() => {
        if (magicButtonState === magicButtonStates.INVALID) {
            const timeoutId = setTimeout(() => {
                changeInvalidLinkUI();
            }, 2000);
            return () => clearTimeout(timeoutId);
        }
    }, [magicButtonState]);

    let buttonStyle = {};
    let magicButtonContent;
    let onMagicButtonClick;
    let magicButtonClass;
    switch (magicButtonState) {
        case magicButtonStates.INITIAL:
            buttonStyle = {
                backgroundColor: "black",
                color: "white",
                fontFamily: "Sora",
                fontSize: "12px",
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "20px",
                marginRight: "20px",
                cursor: "pointer"
            };
            magicButtonContent = "Go!";
            onMagicButtonClick = handleOnClick;
            magicButtonClass = styles.magicLinkButton;
            break;
        case magicButtonStates.INPROCESS:
            buttonStyle = {
                backgroundColor: "black",
                color: "white",
                fontFamily: "Sora",
                fontSize: "12px",
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "20px",
                marginRight: "20px",
                cursor: "pointer"
            };
            magicButtonContent = "Go!";
            onMagicButtonClick = handleOnClick;
            magicButtonClass = styles.magicLinkButton;
            break;
        case magicButtonStates.INVALID:
            buttonStyle = {
                backgroundColor: "black",
                color: "white",
                fontFamily: "Sora",
                fontSize: "12px",
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "20px",
                cursor: "not-allowed"
            };
            magicButtonContent = "Invalid Link!";
            onMagicButtonClick = function () {};
            magicButtonClass = `${styles.magicLinkButton} ${styles.invalidShake}`;
            break;
        case magicButtonStates.LOADING:
            buttonStyle = {
                backgroundColor: "#E9474C",
                cursor: "not-allowed"
            };
            magicButtonContent = <img src={loading} alt="loader" style={{ width: "75px", height: "auto" }} />;
            onMagicButtonClick = function () {};
            magicButtonClass = styles.magicLinkButton;
            break;
        case magicButtonStates.SUCCESS:
            buttonStyle = {
                backgroundColor: "#E9474C"
            };
            magicButtonContent = (
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center"
                    }}
                >
                    <div
                        style={{
                            color: "white",
                            fontFamily: "Sora",
                            fontSize: "12px",
                            fontStyle: "normal",
                            fontWeight: "400",
                            lineHeight: "20px"
                        }}
                    >
                        Added
                    </div>
                    <img src={added} alt="" style={{ height: "50.7px", width: "auto" }} />
                </div>
            );
            onMagicButtonClick = function () {};
            magicButtonClass = styles.magicLinkButton;
            break;
        case magicButtonStates.FAILED:
            buttonStyle = {
                color: "white",
                fontFamily: "Sora",
                fontSize: "12px",
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "20px",
                cursor: "pointer"
            };
            magicButtonContent = "Try Something Else!";
            onMagicButtonClick = changeToInitialState;
            magicButtonClass = `${styles.magicLinkButton} ${styles.colorTransition}`;
            break;
        default:
            break;
    }
    return (
        <div className={magicButtonClass} style={buttonStyle} onClick={onMagicButtonClick}>
            {magicButtonContent}
        </div>
    );
};

export default MagicLinkButton;
