import React, { useState } from "react";
import styles from "./index.module.css";
import Collection from "./Collections";
import Reel from "./Reel";
import SingleReel from "./SingleReel";
import FaymBranding from "./../../FaymBranding";
import { useSelector } from "react-redux";

const index = () => {
    const { user } = useSelector((state) => state.root.auth);
    const { postsChanged, storeSectionsChanged } = useSelector((state) => state.root.dashboard);
    const [playPost, setPlayPost] = useState(null);

    return (
        <div className={styles.container}>
            <div className={styles.previewUserDetail}>
                <div className={styles.userBio}>
                    <div className={styles.name}>{user?.firstName}</div>
                    <div className={styles.bio}>{user?.bio}</div>
                </div>
                <img src={user?.profileImg} alt="" className={styles.userImg} />
            </div>
            {
                <div className={styles.pinnedReels}>
                    {postsChanged
                        ?.filter((e) => e.isPinned)
                        ?.map((e, i) => {
                            return (
                                <div
                                    onClick={() => {
                                        setPlayPost(e.id);
                                    }}
                                >
                                    {postsChanged?.filter((e) => e.isPinned).length === 2 && (
                                        <Reel key={i} post={e} selectedPost={playPost} setPlayPost={setPlayPost} />
                                    )}
                                </div>
                            );
                        })}
                </div>
            }

            {postsChanged?.filter((e) => e.isPinned).length === 1 && (
                <div
                    className={styles.singlePostContainer}
                    onClick={() => {
                        setPlayPost(postsChanged?.filter((e) => e.isPinned)[0].id);
                    }}
                >
                    <SingleReel post={postsChanged?.filter((e) => e.isPinned)[0]} selectedPost={playPost} setPlayPost={setPlayPost} />{" "}
                </div>
            )}
            {storeSectionsChanged && storeSectionsChanged.length > 0 && <Collection />}
            <div className={styles.pinnedReels}>
                {postsChanged
                    ?.filter((e) => !e.isPinned)
                    ?.map((e, i) => {
                        return (
                            <div
                                onClick={() => {
                                    setPlayPost(e.id);
                                }}
                            >
                                <Reel key={i} post={e} selectedPost={playPost} setPlayPost={setPlayPost} />
                            </div>
                        );
                    })}
            </div>
            <FaymBranding />
        </div>
    );
};

export default index;
