// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AppRedirect_container__W7jcb {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    margin-top: 5%;\n}\n\n.AppRedirect_loader__uOZwM {\n    width: 80px;\n    padding: 8px;\n    aspect-ratio: 1;\n    border-radius: 50%;\n    background: #e05bf2;\n    --_m: conic-gradient(#0000 10%, #000), linear-gradient(#000 0 0) content-box;\n    mask: var(--_m);\n    mask-composite: subtract;\n    animation: AppRedirect_l3__UxDU4 1s infinite linear;\n    margin-bottom: 2%;\n}\n\n@keyframes AppRedirect_l3__UxDU4 {\n    to {\n        transform: rotate(1turn);\n    }\n}\n", "",{"version":3,"sources":["webpack://./src/components/AppRedirect/AppRedirect.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,cAAc;AAClB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,eAAe;IACf,kBAAkB;IAClB,mBAAmB;IACnB,4EAA4E;IAE5E,eAAe;IAEf,wBAAwB;IACxB,mDAAgC;IAChC,iBAAiB;AACrB;;AAEA;IACI;QACI,wBAAwB;IAC5B;AACJ","sourcesContent":[".container {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    margin-top: 5%;\n}\n\n.loader {\n    width: 80px;\n    padding: 8px;\n    aspect-ratio: 1;\n    border-radius: 50%;\n    background: #e05bf2;\n    --_m: conic-gradient(#0000 10%, #000), linear-gradient(#000 0 0) content-box;\n    -webkit-mask: var(--_m);\n    mask: var(--_m);\n    -webkit-mask-composite: source-out;\n    mask-composite: subtract;\n    animation: l3 1s infinite linear;\n    margin-bottom: 2%;\n}\n\n@keyframes l3 {\n    to {\n        transform: rotate(1turn);\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "AppRedirect_container__W7jcb",
	"loader": "AppRedirect_loader__uOZwM",
	"l3": "AppRedirect_l3__UxDU4"
};
export default ___CSS_LOADER_EXPORT___;
