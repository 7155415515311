import React from "react";
import profile from "../../../../../assets/profile.jpg";
import headerASHue from "../../../../../assets/HeaderASHue.png";
import styles from "./index.module.css";
import { useSelector } from "react-redux";
import DefaultImg from "../../../../Profile/DefaultImg";

const Sublime = () => {
    const { user } = useSelector((state) => state.root.wall);
    return (
        <div className={styles.profile}>
            <div className={styles.photo}>
                {user && user.profileImg ? (
                    <>
                        <img className={styles.profileImg} src={user.profileImg || profile} alt="Userimage" />
                        <img className={styles.imgHue} src={headerASHue} alt="..." />
                    </>
                ) : (
                    <DefaultImg width={"14vw"} radius={"10px"} fontSize={"7rem"} />
                )}
            </div>
            <div className={styles.profileInfo}>
                <div>
                    <h1 className={styles.heading}>{user.firstName}</h1>
                </div>
                <div>
                    <p className={styles.bio}>{user.bio}</p>
                </div>
            </div>
        </div>
    );
};

export default Sublime;
