import React, { useEffect, useState } from "react";
import { Col, Row } from "antd";
import "./index.css";
import { getAPIData } from "../../../Services/utils";
const DeviceAnalytics = ({ userId }) => {
    const [deviceData, setDeviceData] = useState([]);
    const [loading, setLoading] = useState(false);
    const getDeviceData = async () => {
        try {
            setLoading(true);
            const resp = await getAPIData({
                url: `api/analytics/deviceData?id=${userId}`
            });
            setDeviceData(resp.data);
        } catch (err) {
            console.error(err);
        } finally {
            setLoading(false);
        }
    };
    function getDeviceClickCounts(deviceTxt) {
        const deviceRecord = deviceData.find((record) => record?.deviceName === deviceTxt);
        const deviceName = deviceTxt.charAt(0).toUpperCase() + deviceTxt.slice(1).toLowerCase();
        if (deviceRecord) {
            return {
                deviceName: deviceName,
                clickCount: deviceRecord?.clickCounts
            };
        } else {
            return { deviceName: deviceName, clickCount: "0" };
        }
    }
    const mobileData = getDeviceClickCounts("mobile");
    const desktopData = getDeviceClickCounts("desktop");
    const tabletData = getDeviceClickCounts("tablet");
    useEffect(() => {
        if (userId) getDeviceData();
    }, [userId]);
    return (
        <div className="analytics-summary-container">
            <Row className="analytics-header-row">
                <Col span={12}>
                    <p className="analytics-heading">Device Analytics</p>
                </Col>
            </Row>
            <Row className="analytics-body">
                <Col span={6} xs={24} md={6} className="views-col">
                    <Row>
                        <Col span={12} md={24} className="analytics-label">
                            <span style={{ color: "#FF9191" }}>&#9679;</span>
                            {mobileData?.deviceName}:
                        </Col>
                        <Col span={12} md={24} className="analytics-value">
                            {mobileData?.clickCount}
                        </Col>
                    </Row>
                </Col>
                <Col span={6} xs={24} md={6} className="clicks-col">
                    <Row>
                        <Col span={12} md={24} className="analytics-label ">
                            <span style={{ color: "#91DEFF" }}>&#9679;</span>
                            {desktopData?.deviceName}:
                        </Col>
                        <Col span={12} md={24} className="analytics-value">
                            {desktopData?.clickCount}
                        </Col>
                    </Row>
                </Col>
                <Col span={6} xs={24} md={6} className="ctr-col">
                    <Row>
                        <Col span={12} md={24} className="analytics-label">
                            <span style={{ color: "#F691FF" }}>&#9679;</span>
                            {tabletData?.deviceName}:
                        </Col>
                        <Col span={12} md={24} className="analytics-value">
                            {tabletData?.clickCount}
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    );
};

export default DeviceAnalytics;
