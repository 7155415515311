import React, { useEffect, useState } from "react";
import styles from "./index.module.css";
import LinkInBio from "./LinkInBio";
import Store from "./Store";
import BottomNav from "./BottomNav";
import { useLocation, useParams } from "react-router-dom";
import {
    getuserData,
    getLinkInBioData,
    getFilterData,
    getCollectionData,
    getPostData,
    getPinnedPostData
} from "../../Services/apiServices/wallService";
import ShareModal from "./Store/ShareBottomSheet";
import { useSelector } from "react-redux";
import NotFoundScreen from "./NotFoundScreen"; // Import NotFoundScreen component
import { addEvent } from "../../Services/mixpanel";
import { MixpanelEvents } from "../../constants/mixpanelEvents";
import { addAnalytics } from "../../Services/apiServices/dashboardService";
import { isMobile, isTablet, isBrowser } from "react-device-detect";

function Index() {
    // Rename to start with uppercase
    const location = useLocation();
    const { username } = useParams();
    const [selectedWall, setSelectedWall] = useState("store");
    const limit = 50;
    const [loading, setLoading] = useState(true);
    const { postData, collectionData, linkInBioData, user, filtersData, pinnedPostData } = useSelector((state) => state.root.wall);

    function checkDevice() {
        if (isBrowser) return "desktop";
        else if (isTablet) return "tablet";
        else if (isMobile) return "mobile";
    }

    async function analytics() {
        let analyticsData = [
            {
                eventData: { userId: user?._id.toString() },
                eventType: "PAGE_VIEW"
            },
            {
                eventData: { userId: user?._id.toString() },
                eventType: "LOCATION_DATA"
            },
            {
                eventData: { userId: user?._id.toString(), deviceName: checkDevice() },
                eventType: "DEVICE_DATA"
            }
        ];
        await addAnalytics(analyticsData);
    }

    useEffect(() => {
        const fetchData = async () => {
            if (location.pathname.startsWith("/i/")) {
                let promises = [];
                if (!user) {
                    promises.push(getuserData(username));
                }
                if (!linkInBioData?.length) {
                    promises.push(getLinkInBioData(username));
                }
                if (!filtersData) {
                    promises.push(getFilterData(username));
                }
                if (!pinnedPostData?.length) {
                    promises.push(getPinnedPostData(username));
                }
                if (!collectionData?.length) {
                    promises.push(getCollectionData(username, 0, limit));
                }
                if (!postData?.length) {
                    promises.push(getPostData(username, 0, limit));
                }
                try {
                    if (promises?.length) {
                        setLoading(true);
                        await Promise.all(promises);
                        addEvent({ userName: username }, MixpanelEvents.LOADED_FAYMWALL);
                    }
                } catch (error) {
                    console.log(error);
                } finally {
                    setLoading(false);
                }
            } else {
                setLoading(false);
            }
        };
        fetchData();
    }, []);

    useEffect(() => {
        if (!user) return;
        analytics();
    }, [user]);

    if (loading) {
        return (
            <div className={styles.loaderContainer}>
                <div className={styles.customLoader}></div>
            </div>
        );
    }

    if (!user && location.pathname.startsWith("/i/")) {
        return <NotFoundScreen />;
    }

    return (
        <div className={styles.mainContainer}>
            <div className={styles.middleSection}>
                {selectedWall === "store" ? (
                    <Store username={username} limit={limit} />
                ) : (
                    linkInBioData && linkInBioData?.length > 0 && <LinkInBio />
                )}
                {linkInBioData && linkInBioData?.length > 0 && <BottomNav selectedWall={selectedWall} setSelectedWall={setSelectedWall} />}
                <ShareModal />
            </div>
        </div>
    );
}

export default Index;
