import "./leftScreen.scss";
import faymLogo from "../../../assets/faymLogo.png";
import { useState } from "react";
import MobileForm from "../../Forms/MobileForm/MobileForm";
import GoogleSignInButton from "../../Forms/GoogleSignIn/GoogleSignIn";
const LeftScreen = () => {
    const [loginType, setLoginType] = useState(null);
    const [loading, setLoading] = useState(false);
    return (
        <div className="signIn-leftScreen-container">
            <div className="top-container">
                <img src={faymLogo} alt="faym-logo" />
                <h1 style={{ marginTop: "50px", marginBottom: "20px" }}> Welcome Back </h1>
                <p>Let's get Faymiliar. Transform your Link In Bio in few clicks!</p>
            </div>
            <MobileForm loading={loading} setLoading={setLoading} type="signUp" />

            <div className="lines-container">
                <div className="line"></div>
                <span>Or</span>
                <div className="line"></div>
            </div>
            <div className="othersLogin-container">
                <GoogleSignInButton type="signUp" loading={loading} setLoading={setLoading} />
            </div>
        </div>
    );
};

export default LeftScreen;
