import React from "react";
import appDownloadQrCode from "./../../../../assets/appDownloadQrCode.png";
import styles from "./index.module.css";

const index = () => {
    return (
        <div className={styles.postsContainer}>
            <img className={styles.qrCode} src={appDownloadQrCode} />
            <div className={styles.text}>To access this feature: Scan this QR code to install the Faym App</div>
        </div>
    );
};

export default index;
// 01244599645
