import React, { useEffect, useState } from "react";
import styles from "./EmailVerification.module.css";
import { postAPIData } from "../../Services/utils";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { useLocation } from "react-router-dom";

const EmailVerification = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [isVerified, setIsVerified] = useState(false);
    const { search } = useLocation();
    const param = new URLSearchParams(search);

    const verification = async () => {
        setIsLoading(true);

        const email = param.get("email");
        const token = param.get("token");

        const obj = {
            email,
            token
        };
        try {
            const res = await postAPIData(
                {
                    url: "auth/verify/verification_link",
                    data: obj
                },
                false
            );

            if (res.status === 200) {
                setIsVerified(true);
            }
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        verification();
    }, []);

    return (
        <div className={styles.wrapper}>
            <div className={styles.container}>
                {isLoading ? (
                    <>
                        <div className={styles.loading}></div>
                        <h3>Pending!</h3>
                        <p>Please Wait, while we are verifying your email.</p>
                    </>
                ) : isVerified ? (
                    <>
                        <CheckCircleOutlined className={styles.checkIcon} />
                        <h3>Verified!</h3>
                        <p>Voila! You have successfully verified the account.</p>
                    </>
                ) : (
                    <>
                        <CloseCircleOutlined className={styles.crossIcon} />
                        <h3>Failed</h3>
                        <p>Oops... Your verification has failed.</p>
                    </>
                )}
            </div>
        </div>
    );
};

export default EmailVerification;
