import React from "react";
import styles from "./index.module.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { addEvent } from "../../../../Services/mixpanel";
import { MixpanelEvents } from "../../../../constants/mixpanelEvents";

const index = ({ deals }) => {
    const settings = {
        className: "center",
        centerMode: true,
        infinite: true,
        centerPadding: "4%",
        slidesToShow: 1,
        speed: 500,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 3000
    };
    function onClickHandler(url) {
        window.open(url, "_self");
    }
    return (
        <div className={styles.dealSection}>
            <Slider {...settings}>
                {deals?.map((e, i) => (
                    <div
                        key={i}
                        onClick={() => {
                            console.log(e);
                            onClickHandler(e?.url);
                            addEvent({ url: e.url }, MixpanelEvents.SELECTED_DEAL);
                        }}
                    >
                        <img className={styles.dealTile} src={e?.banner} />
                    </div>
                ))}
            </Slider>
        </div>
    );
};

export default index;
