import React, { useState, useRef, useEffect } from "react";
import styles from "./index.module.css";
import DealsOfTheDay from "./DealsOfTheDay";
import Platform from "./Platform";
import Category from "./Categories";
import Collection from "./Collections";
import Reel from "./Reel";
import dealsIcon from "../../../assets/icons/deals_icon.png";
import dealsIconSelected from "../../../assets/icons/deals_icon_selected.png";
import platformIcon from "../../../assets/icons/platform_icon.png";
import platformIconSelected from "../../../assets/icons/platform_icon_selected.png";
import categoryIcon from "../../../assets/icons/category_icon.png";
import categoryIconSelected from "../../../assets/icons/category_icon_selected.png";
import faym from "../../../assets/icons/faym_logo.png";
import { useSelector } from "react-redux";
import SearchBar from "./SearchBar";
import Filter from "./filters";
import { getBrandName } from "../../Builder/RightContainer/Preview/utils";
import { getPostData } from "../../../Services/apiServices/wallService";
import SingleReel from "./SingleReel";
import ProductsGrid from "./ProductsGrid";
import store from "../../../redux/store";
import { updateProductsData } from "../../../redux/slice/wallSlice";
import { addEvent } from "../../../Services/mixpanel";
import { MixpanelEvents } from "../../../constants/mixpanelEvents";

function index({ username, limit }) {
    const { user, filtersData, pinnedPostData, postData, collectionData } = useSelector((state) => state.root.wall);

    const [isExpanded, setIsExpanded] = useState(false);
    const [searchValue, setSearchValue] = useState("");
    const [activeButton, setActiveButton] = useState("Deal");
    const scrollableDivRef = useRef(null);
    const [responselength, setResponseLength] = useState(postData?.length);
    const [requestSent, setRequestSent] = useState(false);
    const [uniqueProductList, setUniqueProductList] = useState([]);
    const [brands, setBrands] = useState();
    const [categories, setCategories] = useState([]);

    const storeProductsData = (pinnedPostData, postData, collectionData) => {
        const products = [];
        const urlSet = new Set();

        const addProducts = (data) => {
            data?.forEach((item) => {
                if (item.products && item.products.length > 0) {
                    item.products.forEach((product) => {
                        if (!urlSet.has(product.url)) {
                            urlSet.add(product.url);
                            products.push(product);
                        }
                    });
                }
            });
        };

        addProducts(postData);
        addProducts(pinnedPostData);

        collectionData?.forEach((collection) => {
            if (collection.links && collection.links.length > 0) {
                collection.links.forEach((product) => {
                    if (!urlSet.has(product.url)) {
                        urlSet.add(product.url);
                        products.push(product);
                    }
                });
            } else {
                console.log("collection empty");
            }
        });
        store.dispatch(updateProductsData(products));
        return products;
    };

    const filterPlatformsByProducts = (brands, products) => {
        try {
            const normalize = (str) => str.toLowerCase().replace(/\s+/g, "");

            if (!products || !Array.isArray(products)) {
                throw new Error("Invalid or empty products array");
            }

            if (!brands || !Array.isArray(brands)) {
                throw new Error("Invalid or empty brands array");
            }

            const productBrandNames = products?.map((product) => {
                const domain = product.metaData.domain;
                return normalize(getBrandName(domain));
            });

            const filteredBrands = brands?.filter((brand) => productBrandNames.includes(normalize(brand.platform)));
            return [...filteredBrands];
        } catch (error) {
            console.error("Error filtering platforms by products:", error.message);
            return [];
        }
    };

    const filterCategoriesByProducts = (categories, products) => {
        try {
            const productCategories = new Set(products?.map((product) => product.metaData.response.categories).flat());
            const filteredCategories = categories.filter((category) => productCategories.has(category.category));
            return filteredCategories;
        } catch (error) {
            console.error("Error filtering platforms by products:", error.message);
            return [];
        }
    };

    const handleScroll = async () => {
        const scrollableDiv = scrollableDivRef.current;
        const scrollTop = scrollableDiv.scrollTop;
        const scrollHeight = scrollableDiv.scrollHeight - scrollableDiv.clientHeight;
        const scrolledPercentage = (scrollTop / scrollHeight) * 100;

        if (scrolledPercentage >= 80 && !requestSent && responselength >= limit) {
            setRequestSent(true);
            let responseDataLength = await getPostData(username, postData?.length, limit);
            setResponseLength(responseDataLength);
            setTimeout(() => {
                setRequestSent(false);
            }, 2000);
        }
    };

    const renderContent = () => {
        switch (activeButton) {
            case "Deal":
                return <DealsOfTheDay deals={filtersData?.deal_of_the_day_1.data} />;
            case "Platform":
                return <Platform platform={brands} productList={uniqueProductList} />;
            case "Category":
                return <Category categories={categories} />;
            default:
                return null;
        }
    };

    useEffect(() => {
        const products = storeProductsData(pinnedPostData, postData, collectionData);
        setUniqueProductList(products);
        setBrands(filterPlatformsByProducts(filtersData?.platform, products));
        setCategories(filterCategoriesByProducts(filtersData?.category, products));
    }, []);

    return (
        <div className={styles.container} onScroll={handleScroll} ref={scrollableDivRef}>
            <div className={styles.userDetail}>
                <div className={styles.userBio}>
                    <h1>{user?.firstName}</h1>
                    <p>{user?.bio}</p>
                </div>
                <img src={user?.profileImg} alt="profile image" className={styles.userImg} />
            </div>
            <div>
                <div className={styles.buttonsContainer}>
                    {!isExpanded && (
                        <div className={styles.leftButtons}>
                            <button
                                className={`${styles.button} ${activeButton === "Deal" ? styles.buttonActive : ""}`}
                                onClick={() => {
                                    setActiveButton("Deal");
                                    addEvent({}, MixpanelEvents.DEALS_FILTER_CLICKED);
                                }}
                            >
                                <img src={activeButton === "Deal" ? dealsIconSelected : dealsIcon} alt="Category Icon" />
                                Deal
                            </button>
                            {brands?.length > 0 && (
                                <button
                                    className={`${styles.button} ${activeButton === "Platform" ? styles.buttonActive : ""}`}
                                    onClick={() => {
                                        setActiveButton("Platform");
                                        addEvent({}, MixpanelEvents.BRANDS_FILTER_CLICKED);
                                    }}
                                >
                                    <img src={activeButton === "Platform" ? platformIconSelected : platformIcon} alt="Category Icon" />
                                    Platform
                                </button>
                            )}

                            {categories?.length > 0 && (
                                <button
                                    className={`${styles.button} ${activeButton === "Category" ? styles.buttonActive : ""}`}
                                    onClick={() => {
                                        setActiveButton("Category");
                                        addEvent({}, MixpanelEvents.CATEGORY_FILTER_CLICKED);
                                    }}
                                >
                                    <img src={activeButton === "Category" ? categoryIconSelected : categoryIcon} alt="Category Icon" />
                                    Category
                                </button>
                            )}
                        </div>
                    )}
                    <SearchBar
                        isExpanded={isExpanded}
                        setIsExpanded={setIsExpanded}
                        searchValue={searchValue}
                        setSearchValue={setSearchValue}
                    />
                </div>
                <div className={styles.emptyDiv}></div>
                {!isExpanded ? (
                    <div className={styles.contentContainer}>{renderContent()}</div>
                ) : (
                    <Filter searchValue={searchValue} uniqueProductList={uniqueProductList} categories={categories} brands={brands} />
                )}
            </div>

            <>
                {pinnedPostData?.length === 1 ? (
                    <div className={styles.singlePost}>
                        <SingleReel post={pinnedPostData[0]} userName={username} />
                    </div>
                ) : (
                    pinnedPostData?.length > 0 && (
                        <div className={styles.posts}>
                            {pinnedPostData?.map((e, i) => (
                                <Reel key={i} post={e} userName={username} />
                            ))}
                        </div>
                    )
                )}
            </>

            {collectionData && collectionData.length > 0 && <Collection data={collectionData} userName={username} limit={limit} />}
            <div className={styles.posts}>
                {postData?.map((e, i) => {
                    return <Reel key={i} post={e} userName={username} />;
                })}
            </div>
            {uniqueProductList && <ProductsGrid products={uniqueProductList} />}
            <div className={styles.faymFooter}>
                <img
                    src={faym}
                    alt="faym logo"
                    className={styles.faymLogo}
                    onClick={() => {
                        window.open("https://faym.co", "_blank");
                        addEvent({}, MixpanelEvents.OPENED_WEBSITE);
                    }}
                />
            </div>
        </div>
    );
}

export default index;
