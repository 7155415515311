import * as yup from "yup";
export const LoginValidation = yup.object().shape({
    password: yup
        .string()
        .required("Password is required")
        .min(8, "Enter a valid password")
        .matches(/[0-9]/, "Enter a valid password")
        .matches(/[a-z]/, "Enter a valid password")
        .matches(/[A-Z]/, "Enter a valid password")
        .matches(/[!-\/:-@[-`{-~]/, "Enter a valid password"),
    email: yup
        .string()
        .required("Email is Required")
        .email("Please enter a valid email")
        .matches(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/, "Please enter a valid email")
});

export const MobileNumberLogin = yup
    .object()
    .shape({
        mobile: yup
            .string()
            .required("Mobile number is required")
            .min(10, "Mobile number is not valid")
            .max(10, "Mobile number is not valid")
            .matches(/[6-9][0-9]{9}/, "Mobile number is not valid")
    })
    .when((values, schema) => {
        if (values?.isOtpVisible) {
            return schema.shape({
                mobileOTP: yup.string().required("Otp is required").min(6, "Otp is not valid").max(6, "Otp is not valid")
            });
        }
    });
export const InfoFormValidation = yup.object().shape({
    firstName: yup
        .string()
        .required("This field is required")
        .test("spaces", "Full-name is required", (val) => !!val?.trim()),
    username: yup
        .string()
        .required("This field is required")
        .test("spaces", "Username is required", (val) => !!val?.trim())
        .matches(
            /^[a-z0-9_]{3,20}$/,
            "Username must be between 3 and 20 characters and can only contain lowercase letters, digits, and underscores"
        )
});
