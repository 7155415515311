import React from "react";
import styles from "./index.module.css";
import whatsappShare from "../../../../assets/whatsappShare.png";
import instagramShare from "../../../../assets/instagramShare.png";
import telegramShare from "../../../../assets/telegramShare.png";
import linkShare from "../../../../assets/linkShare.png";
import facebookShare from "../../../../assets/facebookShare.png";
import copyLinkShare from "../../../../assets/copyLinkShare.png";
import Modal from "../../../../components/common/ModalV2/Modal.jsx";
import modalClose from "../../../../assets/modalClose.png";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { updateShareModalClose } from "../../../../redux/slice/wallSlice";
import { message } from "antd";
import { addEvent } from "../../../../Services/mixpanel.js";
import { MixpanelEvents } from "../../../../constants/mixpanelEvents.js";
import { MixpanelSharables } from "../../../../constants/mixpanelEvents.js";

const index = () => {
    const { shareModal, shareShortLink, shareModalImage, shareModalDescripition } = useSelector((state) => state.root.wall);
    const shortLinkArr = shareShortLink?.split("-");

    const shorterLink = shortLinkArr ? shortLinkArr[0] : "";
    const dispatch = useDispatch();
    const alignItems = "flex-end";

    const imgShareArr = [
        {
            img: whatsappShare,
            platform: "whatsapp"
        },
        {
            img: instagramShare,
            platform: "instagram"
        },
        {
            img: telegramShare,
            platform: "telegram"
        },
        {
            img: facebookShare,
            platform: "facebook"
        }
    ];

    const handleShareClick = (platform, shortLink) => {
        const subRoute = shortLink.split("/");
        let mixpanelSharableEntity = "";
        for (let i = 2; i < subRoute.length; i++) {
            if (MixpanelSharables.hasOwnProperty(subRoute[i])) {
                mixpanelSharableEntity = MixpanelSharables[subRoute[i]];
            }
        }
        const shareText = `Hey, check out this featured product in my Faym Store 🛒 🔗: ${shortLink}`;
        switch (platform) {
            case "telegram":
                const telegramUrl = `https://t.me/share/url?url=${encodeURIComponent(shortLink)}&text=${encodeURIComponent(
                    `${shareText}`
                )}`;
                window.location.href = telegramUrl;
                addEvent({ url: shortLink }, mixpanelSharableEntity + "TELEGRAM_SHARE_CLICKED");
                break;
            case "facebook":
                const facebookUrl = `https://www.facebook.com/dialog/share?app_id=1837914529944849&display=popup&href=${encodeURIComponent(
                    shortLink
                )}&quote=${encodeURIComponent(`${shareText}`)}`;
                window.open(facebookUrl, "Facebook Share", "width=600,height=400");
                addEvent({ url: shortLink }, mixpanelSharableEntity + "FACEBOOK_SHARE_CLICKED");
                break;
            case "whatsapp":
                const whatsappUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(`${shareText}`)}`;
                window.open(whatsappUrl, "_blank");
                addEvent({ url: shortLink }, mixpanelSharableEntity + "WHATSAPP_SHARE_CLICKED");
                break;
            case "instagram":
                setTimeout(() => {
                    const instagramUrl = `instagram://direct-inbox`;
                    window.location.href = `intent:${instagramUrl}#Intent;end`;
                }, 1000);
                handleCopyClick(shareText);
                addEvent({ url: shortLink }, mixpanelSharableEntity + "INSTAGRAM_SHARE_CLICKED");
                break;
            default:
                console.error("Unsupported platform");
                return;
        }
    };

    const closeModalHandler = () => dispatch(updateShareModalClose());

    const handleCopyClick = async (shortLink) => {
        if (navigator.clipboard) {
            try {
                await navigator.clipboard.writeText(shortLink);
                message.success("Text copied to clipboard!");
            } catch (err) {
                console.error("Failed to copy text: ", err);
            }
        } else {
            fallbackCopyTextToClipboard(shortLink);
        }
        addEvent({}, MixpanelEvents.COPY_SHORT_LINK);
    };
    const fallbackCopyTextToClipboard = (text) => {
        const textArea = document.createElement("textarea");
        textArea.value = text;
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        try {
            document.execCommand("copy");
            message.success("Text copied to clipboard!");
        } catch (err) {
            console.error("Fallback: Oops, unable to copy", err);
        }
        document.body.removeChild(textArea);
    };

    return (
        <>
            <Modal showModal={shareModal} onClose={closeModalHandler} alignItems={alignItems}>
                <div className={styles.modalContent} onClick={(e) => e.stopPropagation()}>
                    <div className={styles.header}>
                        <div className={styles.title}>Share this link</div>
                        <div
                            className={styles.closeButton}
                            style={{ backgroundImage: `url(${modalClose})` }}
                            onClick={closeModalHandler}
                        ></div>
                    </div>
                    <div className={styles.product}>
                        <img src={shareModalImage} alt="product" className={styles.productImage} />
                        <div className={styles.productInfo}>
                            <div className={styles.modalProductDescription}>{shareModalDescripition}</div>
                        </div>
                    </div>
                    <div className={styles.shareVia}>Share this via</div>
                    <div className={styles.shareIcons}>
                        {imgShareArr?.map((e, i) => (
                            <div
                                key={i}
                                className={styles.shareIcon}
                                onClick={() => {
                                    handleShareClick(e.platform, shareShortLink);
                                }}
                            >
                                <img src={e.img} alt={e.platform} className={styles.iconImage} />
                            </div>
                        ))}
                    </div>
                    <div className={styles.copyLink}>Copy Link</div>
                    <div className={styles.linkContainer}>
                        <img src={linkShare} alt="link" className={styles.linkImage} />
                        <div className={styles.linkText}>{shorterLink}</div>
                        <img onClick={() => handleCopyClick(shorterLink)} src={copyLinkShare} alt="copy" className={styles.copyIcon} />
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default index;
