import React, { useState, useEffect } from "react";
import { Col, Row, Table } from "antd";
import { getAPIData } from "../../../Services/utils";

const GameAnalytics = ({ userId }) => {
    const [loading, setLoading] = useState(false);
    const columns = [
        { width: "80%", dataIndex: "heading" },
        { dataIndex: "count", title: "Count" }
    ];
    const [data, setData] = useState();

    const getData = async () => {
        try {
            setLoading(true);
            const resp = await getAPIData({
                url: `games/analytics?id=${userId}`
            });
            setData(resp.data);
        } catch (err) {
            console.error(err);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (userId) getData();
    }, [userId]);

    const analyticsData = [
        {
            heading: "Games Played",
            count: data?.gamesPlayedCount
        },
        {
            heading: "Coupons Redeemed",
            count: data?.redeemedCouponsCount
        },
        {
            heading: "Website Redirects - Protouch",
            count: data?.websiteRedirects
        }
    ];

    return (
        <div className="game-analytics-container">
            <Row className="analytics-header-row">
                <Col span={12}>
                    <p className="game-analytics-heading">Tic Tac Toe - Protouch</p>
                </Col>
            </Row>
            <Row className="game-analytics-table">
                <Table
                    columns={columns}
                    dataSource={analyticsData}
                    pagination={false}
                    loading={loading}
                    hoverable
                    // key={data?._id}
                />
            </Row>
        </div>
    );
};

export default GameAnalytics;
