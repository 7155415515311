import React, { useState, useEffect, useRef } from "react";
import styles from "./index.module.css";
import { useParams, useNavigate } from "react-router-dom";
import sound from "../../../../assets/wall-icons/sound_icon.png";
import soundOff from "../../../../assets/wall-icons/soundOff_icon.png";
import share from "../../../../assets/wall-icons/post_share_icon.png";
import ShareModal from "../ShareBottomSheet/index.jsx";
import { useDispatch, useSelector } from "react-redux";
import {
    updateShareModalDescripition,
    updateShareModalImage,
    updateShareModalOpen,
    updateShareShortLink
} from "../../../../redux/slice/wallSlice.js";
import { getBrandName } from "../../../Builder/RightContainer/Preview/utils";
import { getSharedPost, getuserData } from "../../../../Services/apiServices/wallService.js";
import { addEvent } from "../../../../Services/mixpanel.js";
import { MixpanelEvents } from "../../../../constants/mixpanelEvents.js";
import NotFoundScreen from "../../NotFoundScreen/index.jsx";

function Index() {
    const { id } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [userData, setUserData] = useState();
    const [post, setPost] = useState([]);
    const [isSoundOn, setIsSoundOn] = useState(false);
    const [loading, setLoading] = useState(true);
    const videoRefs = useRef([]);
    const [playPost, setPlayPost] = useState(null);
    const reelRefs = useRef([]);
    const [notFound, setNotFound] = useState(false);
    const { user, postData, pinnedPostData } = useSelector((state) => state.root.wall);

    const fetchData = async () => {
        try {
            setLoading(true);
            if (user) {
                setUserData(user);
            }
            if (postData?.length || pinnedPostData?.length) {
                let data = [];
                let allPosts = [];
                postData?.forEach((ele) => {
                    allPosts.push(ele);
                });
                pinnedPostData?.forEach((ele) => {
                    allPosts.push(ele);
                });
                let selectedPost = allPosts.filter((ele) => ele.id === id);
                data.push(selectedPost[0]);
                let filterData = allPosts.filter((ele) => ele.id !== id);
                filterData.forEach((ele) => data.push(ele));
                setPost(data);
            } else {
                const response = await getSharedPost(id);
                if (response?.status === 200) {
                    setPost(response?.data?.posts);
                    setUserData(response?.data?.user);
                } else {
                    userData?.userName ? navigate(`/i/${userData?.userName}`) : setNotFound(true);
                }
            }
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        const options = {
            root: null,
            rootMargin: "0px",
            threshold: 0.5
        };

        const callback = (entries) => {
            entries?.forEach((entry) => {
                if (entry.isIntersecting) {
                    const reelId = entry.target.id.replace("reel-", "");
                    navigate(`/post/${reelId}`, { replace: true });
                    setPlayPost(reelId);
                }
            });
        };

        const observer = new IntersectionObserver(callback, options);
        reelRefs.current.forEach((reel) => observer.observe(reel));

        return () => {
            observer.disconnect();
        };
    }, [navigate, post]);

    useEffect(() => {
        videoRefs.current.forEach((video) => {
            if (video) {
                if (video.id === `reel-video-${playPost}`) {
                    video.play();
                } else {
                    video.pause();
                }
            }
        });
    }, [playPost]);

    const handleSoundToggle = () => {
        setIsSoundOn(!isSoundOn);
        if (videoRefs.current) {
            videoRefs.current.forEach((video) => {
                if (video) video.muted = isSoundOn;
            });
        }
        addEvent({}, MixpanelEvents.VIDEO_SOUND);
    };

    return (
        <>
            {notFound ? (
                <NotFoundScreen />
            ) : (
                <div className={styles.fullScreenContainer}>
                    {loading && (
                        <div className={styles.loaderContainer}>
                            <div className={styles.loader}></div>
                        </div>
                    )}
                    <div className={styles.postContainer}>
                        {post?.map((e, i) => (
                            <div key={i} id={`reel-${e?.id}`} ref={(el) => (reelRefs.current[i] = el)} className={styles.wrapper}>
                                {e?.content?.media_type === "VIDEO" ? (
                                    <video
                                        id={`reel-video-${e.id}`}
                                        src={e?.content?.media_url}
                                        loop
                                        muted={!isSoundOn} // Set muted based on sound state
                                        autoPlay
                                        playsInline
                                        autoplay="true"
                                        controls="false"
                                        ref={(el) => (videoRefs.current[i] = el)}
                                        className={styles.postMedia}
                                    />
                                ) : (
                                    <img src={e?.content?.media_url} alt="post" className={styles.postMedia} />
                                )}

                                <div className={styles.overlay}></div>
                                <div className={styles.footer}>
                                    <div style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
                                        <div style={{ display: "flex", flexDirection: "row", gap: "5px", alignItems: "center" }}>
                                            <img src={userData?.profileImg} alt="user profile pic" className={styles.profilePic} />
                                            <p
                                                style={{ cursor: "pointer" }}
                                                onClick={() => {
                                                    navigate(`/i/${userData?.userName}`);
                                                }}
                                            >
                                                {userData?.userName}
                                            </p>
                                        </div>
                                        <div className={styles.caption}>{e?.caption}</div>
                                    </div>
                                    <div style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
                                        {e?.content?.media_type === "VIDEO" && (
                                            <div onClick={handleSoundToggle} className={styles.toggleIcon}>
                                                <img src={isSoundOn ? sound : soundOff} alt="sound" className={styles.iconImg} />
                                            </div>
                                        )}
                                        <div className={styles.toggleIcon} style={{ marginTop: "auto" }}>
                                            <img
                                                src={share}
                                                alt="share"
                                                className={styles.iconImg}
                                                onClick={(event) => {
                                                    event.stopPropagation();
                                                    dispatch(updateShareModalOpen());
                                                    dispatch(updateShareModalDescripition(e?.caption));
                                                    dispatch(
                                                        updateShareModalImage(
                                                            e?.content.media_type === "VIDEO"
                                                                ? e?.content.thumbnail_url
                                                                : e?.content.media_url
                                                        )
                                                    );
                                                    dispatch(updateShareShortLink(`${process.env.REACT_APP_URL}/post/${e?.id}`));
                                                    addEvent(
                                                        { url: `${process.env.REACT_APP_URL}/post/${e.id}` },
                                                        MixpanelEvents.SHARING_REEL
                                                    );
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.scroller}>
                                    {e?.products?.map((product, i) => (
                                        <div
                                            className={styles.productContainer}
                                            key={i}
                                            onClick={() => {
                                                try {
                                                    window.open(product?.metaData?.response?.short_link, "_self");
                                                    addEvent({ url: product.url }, MixpanelEvents.PRODUCT_LINK_CLICKED);
                                                } catch (error) {
                                                    console.error("Error handling wrapper click:", error);
                                                }
                                            }}
                                        >
                                            <img src={product?.metaData.response.image} alt="product image" className={styles.productImg} />
                                            <div className={styles.descriptionContainer}>
                                                <p className={styles.brandName}>{getBrandName(product.url)}</p>
                                                <div className={styles.description}>{product.metaData.response.web_title}</div>{" "}
                                                <div style={{ display: "flex", gap: "8px" }}>
                                                    <span>
                                                        {product.metaData?.response?.price.symbol}
                                                        {product.metaData?.response?.price.discounted_price}
                                                    </span>
                                                    <span className={styles.originalPrice}>
                                                        <del>
                                                            {product.metaData?.response?.price.symbol}
                                                            {product.metaData?.response?.price.actual_price}
                                                        </del>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ))}
                    </div>
                    <ShareModal />
                </div>
            )}
        </>
    );
}

export default Index;
