import React, { useEffect, useState } from "react";
import "./index.css";
import Summary from "./Summary";
import TopLinks from "./TopLinks";
import TopLocations from "./TopLocations";
import DeviceAnalytics from "./DeviceAnalytics";
import Navbar from "../Builder/Navbar/Navbar";
import { getAPIData } from "../../Services/utils";
import GameAnalytics from "./GameAnalytics";
import { addEvent } from "../../Services/mixpanel";
import { MixpanelEvents } from "../../constants/mixpanelEvents";
import { useSelector } from "react-redux";
const Analytics = () => {
    const { user } = useSelector((state) => state.root.auth);
    const [userData, setUserData] = useState({ profile: {}, data: [] });
    const [loading, setLoading] = useState(false);
    const updateUserData = (data) => {
        setUserData(data);
    };
    const getUserId = async () => {
        try {
            setLoading(true);
            const wallResponse = await getAPIData({
                url: "api/dashboard/links",
                data: { userId: user._id }
            });
            updateUserData(wallResponse.data);
        } catch (err) {
            console.error(err);
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        getUserId();
        addEvent({}, MixpanelEvents.LOADED_ANALYTICS_PAGE);
    }, []);
    return (
        <>
            {" "}
            <div className="dashboard-container-analytics">
                <div className="analytics-navbar-container">
                    <Navbar analytics={true} currPage={"analytics"} />
                </div>
                <Summary userId={user._id} />
                {userData.data?.map((e) => e?.templateType).includes("games") ? <GameAnalytics userId={user._id} /> : <></>}
                <TopLinks userId={user._id} />
                <TopLocations userId={user._id} />
                <DeviceAnalytics userId={user._id} />
            </div>
            <div className="footer-container"></div>
        </>
    );
};

export default Analytics;
