import React from "react";
import styles from "./index.module.css";
import bg from "../../../../../../assets/collection_bg.png";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setSelectedCollection } from "../../../../../../redux/slice/dashboardSlice";
import ImageRenderer from "./../ImageRenderer";

const index = () => {
    const dispatch = useDispatch();
    const { storeSectionsChanged } = useSelector((state) => state.root.dashboard);

    const extractImages = (section) => {
        if (!section || !Array.isArray(section.links)) {
            return [];
        }
        return section.links
            .map((link) => {
                if (link.metaData && link.metaData.response && link.metaData.response.image) {
                    return link.metaData.response.image;
                }
                return null;
            })
            .filter((image) => image !== null);
    };

    return (
        <div className={`${styles.container}`}>
            <img src={bg} alt="bg img" className={styles.background} />
            <h1 className={styles.previewContainerHeading}>My Curated Collections</h1>
            <div className={styles.previewScrollContainer}>
                {storeSectionsChanged?.map((e, i) => {
                    return (
                        e?.links?.length >= 1 && (
                            <div
                                className={styles.collectionPreviewContainer}
                                key={i}
                                style={{
                                    marginLeft: i === 0 ? "0.75em" : undefined,
                                    marginRight: i === storeSectionsChanged.length - 1 ? "0.75em" : undefined
                                }}
                            >
                                {ImageRenderer(extractImages(e))}
                                {e.links.length > 0 && (
                                    <div className={styles.moreItems}>
                                        <span>{`${e.links.length} item(s)`}</span>
                                    </div>
                                )}
                                <div className={styles.title}>{e.title}</div>
                                <div
                                    className={styles.viewButton}
                                    onClick={() => {
                                        dispatch(setSelectedCollection(e));
                                    }}
                                >
                                    View Collection
                                </div>
                            </div>
                        )
                    );
                })}
            </div>
        </div>
    );
};

export default index;
