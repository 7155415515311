import React from "react";
import styles from "./index.module.css";
import share from "../../../../assets/icons/shareIcon.png";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
    updateShareModalDescripition,
    updateShareModalImage,
    updateShareModalOpen,
    updateShareShortLink
} from "../../../../redux/slice/wallSlice";
import postIcon from "../../../../assets/wall-icons/post_icon.png";
import reelIcon from "../../../../assets/wall-icons/reel_icon.png";
import playButton from "../../../../assets/wall-icons/play_button.png";
import { addEvent } from "../../../../Services/mixpanel";
import { MixpanelEvents } from "../../../../constants/mixpanelEvents";

function index({ post, userName }) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const url = post?.content.media_type === "IMAGE" ? post.content.media_url : post.content.thumbnail_url;

    const randomCaptions = ["Shop my faves!", "Tap to discover my favorite finds", "Get the look with my top picks"];
    const getRandomCaption = () => randomCaptions[Math.floor(Math.random() * randomCaptions.length + 1)];
    const caption = post?.caption || getRandomCaption();

    return (
        <div
            className={styles.wrapper}
            onClick={() => {
                navigate(`/post/${post.id}`, { state: [post] });
                addEvent({ url: `${process.env.REACT_APP_URL}/post/${post.id}` }, MixpanelEvents.PLAYED_REEL);
            }}
        >
            <img
                src={share}
                alt="share icon"
                className={styles.share}
                onClick={(event) => {
                    event.stopPropagation();
                    dispatch(updateShareModalOpen());
                    dispatch(updateShareModalDescripition(caption));
                    dispatch(updateShareModalImage(url));
                    dispatch(updateShareShortLink(`${process.env.REACT_APP_URL}/post/${post.id}`));
                    addEvent({ url: `${process.env.REACT_APP_URL}/post/${post.id}` }, MixpanelEvents.SHARING_REEL);
                }}
            />
            <img src={post?.content.media_type === "IMAGE" ? postIcon : reelIcon} alt="post icon" className={styles.postIcon} />

            {post?.content.media_type !== "IMAGE" && <img src={playButton} alt="post icon" className={styles.playIcon} />}

            <img src={url} alt="post image" className={styles.postImg} />
            <div className={styles.overlay}></div>

            <div className={styles.scroller}>
                {post.products.map((e, i) => {
                    return <img key={i} src={e?.metaData.response.image} alt="product image" className={styles.productImg} />;
                })}
            </div>
            {post.products.length - 2 > 0 && (
                <div className={styles.moreItems}>
                    <span>{`+${post.products.length - 2}`}</span>
                </div>
            )}

            <div className={styles.descripition}>{caption}</div>
        </div>
    );
}

export default index;
