// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ProductsGrid_productsGrid__UVmyn {\n    width: 95%;\n    display: grid;\n    grid-template-columns: repeat(2, 1fr);\n    gap: 0.363em;\n    row-gap: 1.875em;\n    justify-content: center;\n    align-items: center;\n    place-items: center;\n    margin: 30px auto 0px auto;\n}\n\n.ProductsGrid_productCell__SxApp {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n\n@media (max-width: 360px) {\n    .ProductsGrid_productsGrid__UVmyn {\n        width: 100%;\n        display: grid;\n        grid-template-columns: repeat(2, 1fr);\n        gap: 0.163em;\n        row-gap: 1.275em;\n        justify-content: center;\n        align-items: center;\n        place-items: center;\n        margin: 0 auto;\n    }\n}\n", "",{"version":3,"sources":["webpack://./src/components/Wall3/Store/ProductsGrid/index.module.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,aAAa;IACb,qCAAqC;IACrC,YAAY;IACZ,gBAAgB;IAChB,uBAAuB;IACvB,mBAAmB;IACnB,mBAAmB;IACnB,0BAA0B;AAC9B;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI;QACI,WAAW;QACX,aAAa;QACb,qCAAqC;QACrC,YAAY;QACZ,gBAAgB;QAChB,uBAAuB;QACvB,mBAAmB;QACnB,mBAAmB;QACnB,cAAc;IAClB;AACJ","sourcesContent":[".productsGrid {\n    width: 95%;\n    display: grid;\n    grid-template-columns: repeat(2, 1fr);\n    gap: 0.363em;\n    row-gap: 1.875em;\n    justify-content: center;\n    align-items: center;\n    place-items: center;\n    margin: 30px auto 0px auto;\n}\n\n.productCell {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n\n@media (max-width: 360px) {\n    .productsGrid {\n        width: 100%;\n        display: grid;\n        grid-template-columns: repeat(2, 1fr);\n        gap: 0.163em;\n        row-gap: 1.275em;\n        justify-content: center;\n        align-items: center;\n        place-items: center;\n        margin: 0 auto;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"productsGrid": "ProductsGrid_productsGrid__UVmyn",
	"productCell": "ProductsGrid_productCell__SxApp"
};
export default ___CSS_LOADER_EXPORT___;
