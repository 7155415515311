import React, { useEffect } from "react";
import axios from "axios";
import styles from "./AppRedirect.module.css";
import { baseUrl } from "../../Services/utils";

function AppRedirect() {
    const getAPIData = async () => {
        const response = await axios.get(baseUrl + "/app/download");
        const redirect = response.data.link;
        window.open(redirect, "_self");
        return;
    };
    useEffect(() => {
        getAPIData();
    }, []);
    return (
        <div className={styles.container}>
            <div className={styles.loader}></div>
            <p>You are being Redirected ...</p>
        </div>
    );
}

export default AppRedirect;
