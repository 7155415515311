import React, { useEffect, useState } from "react";
import Navbar from "./Navbar/Navbar";
import styles from "./index.module.css";
import LeftContainer from "./LeftContainer";
import RightContainer from "./RightContainer";
import { useDispatch, useSelector } from "react-redux";
import { getUserData } from "../../Services/apiServices/userService";
import { getCollectionsData, getLinkInBioData, getPostsData } from "../../Services/apiServices/dashboardService";
import { getThemesData } from "../../Services/apiServices/themesService";
import Modal from "./../CommonV2/Modal";
import Loader from "./../CommonV2/Loader";
import closeIcon from "../../assets/dashboard/popup-close.png";
import {
    setDescriptionEditing,
    setLinksDiff,
    setMagicButtonState,
    setMagicLinkInput,
    setPublishButtonEnabled,
    setSectionsDiff,
    setSelectedHeader,
    setSelectedSection,
    updateProfileData,
    closePublishSuccessModal,
    setPluginSelected,
    setInstaAuthModalClose,
    setSelectedCollection
} from "../../redux/slice/dashboardSlice";
import { message } from "antd";
import { getPlugins } from "../../Services/apiServices/pluginsService";

const index = () => {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(true);
    const { isPublishedSuccessModalOpen, publishLoading, isSectionDeleting } = useSelector((state) => state.root.dashboard);
    const { user } = useSelector((state) => state.root.auth);

    function fallbackCopyTextToClipboard(text) {
        let textArea = document.createElement("textarea");
        textArea.value = text;
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        try {
            document.execCommand("copy");
            message.success("Faym Wall Link Copied");
        } catch (err) {
            message.error("Failed to copy the link");
        }
        document.body.removeChild(textArea);
    }

    const getFaymData = async () => {
        setIsLoading(true);
        await getUserData();
        await getLinkInBioData();
        await getCollectionsData();
        await getPostsData(true);
        await getPostsData(false);
        setIsLoading(false);
    };
    useEffect(() => {
        getFaymData();
        getThemesData();
        getPlugins();
        dispatch(setSelectedHeader("Collections"));
        dispatch(setMagicButtonState("INITIAL"));
        dispatch(setMagicLinkInput(""));
        dispatch(setPublishButtonEnabled(null));
        dispatch(setLinksDiff(null));
        dispatch(setSectionsDiff(null));
        dispatch(updateProfileData(null));
        dispatch(setInstaAuthModalClose());
        dispatch(closePublishSuccessModal());
        dispatch(setSelectedCollection(null));
    }, []);
    return isLoading ? (
        <div>
            <Loader />
        </div>
    ) : (
        <div
            onClick={() => {
                dispatch(setSelectedSection(null));
                dispatch(setPluginSelected(null));
                dispatch(setDescriptionEditing(null));
            }}
        >
            <div className={styles.navbarContainer}>
                <Navbar currPage="dashboard" />
            </div>
            <div className={styles.dashboardContainer}>
                <div className={styles.leftContainer}>
                    <div className={styles.hidingContainer}></div>
                    <LeftContainer />
                </div>
                <div className={styles.rightContainer}>
                    <RightContainer />
                </div>
            </div>
            {(publishLoading || isSectionDeleting) && (
                <div className={styles.loadingOverlay}>
                    <div className={styles.loader}></div>
                </div>
            )}
            <Modal
                showModal={isPublishedSuccessModalOpen}
                alignItems={"center"}
                onClose={() => {
                    dispatch(closePublishSuccessModal());
                }}
            >
                <div className={styles.popup} onClick={(e) => e.stopPropagation()}>
                    <img
                        src={closeIcon}
                        className={styles.closeButton}
                        onClick={() => {
                            dispatch(closePublishSuccessModal());
                        }}
                        alt="Close"
                    />
                    <div className={styles.congratulations}>Congratulations!</div>
                    <div className={styles.popupInnerText}>Your Faym Wall is Now Live!.</div>
                    <div className={styles.buttonBox}>
                        <div
                            onClick={() => {
                                let wallUrl = `${process.env.REACT_APP_URL}/i/${user.userName}`;
                                window.open(wallUrl, "_blank");
                            }}
                            className={styles.viewWall}
                        >
                            <p className={styles.viewWallText}>View Faym Wall</p>
                        </div>
                        <div
                            onClick={() => {
                                let wallUrl = `${process.env.REACT_APP_URL}/i/${user.userName}`;
                                if (navigator.clipboard && navigator.clipboard.writeText) {
                                    navigator.clipboard
                                        .writeText(wallUrl)
                                        .then(() => {
                                            message.success("Faym Wall Link Copied");
                                        })
                                        .catch(() => {
                                            fallbackCopyTextToClipboard(wallUrl);
                                        });
                                } else {
                                    fallbackCopyTextToClipboard(wallUrl);
                                }
                            }}
                            className={styles.copyWall}
                        >
                            <p className={styles.copyWallText}>Copy Link</p>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default index;
