import React from "react";
import styles from "./index.module.css";
import arrow from "../../../../../../assets/wall-icons/back_arrow.png";
import Product from "../Product/index";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedCollection } from "../../../../../../redux/slice/dashboardSlice";
import FaymBranding from "../../../FaymBranding";

const index = () => {
    const { selectedCollection } = useSelector((state) => state.root.dashboard);
    const { user } = useSelector((state) => state.root.auth);
    const dispatch = useDispatch();
    const handleBack = () => {
        dispatch(setSelectedCollection(null));
    };

    return (
        <div className={styles.collectionContainer}>
            <div className={styles.userWrapper}>
                <img src={arrow} alt="back icon" className={styles.backIcon} onClick={() => handleBack()} />
                <div className={styles.userName}>{user?.firstName}</div>
                <img src={user?.profileImg} alt="user profile pic" className={styles.profileImg} />
            </div>
            <div className={styles.collectionDescripitionContainer}>
                <div className={styles.collectionHeading}>{selectedCollection?.title}</div>
            </div>
            <div className={styles.productsGrid}>
                {selectedCollection &&
                    selectedCollection.links.map((e, i) => {
                        return <Product key={i} productInfo={e} />;
                    })}
            </div>
            <div style={{ flex: 1 }}></div>
            <FaymBranding />
        </div>
    );
};

export default index;
