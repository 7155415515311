import React, { useState } from "react";
import styles from "./index.module.css";
import linkDelteIcon from "../../../assets/linkDeleteIcon.png";
import linkEdit from "../../../assets/linkEdit.png";
import { useDispatch, useSelector } from "react-redux";
import {
    setDescriptionEditing,
    setStoreSectionsChanged,
    setNonStoreSectionsChanged,
    setLinksChanged
} from "../../../redux/slice/dashboardSlice";
import { findDiff } from "../../Builder/builderServices/utils";

const index = ({ link, sectionTemplate }) => {
    const dispatch = useDispatch();
    const { descriptionEditing, storeSectionsChanged, nonStoreSectionsChanged, selectedHeader } = useSelector(
        (state) => state.root.dashboard
    );
    const [description, setDescrition] = useState(link.description || link.metaData.response.product_title);

    const onDescriptionChange = (e) => {
        setDescrition(e.target.value);
        let section;
        let linkCopy;
        let updatedSections;
        if (selectedHeader === "Collections") {
            section = { ...storeSectionsChanged.filter((e) => e.id === link.parentId)[0] };
            linkCopy = { ...link, description: e.target.value };
            section.links = section.links.map((e) => (e.id === linkCopy.id ? linkCopy : e));
            updatedSections = storeSectionsChanged.map((e) => (e.id === section.id ? section : e));
            dispatch(setStoreSectionsChanged(updatedSections));
        } else if (selectedHeader === "LinkInBio") {
            section = { ...nonStoreSectionsChanged.filter((e) => e.id === link.parentId)[0] };
            linkCopy = { ...link, description: e.target.value };
            section.links = section.links.map((e) => (e.id === link.id ? link : e));
            updatedSections = nonStoreSectionsChanged.map((e) => (e.id === section.id ? section : e));
            dispatch(setNonStoreSectionsChanged(updatedSections));
        }
        dispatch(setLinksChanged());
        findDiff();
    };

    const onLinkDelete = () => {
        let linkCopy;
        let section;
        let updatedSections;
        linkCopy = { ...link, isDeleted: true, sortId: -1 };
        if (selectedHeader === "Collections") {
            section = { ...storeSectionsChanged.filter((e) => e.id === link.parentId)[0] };
            section.links = section.links.map((e) => (e.id === linkCopy.id ? linkCopy : e));
            updatedSections = storeSectionsChanged.map((e) => (e.id === section.id ? section : e));
            dispatch(setStoreSectionsChanged(updatedSections));
        } else if (selectedHeader === "LinkInBio") {
            section = { ...nonStoreSectionsChanged.filter((e) => e.id === link.parentId)[0] };
            section.links = section.links.map((e) => (e.id === link.id ? linkCopy : e));
            updatedSections = nonStoreSectionsChanged.map((e) => (e.id === section.id ? section : e));
            dispatch(setNonStoreSectionsChanged(updatedSections));
        }
        dispatch(setLinksChanged());
        findDiff();
    };

    return (
        <div className={styles.linkItem}>
            <div className={styles.linkContents}>
                <p className={styles.linkUrl}>{link.url}</p>
                {sectionTemplate === "affiliate_link" && (
                    <div className={styles.descriptionContainer}>
                        {descriptionEditing === link.id ? (
                            <input
                                className={styles.descriptionInput}
                                value={description}
                                onClick={(e) => e.stopPropagation()}
                                onChange={onDescriptionChange}
                            />
                        ) : (
                            <p className={styles.linkDescription}>{description}</p>
                        )}
                        {descriptionEditing != link.id && (
                            <img
                                className={styles.editIcon}
                                src={linkEdit}
                                onClick={(event) => {
                                    event.stopPropagation();
                                    dispatch(setDescriptionEditing(link.id));
                                }}
                            />
                        )}
                    </div>
                )}
            </div>
            <img
                className={styles.linkDelete}
                src={linkDelteIcon}
                onClick={() => {
                    onLinkDelete();
                }}
            />
        </div>
    );
};

export default index;
