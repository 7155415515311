import React from "react";
import styles from "./index.module.css";
import fire from "../../../../assets/icons/fire.gif";
import share from "../../../../assets/icons/shareIcon.png";
import star from "../../../../assets/icons/star.png";
import { useDispatch, useSelector } from "react-redux";
import {
    updateShareModalDescripition,
    updateShareModalImage,
    updateShareModalOpen,
    updateShareShortLink
} from "../../../../redux/slice/wallSlice.js";
import { getBrandName } from "../../../Builder/RightContainer/Preview/utils";
import { addEvent } from "../../../../Services/mixpanel.js";
import { MixpanelEvents } from "../../../../constants/mixpanelEvents.js";
import { addAnalytics } from "../../../../Services/apiServices/dashboardService.js";

function index({ productInfo }) {
    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.root.wall);
    const styleCoupon = {
        fontFamily: "Gilroy-Medium !important",
        fontSize: "10px",
        fontWeight: 400,
        lineHeight: "16px"
    };

    const calculateDiscountPercentage = (actualPrice, discountedPrice) => {
        if (actualPrice === 0 || discountedPrice == 0) return 0;
        const discount = actualPrice - discountedPrice;
        return Math.floor((discount / actualPrice) * 100);
    };

    const handleShareClick = (event) => {
        try {
            event.stopPropagation();
            dispatch(updateShareModalOpen());
            dispatch(updateShareModalDescripition(productInfo?.metaData?.response?.web_title));
            dispatch(updateShareModalImage(productInfo?.metaData?.response?.image));
            dispatch(updateShareShortLink(productInfo?.metaData?.response?.short_link));
            addEvent({ url: productInfo?.metaData?.response?.short_link }, MixpanelEvents.PRODUCT_SHARE_CLICKED);
        } catch (error) {
            console.error("Error handling share click:", error);
        }
    };

    const handleWrapperClick = async () => {
        try {
            addEvent({ url: productInfo?.metaData?.response?.affiliate_link }, MixpanelEvents.PRODUCT_LINK_CLICKED);
            await addAnalytics([
                {
                    eventData: { link: productInfo?.metaData?.response?.affiliate_link, userId: user._id.toString() },
                    eventType: "LINK_CLICK"
                }
            ]);
            window.open(productInfo?.metaData?.response?.short_link, "_self");
        } catch (error) {
            console.error("Error handling wrapper click:", error);
        }
    };

    const { actual_price, discounted_price, symbol } = productInfo?.metaData?.response?.price || {};

    return (
        <div className={styles.wrapper} onClick={() => handleWrapperClick()}>
            {productInfo?.metaData.response?.state !== null && productInfo.stats?.sales > 0 && productInfo.stats?.clicks > 0 && (
                <div className={styles.tag}>
                    <img src={fire} alt="fire gif" className={styles.fire} />
                    <p>BEST SELLER</p>
                </div>
            )}

            <img src={share} alt="share icon" className={styles.share} onClick={handleShareClick} />
            <div className={styles.productDetailsContainer}>
                <img src={productInfo?.metaData?.response?.image} alt="product image" className={styles.productImg} />
                {productInfo?.metaData?.response?.offerMessage && (
                    <div className={styles.ribbon}>
                        <span style={styleCoupon}>Coupon: </span>
                        {productInfo.metaData.response.offerMessage}
                    </div>
                )}
            </div>

            {parseFloat(productInfo?.metaData?.response?.rating) > 1 && (
                <div className={styles.ratingContainer}>
                    <p>{productInfo.metaData.response.rating}</p>
                    <img src={star} className={styles.ratingStar} alt="rating star" />
                </div>
            )}

            <div className={styles.priceDetails}>
                <p className={styles.brandName}>{getBrandName(productInfo?.metaData?.domain)}</p>
                <div className={styles.descripition}>{productInfo?.metaData?.response?.web_title}</div>
                <div className={styles.price}>
                    {discounted_price && (discounted_price < actual_price || actual_price === discounted_price) && (
                        <span>
                            {symbol || "₹"}
                            {discounted_price}
                        </span>
                    )}
                    {discounted_price && actual_price && discounted_price < actual_price && (
                        <>
                            <span className={styles.originalPrice}>
                                <del>
                                    {symbol || "₹"}
                                    {actual_price}
                                </del>
                            </span>
                            <span className={styles.discount}>
                                &#40;
                                {calculateDiscountPercentage(actual_price, discounted_price)}% Off &#41;
                            </span>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
}

export default index;
