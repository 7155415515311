// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DealsOfTheDay_dealSection__lAjXf {\n    width: 100%;\n}\n\n.DealsOfTheDay_dealTile__00CTL {\n    border-radius: 0.625rem;\n    width: 20.938em;\n    aspect-ratio: 335 / 180;\n    border: 1px solid #fff;\n    margin: 0 auto;\n}\n\n@media (max-width: 600px) {\n    .DealsOfTheDay_dealSection__lAjXf {\n        width: 100%;\n    }\n    .DealsOfTheDay_dealTile__00CTL {\n        width: 94%;\n        aspect-ratio: 1.75;\n    }\n}\n", "",{"version":3,"sources":["webpack://./src/components/Wall3/Store/DealsOfTheDay/index.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;AACf;;AAEA;IACI,uBAAuB;IACvB,eAAe;IACf,uBAAuB;IACvB,sBAAsB;IACtB,cAAc;AAClB;;AAEA;IACI;QACI,WAAW;IACf;IACA;QACI,UAAU;QACV,kBAAkB;IACtB;AACJ","sourcesContent":[".dealSection {\n    width: 100%;\n}\n\n.dealTile {\n    border-radius: 0.625rem;\n    width: 20.938em;\n    aspect-ratio: 335 / 180;\n    border: 1px solid #fff;\n    margin: 0 auto;\n}\n\n@media (max-width: 600px) {\n    .dealSection {\n        width: 100%;\n    }\n    .dealTile {\n        width: 94%;\n        aspect-ratio: 1.75;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dealSection": "DealsOfTheDay_dealSection__lAjXf",
	"dealTile": "DealsOfTheDay_dealTile__00CTL"
};
export default ___CSS_LOADER_EXPORT___;
