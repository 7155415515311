import React from "react";
import styles from "./index.module.css";
import starImage from "../../../../../../../assets/wall-icons/star.svg";

const index = ({ data, title, templateType }) => {
    const imgStyle = {
        borderRadius: "15px",
        width: templateType !== "application" ? "2.5em" : ""
    };
    const splitArrayIntoPairs = (arr, count) => {
        if (!arr || !arr.length) return [];
        return Array.from({ length: Math.ceil(arr.length / count) }, (_, index) => arr.slice(index * count, index * count + count));
    };

    const getStarImages = (rating) => {
        const parsedRating = parseFloat(rating);
        if (!isNaN(parsedRating)) {
            const stars = [];
            const fullStars = Math.min(Math.floor(parsedRating), 5); // Ensure no more than 5 full stars
            const emptyStars = 5 - fullStars; // Calculate the remaining empty stars

            for (let i = 0; i < fullStars; i++) {
                stars.push(<img key={`full-${i}`} src={starImage} alt={`star-${i}`} />);
            }
            for (let i = 0; i < emptyStars; i++) {
                stars.push(<img key={`empty-${fullStars + i}`} src={starImage} alt={`star-${fullStars + i}`} style={{ opacity: "40%" }} />);
            }
            return stars;
        }
        return null;
    };
    if (!styles) {
        return <div>Loading...</div>;
    }
    return (
        <div
            className={styles.container}
            style={
                templateType !== "application"
                    ? {
                          marginBottom: "0em"
                      }
                    : {}
            }
        >
            <h1 className={styles.heading}> {title || "Apps & Websites"}</h1>
            <div className={styles.scroller}>
                {splitArrayIntoPairs(data, 2).map((row, i) => {
                    return (
                        <div style={{ width: "100%" }} key={i}>
                            {row.map((rowItem, index) => {
                                return (
                                    <React.Fragment key={index}>
                                        <div
                                            className={`${styles.linkContentBox} ${
                                                templateType !== "application" ? styles.appLinkContent : styles.webLinkContent
                                            }`}
                                            onClick={() => {
                                                window.open(rowItem.url, "_blank");
                                            }}
                                        >
                                            <div
                                                className={styles.linkImageContainer}
                                                style={{ height: window.location.href.includes("dashboard") ? "50px" : "90px" }}
                                            >
                                                <img
                                                    className={`${templateType === "application" ? styles.linkImage : styles.linkImageWeb}`}
                                                    onError={(e) => {
                                                        e.target.src = starImage;
                                                    }}
                                                    src={rowItem?.metaData?.response?.image}
                                                    alt="err"
                                                />
                                            </div>
                                            <div className={styles.linkDetails}>
                                                <h1>{rowItem?.metaData?.response?.product_title}</h1>
                                                {templateType === "application" && (
                                                    <>
                                                        <p>{rowItem?.metaData?.response?.web_title}</p>
                                                        <div className={styles.rating}>
                                                            {getStarImages(Math.floor(Math.random() * 4) + 1)}
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                        {index % 2 == 0 && index != row.length - 1 && templateType === "application" && (
                                            <div
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "flex-end",
                                                    paddingRight: "1em"
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        height: "0.5px",
                                                        width: "67%",
                                                        backgroundColor: "black"
                                                    }}
                                                ></div>
                                            </div>
                                        )}
                                    </React.Fragment>
                                );
                            })}
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default index;
