import React from "react";
import styles from "./index.module.css";
import { useSelector } from "react-redux";

const DefaultImg = ({ width, radius, fontSize }) => {
    const { user } = useSelector((state) => state.root.auth);
    const getInitials = (fullName) => {
        const nameArray = fullName?.split(" ");
        let initials = "";
        if (nameArray?.length > 1) {
            initials += nameArray[0]?.charAt(0);
            initials += nameArray[nameArray?.length - 1]?.charAt(0);
        } else {
            initials += nameArray ? nameArray[0]?.charAt(0) : "";
        }
        return initials;
    };
    const initials = getInitials(user.firstName);
    return (
        <div
            className={styles.defaultImageContainer}
            style={{
                width: width,
                borderRadius: radius
            }}
        >
            <p
                className={styles.defaultImageContent}
                style={{
                    fontSize: fontSize
                }}
            >
                {initials}
            </p>
        </div>
    );
};

export default DefaultImg;
