import React from "react";
import styles from "./index.module.css";
const index = ({ data, title, type }) => {
    const slideCount = data?.length;
    let customTextStyle = {
        marginTop: "8px"
    };
    return (
        <div className={styles.container}>
            <h1 style={customTextStyle} className={styles.heading}>
                {title || ""}
            </h1>
            {type === "insta_reels" ? (
                <div className={styles.slider}>
                    {data?.map((e, i) => {
                        let linkId = e.url.split("/");
                        linkId = linkId?.[linkId.length - 2];
                        if (!linkId) return;
                        return (
                            <div key={i}>
                                <div className={styles.frameContainer}>
                                    {linkId && (
                                        <iframe
                                            scrolling="no"
                                            title="video-tag"
                                            src={`https://www.instagram.com/p/${linkId}/embed/`}
                                            frameBorder="0"
                                            loading="lazy"
                                        ></iframe>
                                    )}
                                </div>
                            </div>
                        );
                    })}
                </div>
            ) : (
                <div className={styles.slider}>
                    {data?.map((e, i) => {
                        const shortsLink = e?.url;
                        const shortsID = shortsLink.split("/shorts/")[1].split("?")[0];
                        return (
                            <div key={i}>
                                <div className={styles.frameContainer}>
                                    {shortsID && (
                                        <iframe
                                            scrolling="no"
                                            title="video-tag"
                                            src={`https://www.youtube.com/embed/${shortsID}`}
                                            frameBorder="0"
                                            loading="lazy"
                                        ></iframe>
                                    )}
                                </div>
                            </div>
                        );
                    })}
                </div>
            )}
        </div>
    );
};

export default index;
