import { useRef, useState } from "react";
import { message } from "antd";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { baseUrl, postAPIData } from "../Services/utils";
import { addEvent } from "../Services/mixpanel";
import { MixpanelEvents } from "../constants/mixpanelEvents";
import { updateUserProperties, updateUser } from "../redux/slice/authSlice";
import { Navigate } from "react-router-dom";
export function useImageUploader() {
    const dispatch = useDispatch();
    const { user, idToken } = useSelector((state) => state.root.auth);
    const [loading, setLoading] = useState(false);
    const [uploadRef, setUploadRef] = useState(useRef(null));
    const updateUserImage = async (profileImg) => {
        const obj = {
            profileImg: profileImg
        };
        const userData = await postAPIData(
            {
                url: "api/user/update_user",
                data: obj
            },
            true
        );
        // dispatch(updateUser(userData?.data?.user));
        if (!idToken) {
            message?.error("You are not authorized");
            return <Navigate to={"/"} />;
        }
        addEvent(userData.response, MixpanelEvents.USER_PROFILE_UPDATED);
    };
    const handleUploadChange = async (imageFile) => {
        if (Number(imageFile?.size / (1024 * 1024)) > 5) {
            message.error("Maximum image size(5MB) exceeded");
            setUploadRef({
                ...uploadRef,
                current: {
                    ...uploadRef.current,
                    value: null
                }
            });
            return;
        }
        try {
            setLoading(true);
            const form = new FormData();
            form.append("file", imageFile);
            form.append("fileName", `${user.userName}_${imageFile?.name}`);
            const resp = await axios({
                method: "post",
                url: baseUrl + "/api/dashboard/upload/profile-pic",
                data: form,
                headers: {
                    Authorization: `Bearer ${idToken}`,
                    "Content-Type": `multipart/form-data; boundary=${form._boundary}`
                }
            });
            addEvent({}, MixpanelEvents.USER_IMAGE_UPLOADED);
            const downloadURL = resp.data.fileUrl;
            const updateUserObj = { profileImg: downloadURL };
            dispatch(updateUserProperties(updateUserObj));
            updateUserImage(resp.data.fileUrl);
            setUploadRef({
                ...uploadRef,
                current: {
                    ...uploadRef.current,
                    value: null
                }
            });
            message.success("Profile uploaded successfully !!");
        } catch (err) {
            console.error(err);
        } finally {
            setLoading(false);
        }
    };
    return { handleUploadChange, loading, uploadRef };
}
