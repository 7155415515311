import React, { useState, useEffect } from "react";
import styles from "./index.module.css";
import accordionExpand from "./../../../assets/accordionExpand.png";
import accordionCollapse from "./../../../assets/accordionCollapse.png";
import { iconMap, filledIconMap, findDiff } from "../../Builder/builderServices/utils";
import {
    setDescriptionEditing,
    setLinksChanged,
    setNonStoreSectionsChanged,
    setSelectedSection,
    setStoreSectionsChanged
} from "../../../redux/slice/dashboardSlice";
import { useDispatch, useSelector } from "react-redux";
import headlineIcon from "../../../assets/icons/headlineInput.svg";
import LinkItem from "../../CommonV2/LinkItem";
import { SortableContainer, SortableElement, SortableHandle } from "react-sortable-hoc";
import { arrayMoveImmutable } from "array-move";
import linkDrag from "../../../assets/linkItemDraggable.png";

const DragHandle = SortableHandle(() => <img className={styles.linkDrag} src={linkDrag} />);

const SortableItem = SortableElement(({ value, templateType }) => (
    <div className={styles.sortableElement}>
        <DragHandle />
        {!value.isDeleted && <LinkItem link={value} sectionTemplate={templateType} />}
    </div>
));

const SortableList = SortableContainer(({ items, templateType }) => {
    return (
        <div className={styles.linksContainer}>
            {items.map(
                (value, index) =>
                    !value.isDeleted && <SortableItem key={`item-${value.id}`} index={index} value={value} templateType={templateType} />
            )}
        </div>
    );
});

const index = ({ section, isCollapsed, setIsCollapsed }) => {
    const dispatch = useDispatch();
    const { selectedSection, selectedHeader, storeSectionsChanged, nonStoreSectionsChanged } = useSelector((state) => state.root.dashboard);
    const [list, setList] = useState(section.links);
    const [sectionHeading, setSectionHeading] = useState(section.title);

    const onChangeHandler = (e) => {
        setSectionHeading(e.target.value);
        let updatedSections;
        section = { ...section, title: e.target.value };
        if (selectedHeader === "Collections") {
            updatedSections = storeSectionsChanged.map((e) => (e.id === section.id ? section : e));
            dispatch(setStoreSectionsChanged(updatedSections));
        } else if (selectedHeader === "LinkInBio") {
            updatedSections = nonStoreSectionsChanged.map((e) => (e.id === section.id ? section : e));
            dispatch(setNonStoreSectionsChanged(updatedSections));
        }
        dispatch(setLinksChanged());
        findDiff();
    };

    const onSortEnd = ({ oldIndex, newIndex }) => {
        if (newIndex === oldIndex) {
            return;
        }
        setList(arrayMoveImmutable(list, oldIndex, newIndex));
        let sectionCopy = { ...section };
        let tempSortId = section.links[newIndex].sortId;
        if (newIndex > oldIndex) {
            sectionCopy.links = sectionCopy.links.map((link, i) => {
                if (i > oldIndex && i <= newIndex) {
                    return {
                        ...link,
                        sortId: sectionCopy.links[i - 1].sortId
                    };
                }
                return link;
            });
        } else if (oldIndex > newIndex) {
            sectionCopy.links = sectionCopy.links.map((link, i) => {
                if (i >= newIndex && i < oldIndex) {
                    return {
                        ...link,
                        sortId: sectionCopy.links[i + 1].sortId
                    };
                }
                return link;
            });
        }
        sectionCopy.links[oldIndex] = {
            ...sectionCopy.links[oldIndex],
            sortId: tempSortId
        };
        const item = sectionCopy.links.splice(oldIndex, 1)[0];
        sectionCopy.links.splice(newIndex, 0, item);
        let updatedSections;
        if (selectedHeader === "Collections") {
            updatedSections = storeSectionsChanged.map((section) => (section.id === sectionCopy.id ? sectionCopy : section));
            dispatch(setStoreSectionsChanged(updatedSections));
        } else if (selectedHeader === "LinkInBio") {
            updatedSections = nonStoreSectionsChanged.map((section) => (section.id === sectionCopy.id ? sectionCopy : section));
            dispatch(setNonStoreSectionsChanged(updatedSections));
        }
        dispatch(setLinksChanged());
        findDiff();
    };
    useEffect(() => {
        setList(section.links);
    }, [section]);
    return (
        <div
            className={styles.accordion}
            onClick={(event) => {
                event.stopPropagation();
                dispatch(setSelectedSection(section.id));
                dispatch(setDescriptionEditing(null));
            }}
        >
            <div className={styles.accordionHead} onClick={() => setIsCollapsed(!isCollapsed)}>
                <div className={styles.accordionHeadingContent}>
                    <img
                        src={
                            selectedSection === section.id
                                ? filledIconMap[section.headingTitle?.toString()]
                                : iconMap[section.headingTitle?.toString()]
                        }
                        alt="icon"
                    />
                    <div>{section.headingTitle}</div>
                </div>
                <img className={styles.headerAccordionCollapse} src={isCollapsed ? accordionExpand : accordionCollapse} alt="arrow" />
            </div>
            {!isCollapsed && (
                <div className={styles.accordionContent}>
                    <div className={styles.headerInputBox}>
                        <img className={styles.headerInputImage} src={headlineIcon} alt="Headline Icon" />
                        <input
                            className={styles.headerInput}
                            value={sectionHeading}
                            onChange={(e) => {
                                onChangeHandler(e);
                            }}
                        />
                    </div>
                    <div className={styles.linksContainer}>
                        <SortableList items={list} templateType={section.templateType} onSortEnd={onSortEnd} useDragHandle />
                    </div>
                </div>
            )}
        </div>
    );
};

export default index;
