import React, { useEffect, useState } from "react";
import styles from "./index.module.css";
import { getBrandName } from "../../../Builder/RightContainer/Preview/utils";
import ProductGrid from "../ProductsGrid/index.jsx";
import { addEvent } from "../../../../Services/mixpanel.js";
import { MixpanelEvents } from "../../../../constants/mixpanelEvents.js";

function index({ platform, productList }) {
    const [selected, setSelected] = useState("");
    const [filteredProducts, setFilteredProducts] = useState([]);

    const normalizeString = (str) => str.replace(/\s+/g, "").toLowerCase();

    useEffect(() => {
        if (selected) {
            const filtered = productList.filter((product) => {
                const domain = product.metaData.domain;
                return normalizeString(getBrandName(domain)) === normalizeString(selected);
            });
            setFilteredProducts(filtered);
        } else {
            setFilteredProducts([]);
        }
    }, [selected, productList]);

    if (!platform || !productList) {
        return <div>Loading...</div>;
    }

    return (
        <>
            <div className={styles.gridContainer}>
                {platform.map((e, index) => (
                    <div
                        className={styles.imageItem}
                        key={index}
                        onClick={() => {
                            setSelected(e.platform);
                            addEvent({}, MixpanelEvents.SELECTED_PLATFORM + `${e.platform}`);
                        }}
                    >
                        <img src={selected === e.platform ? e.icon : e.iconBlack} alt={`Image ${index + 1}`} />
                    </div>
                ))}
            </div>
            {filteredProducts.length > 0 && <ProductGrid products={filteredProducts} />}
        </>
    );
}

export default index;
