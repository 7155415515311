import React, { useEffect, useState } from "react";
import MagicLinkInput from "./MagicLinkInput";
import MagicLinkButton from "./MagicLinkButton";
import { isValidLink } from "../../../../Services/utils";
import MagicGif from "../../../../assets/magicGif2.gif";
import { useDispatch, useSelector } from "react-redux";
import styles from "./index.module.css";
import { getLinkData } from "../../../../Services/apiServices/linkService";
import { setMagicButtonState, setMagicLinkInput, setMagicLinkInputCopy } from "../../../../redux/slice/dashboardSlice";
import { magicButtonStates } from "../../../../enums";

const index = (screen) => {
    const dispatch = useDispatch();
    const { magicButtonState, magicLinkResponse, magicLinkInput } = useSelector((state) => state.root.dashboard);
    const [isValidMagicLink, setIsValidMagicLink] = useState(true);
    const [magicLinkButtonClick, setMagicLinkButtonClick] = useState(false);
    if (screen === "Store" && magicLinkResponse.section !== "affiliate_link") {
        dispatch(setMagicButtonState(magicButtonStates.INVALID));
    }
    useEffect(() => {
        if (magicButtonState === magicButtonStates.INITIAL) {
            setMagicLinkInput("");
        }
    }, [magicButtonState]);

    const handleKeyDown = (e) => {
        if (e.key === "Enter") {
            setIsValidMagicLink(isValidLink(magicLinkInput, ""));
            if (isValidLink(magicLinkInput, "")) {
                getLinkData(magicLinkInput);
                setMagicLinkButtonClick(true);
            } else {
                setMagicButtonState(magicButtonStates.INVALID);
            }
        }
    };

    const handleOnChange = (e) => {
        if (e.target.value === "") {
            setIsValidMagicLink(true);
        }
        dispatch(setMagicLinkInput(e.target.value));
        dispatch(setMagicLinkInputCopy());
    };

    const handleOnClick = () => {
        setIsValidMagicLink(isValidLink(magicLinkInput, ""));
        if (isValidLink(magicLinkInput, "")) {
            getLinkData(magicLinkInput);
            setMagicLinkButtonClick(true);
        } else {
            dispatch(setMagicButtonState(magicButtonStates.INVALID));
        }
    };

    return (
        <div
            className={styles.magicLinkContainer}
            style={{
                backgroundImage: `url(${MagicGif})`
            }}
        >
            <MagicLinkInput
                handleKeyDown={handleKeyDown}
                handleOnChange={handleOnChange}
                // magicLink={magicLinkInput}
                magicLinkButtonClick={magicLinkButtonClick}
                setMagicButtonClick={setMagicLinkButtonClick}
            />
            <MagicLinkButton handleOnClick={handleOnClick} />
        </div>
    );
};

export default index;
