import React, { useEffect, useState } from "react";
import "./index.css";
import { Col, Row, Select, Table } from "antd";
import { getAPIData } from "../../../Services/utils";

const TopLinks = ({ userId }) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 5
        }
    });
    const columns = [
        {
            width: "80%",
            dataIndex: "linkName",
            render: (text) => (
                <a
                    href={text}
                    style={{
                        color: "black",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap"
                    }}
                    target="_blank"
                    rel="noreferrer"
                >
                    {text.length > 108 ? text.substr(0, 108) + "..." : text}
                </a>
            ),
            sort: true
        },
        {
            title: "Clicks",
            dataIndex: "clickCounts"
        }
    ];
    const sortLinksAscending = () => {
        const sortedLinks = [...data].sort((a, b) => a?.clickCounts - b?.clickCounts);
        setData(sortedLinks);
    };

    const sortLinksDescending = () => {
        const sortedLinks = [...data].sort((a, b) => b?.clickCounts - a?.clickCounts);
        setData(sortedLinks);
    };
    const handleChange = (value) => {
        if (value === "ascending") {
            sortLinksAscending();
        } else if (value === "descending") {
            sortLinksDescending();
        }
    };
    const getData = async () => {
        try {
            setLoading(true);
            const resp = await getAPIData({
                url: `api/analytics/urlData?id=${userId}`
            });
            const sortedData = [...resp.data].sort((a, b) => b?.clickCounts - a?.clickCounts);
            setData(sortedData);
        } catch (err) {
            console.error(err);
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        if (userId) getData();
    }, [userId]);
    const handleTableChange = (pagination, filters, sorter) => {
        setTableParams({
            pagination,
            filters,
            ...sorter
        });

        if (pagination?.pageSize !== tableParams?.pagination?.pageSize) {
            setData([]);
        }
    };
    return (
        <div className="top-links-container">
            <Row className="top-links-header-row">
                <Col span={12}>
                    <p className="top-links-heading">Top Performing Links</p>
                </Col>
                <Col span={12} className="top-links-filter">
                    <Select
                        defaultValue="Best Performing"
                        style={{ width: 150 }}
                        onChange={handleChange}
                        options={[
                            {
                                value: "descending",
                                label: "Best Performing"
                            },
                            {
                                value: "ascending",
                                label: "Least Performing"
                            }
                        ]}
                    />
                </Col>
            </Row>
            <Row className="top-links-table">
                <Table
                    columns={columns}
                    dataSource={data}
                    pagination={tableParams?.pagination}
                    loading={loading}
                    hoverable
                    onChange={handleTableChange}
                    key={data?._id}
                />
            </Row>
        </div>
    );
};

export default TopLinks;
