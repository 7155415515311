// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FaymBranding_faym__EmBUy {\n    width: 4.575em;\n    margin-top: 2em;\n    margin-bottom: 3em;\n}\n", "",{"version":3,"sources":["webpack://./src/components/Builder/RightContainer/FaymBranding/index.module.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,eAAe;IACf,kBAAkB;AACtB","sourcesContent":[".faym {\n    width: 4.575em;\n    margin-top: 2em;\n    margin-bottom: 3em;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"faym": "FaymBranding_faym__EmBUy"
};
export default ___CSS_LOADER_EXPORT___;
