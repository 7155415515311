import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getAPIData, postAPIData, getInitials } from "../../../../../Services/utils";
import Modal from "../../../../CommonV2/Modal";
import Loader from "../../../../CommonV2/Loader";
import insta_icon from "../../../../../assets/instagramIcon.png";
import sync from "../../../../../assets/ratingStar.png";
import "./index.css";

function Inbox() {
    const { user } = useSelector((state) => state.root.auth);
    const { instaAuthModalOpen, instaAuth } = useSelector((state) => state.root.dashboard);
    const [instaData, setInstaData] = useState();
    const [showInstaModal, setShowInstaModal] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    const [selectedSwitch, setSelectedSwitch] = useState(0);
    const [inboxResponse, setInboxResponse] = useState([]);
    const [isInboxLoading, setIsInboxLoading] = useState(false);
    const [selectedChat, setSelectedChat] = useState(-1);
    const [chatResponse, setChatResponse] = useState([]);
    const [isChatLoading, setIsChatLoading] = useState(false);
    const [message, setMessage] = useState("");
    const [isInstaDataLoading, setIsInstaDataLoading] = useState(true);

    useEffect(() => {
        getUserinsta();
        if (instaAuthModalOpen) {
            setShowInstaModal(true);
        }
    }, [instaAuthModalOpen]);

    const getUserinsta = async () => {
        try {
            setIsInstaDataLoading(true);
            const wallResponse = await getAPIData({
                url: "api/dashboard/links",
                data: { userId: user._id }
            });
            setInstaData(wallResponse.data.instagram);
            // console.log(wallResponse.data.instagram);
        } catch (err) {
            console.error(err);
        } finally {
            setIsInstaDataLoading(false);
        }
    };

    const getInboxData = async () => {
        try {
            setIsInboxLoading(true);
            const res = await getAPIData({
                url: "v1/meta/conversations"
            });
            if (res) {
                setIsInboxLoading(false);
                console.log(res.data);
                setInboxResponse(res.data);
            }
        } catch (error) {
            setIsInboxLoading(false);
            console.error(error);
        }
    };

    const getChatsData = async (conversationId) => {
        try {
            setIsChatLoading(true);
            const res = await getAPIData({
                url: `v1/meta/messages?conversationId=${conversationId}`
            });
            if (res.data) {
                setIsChatLoading(false);
                setChatResponse(res.data);
            }
        } catch (error) {
            setIsChatLoading(false);
            console.error(error);
        }
    };

    const handleToggleChange = () => {
        postAPIData(
            {
                url: "v1/meta/allow_auto_dm",
                data: {
                    userId: user._id,
                    allowAutoDm: !instaAuth.allowAutoDm
                }
            },
            true
        ).then(() => {
            setIsChecked(!isChecked);
        });
    };

    const sendMessage = (recipient) => {
        if (message) {
            postAPIData(
                {
                    url: "v1/meta/message",
                    data: {
                        recipient: recipient,
                        message: message
                    }
                },
                true
            )
                .then(() => {
                    setChatResponse([
                        ...chatResponse,
                        {
                            from: { username: instaData.instagramProfileInfo.username, id: instaData.instagramProfileInfo.id },
                            message: message
                        }
                    ]);
                    setMessage("");
                })
                .catch((error) => {
                    console.error(error);
                });
        }
    };

    return (
        <div>
            {instaAuth && (
                <div className="insta-waitlist-modal-integrated" onClick={(e) => e.stopPropagation()}>
                    <div className="insta-modal-heading">{instaData?.pageInfo?.name}</div>
                    <div className="switches">
                        <div
                            className={selectedSwitch == 0 ? "selected-switch" : "switch"}
                            onClick={() => {
                                setSelectedSwitch(0);
                                setInboxResponse([]);
                                setSelectedChat(-1);
                            }}
                        >
                            Overview
                        </div>
                        <div
                            className={selectedSwitch == 1 ? "selected-switch" : "switch"}
                            onClick={() => {
                                setSelectedSwitch(1);
                                getInboxData();
                            }}
                        >
                            Inbox
                        </div>
                        {selectedChat > -1 && selectedSwitch === 1 ? (
                            <>
                                <div className="chat-heading">{inboxResponse[selectedChat]?.name}</div>
                            </>
                        ) : (
                            <></>
                        )}
                    </div>
                    <div className="line"></div>
                    {isInstaDataLoading ? (
                        <Loader />
                    ) : (
                        <>
                            {selectedSwitch == 0 ? (
                                <>
                                    <div className="insta-details">
                                        <img
                                            src={instaData?.instagramProfileInfo.profile_picture_url}
                                            alt="insta profile pic"
                                            className="insta-pic"
                                        />
                                        <img
                                            src={insta_icon}
                                            style={{
                                                height: "32px",
                                                position: "relative",
                                                top: "58px",
                                                left: "15%",
                                                position: "absolute",
                                                borderRadius: "50%"
                                            }}
                                        />
                                        <div className="instagram-profile">
                                            <div className="profile-info">
                                                <h4>{instaData?.instagramProfileInfo?.name}</h4>
                                                <span>@{instaData?.instagramProfileInfo?.username}</span>
                                            </div>
                                            <div className="profile-stats">
                                                <span className="stats">{instaData?.instagramProfileInfo?.media_count}</span>
                                                <span> Posts | </span>

                                                <span className="stats">{instaData?.instagramProfileInfo?.followers_count}</span>
                                                <span> Followers | </span>

                                                <span className="stats">{instaData?.instagramProfileInfo?.follows_count}</span>
                                                <span> Following</span>
                                            </div>
                                        </div>
                                        <div
                                            className="disintegrate-button"
                                            onClick={async () => {
                                                postAPIData(
                                                    {
                                                        url: "v1/meta/disintegrate",
                                                        data: {
                                                            userId: user._id
                                                        }
                                                    },
                                                    true
                                                ).then(() => {
                                                    window.location.reload();
                                                });
                                            }}
                                        >
                                            Disintegrate Account
                                        </div>
                                    </div>

                                    <div className="insta-waitlist-modal-text">Recent Instagram Posts</div>
                                    <div className="horizontal-scroll-container">
                                        {instaData?.topMedia?.map((media, index) => {
                                            return (
                                                <img key={index} src={media.media_url} alt={`Image ${index}`} className="scroll-image" />
                                            );
                                        })}
                                    </div>
                                    <div className="line"></div>
                                    <div style={{ display: "flex", gap: "10px" }}>
                                        <div className="insta-waitlist-modal-text">Automated link sharing over DM’s</div>
                                        <label className="toggle">
                                            <input
                                                className="toggle-input"
                                                type="checkbox"
                                                checked={isChecked}
                                                onChange={handleToggleChange}
                                            />
                                            <span className="toggle-label" data-on="On" data-off="  "></span>
                                            <span className="toggle-handle"></span>
                                        </label>
                                    </div>
                                    <div
                                        className="sync-button"
                                        onClick={async () => {
                                            postAPIData(
                                                {
                                                    url: "v1/meta/sync",
                                                    data: {
                                                        userId: user._id
                                                    }
                                                },
                                                true
                                            ).then(() => {
                                                message.success("Data Synced from Meta and Instagram");
                                            });
                                        }}
                                    >
                                        <img src={sync} style={{ height: "25px" }} />
                                        Sync Data
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div className="inbox">
                                        <div className="chat-list">
                                            {inboxResponse.map((e, i) => {
                                                return (
                                                    <div key={i}>
                                                        <div
                                                            className={selectedChat === i ? "selected-chat-list-item" : "chat-list-item"}
                                                            onClick={() => {
                                                                setSelectedChat(i);
                                                                getChatsData(e.id);
                                                            }}
                                                        >
                                                            <div
                                                                className={
                                                                    selectedChat === i
                                                                        ? "selected-chat-list-item-name"
                                                                        : "chat-list-item-name"
                                                                }
                                                            >
                                                                {e.name}
                                                            </div>
                                                            <div
                                                                className={
                                                                    selectedChat === i ? "selected-chat-list-item-id" : "chat-list-item-id"
                                                                }
                                                            >
                                                                {
                                                                    e.participants.data.filter(
                                                                        (e) => e.username != instaData.instagramProfileInfo.username
                                                                    )[0].id
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className="chat-list-item-divider"></div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                        <div className="inbox-divider" />
                                        <div
                                            className="chat-box"
                                            style={
                                                selectedChat > -1
                                                    ? {}
                                                    : { borderRight: "1.5px solid #dedfe2", borderBottom: "1.5px solid #dedfe2" }
                                            }
                                        >
                                            <div style={{ overflowY: "auto" }}>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        justifyContent: "flex-end"
                                                    }}
                                                >
                                                    {isChatLoading ? (
                                                        <>
                                                            <Loader />
                                                        </>
                                                    ) : (
                                                        <>
                                                            {selectedChat > -1 ? (
                                                                <>
                                                                    {chatResponse?.map((e, i) => {
                                                                        return (
                                                                            <React.Fragment key={i}>
                                                                                {e.from.username ===
                                                                                inboxResponse[selectedChat].participants.data.filter(
                                                                                    (e) =>
                                                                                        e.username !=
                                                                                        instaData.instagramProfileInfo?.username
                                                                                )[0].username ? (
                                                                                    e?.attachments?.data && e?.attachments?.data?.length ? (
                                                                                        <div>
                                                                                            <div className="receiver">
                                                                                                {getInitials(
                                                                                                    inboxResponse[selectedChat]?.name
                                                                                                )}
                                                                                            </div>
                                                                                            <div className="received-message">
                                                                                                <img
                                                                                                    src={
                                                                                                        e?.attachments?.data[0]?.image_data
                                                                                                            ?.url
                                                                                                    }
                                                                                                    alt=""
                                                                                                    width={
                                                                                                        e?.attachments?.data[0]?.image_data
                                                                                                            ?.width
                                                                                                    }
                                                                                                    height={
                                                                                                        e?.attachments?.data[0]?.image_data
                                                                                                            ?.height
                                                                                                    }
                                                                                                />
                                                                                            </div>
                                                                                        </div>
                                                                                    ) : (
                                                                                        <div
                                                                                            style={{
                                                                                                display: "flex",
                                                                                                marginBottom: "4px"
                                                                                            }}
                                                                                        >
                                                                                            <div className="receiver">
                                                                                                {getInitials(
                                                                                                    inboxResponse[selectedChat]?.name
                                                                                                )}
                                                                                            </div>
                                                                                            <div className="received-message">
                                                                                                {e.message}
                                                                                            </div>
                                                                                        </div>
                                                                                    )
                                                                                ) : e?.attachments?.data && e?.attachments?.data?.length ? (
                                                                                    <div className="sent-message">
                                                                                        <img
                                                                                            src={e?.attachments?.data[0]?.image_data?.url}
                                                                                            alt=""
                                                                                            width={
                                                                                                e?.attachments?.data[0]?.image_data?.width
                                                                                            }
                                                                                            height={
                                                                                                e?.attachments?.data[0]?.image_data?.height
                                                                                            }
                                                                                        />
                                                                                    </div>
                                                                                ) : e.from.username ===
                                                                                  inboxResponse[selectedChat].participants.data.filter(
                                                                                      (e) =>
                                                                                          e.username ===
                                                                                          instaData.instagramProfileInfo.username
                                                                                  )[0].username ? (
                                                                                    <div className="sent-message">{e.message}</div>
                                                                                ) : (
                                                                                    <></>
                                                                                )}
                                                                            </React.Fragment>
                                                                        );
                                                                    })}
                                                                </>
                                                            ) : (
                                                                <></>
                                                            )}
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                            {selectedChat > -1 ? (
                                                <div className="send-message-box">
                                                    <div className="agent">Human Agent:</div>
                                                    <input
                                                        className="send-message-input"
                                                        placeholder="Enter Message"
                                                        onChange={(e) => setMessage(e.target.value)}
                                                        value={message}
                                                    />
                                                    <button
                                                        className="send-button"
                                                        type="submit"
                                                        onClick={() => {
                                                            sendMessage(
                                                                inboxResponse[selectedChat].participants.data.filter(
                                                                    (e) => e.username !== instaData.instagramProfileInfo.username
                                                                )[0].id
                                                            );
                                                            setMessage("");
                                                        }}
                                                    >
                                                        Send
                                                    </button>
                                                </div>
                                            ) : (
                                                <></>
                                            )}
                                        </div>
                                    </div>
                                </>
                            )}
                        </>
                    )}
                </div>
            )}
        </div>
    );
}

export default Inbox;
