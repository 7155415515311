import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    deviceData: null,
    gameData: null,
    summaryData: null,
    topLinksData: null,
    topLocationsData: null
};

const analyticsSlice = createSlice({
    name: "analytics",
    initialState,
    reducers: {
        setDeviceData: (state, action) => {
            state.deviceData = action.payload;
        },
        setGameData: (state, action) => {
            state.gameData = action.payload;
        },
        setSummaryData: (state, action) => {
            state.summaryData = action.payload;
        },
        setTopLinksData: (state, action) => {
            state.topLinksData = action.payload;
        },
        setTopLocationsData: (state, action) => {
            state.topLocationsData = action.payload;
        }
    }
});

export const { setDeviceData, setGameData, setSummaryData, setTopLinksData, setTopLocationsData } = analyticsSlice.actions;
export const selectAnalytics = (state) => state.analytics;
export default analyticsSlice.reducer;
