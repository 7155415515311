import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Accordion from "./../../../CommonV2/Accordion";
import { SortableContainer, SortableElement, SortableHandle } from "react-sortable-hoc";
import { arrayMoveImmutable } from "array-move";
import styles from "./index.module.css";
import sectionDrag from "../../../../assets/sectionDrag.svg";
import MagicLink from "../MagicLink";
import Modal from "../../../CommonV2/Modal";
import {
    setMagicLinkInput,
    closeSectionPickModal,
    openSectionDeleteModal,
    closeSectionDeleteModal,
    openSectionCreateModal,
    closeSectionCreateModal,
    setNonStoreSectionsChanged,
    setLinksChanged
} from "../../../../redux/slice/dashboardSlice";
import addSection from "./../../../../assets/addSection.png";
import deleteSectionIcon from "./../../../../assets/deleteSection.png";
import { duplicateNonStoreSection, deleteSection, findDiff } from "../../builderServices/utils";
import { addLinkToNonStoreSectionsWrapper, addNewLinkWithNewNonStoreSection } from "../../../../Services/apiServices/linkService";
import { message } from "antd";

const DragHandle = SortableHandle(() => <img className={styles.sectionDrag} src={sectionDrag} />);

const SortableItem = SortableElement(({ value, setSection }) => {
    const [isCollapsed, setIsCollapsed] = useState(false);
    const dispatch = useDispatch();
    return (
        <div className={styles.accordion}>
            <DragHandle />
            <Accordion section={value} isCollapsed={isCollapsed} setIsCollapsed={setIsCollapsed} />
            {!isCollapsed ? (
                <div className={styles.sectionActions}>
                    <img className={styles.addSection} src={addSection} onClick={() => duplicateNonStoreSection(value)} />
                    <img
                        className={styles.deleteSection}
                        src={deleteSectionIcon}
                        onClick={() => {
                            dispatch(openSectionDeleteModal());
                            setSection(value);
                        }}
                    />
                </div>
            ) : (
                <div className={styles.sectionActions}>
                    <div className={styles.addSection}></div>
                    <div className={styles.deleteSection}></div>
                </div>
            )}
        </div>
    );
});

const SortableList = SortableContainer(({ items, setSection }) => {
    return (
        <>
            <div className={styles.linksContainer}>
                {items.map((value, index) => (
                    <SortableItem key={`item-${value.id}`} index={index} value={value} setSection={setSection} />
                ))}
            </div>
        </>
    );
});

const index = () => {
    const dispatch = useDispatch();
    const {
        nonStoreSectionsChanged,
        isSectionPickModalOpen,
        magicLinkResponse,
        nonStoreAddedTemplateType,
        isSectionDeleteModalOpen,
        isSectionCreateModalOpen
    } = useSelector((state) => state.root.dashboard);
    const [list, setList] = useState(nonStoreSectionsChanged);
    const [title, setTitle] = useState("");
    const onSortEnd = ({ oldIndex, newIndex }) => {
        setList(arrayMoveImmutable(list, oldIndex, newIndex));
        let nonStoreSectionsChangedCopy = [...nonStoreSectionsChanged];
        let tempSortId = nonStoreSectionsChangedCopy[newIndex].sortId;
        if (newIndex > oldIndex) {
            nonStoreSectionsChangedCopy = nonStoreSectionsChangedCopy.map((section, i) => {
                if (i > oldIndex && i <= newIndex) {
                    return {
                        ...section,
                        sortId: nonStoreSectionsChangedCopy[i - 1].sortId
                    };
                }
                return section;
            });
        } else if (oldIndex > newIndex) {
            nonStoreSectionsChangedCopy = nonStoreSectionsChangedCopy.map((section, i) => {
                if (i >= newIndex && i < oldIndex) {
                    return {
                        ...section,
                        sortId: nonStoreSectionsChangedCopy[i + 1].sortId
                    };
                }
                return section;
            });
        }
        nonStoreSectionsChangedCopy[oldIndex] = {
            ...nonStoreSectionsChangedCopy[oldIndex],
            sortId: tempSortId
        };
        const item = nonStoreSectionsChangedCopy.splice(oldIndex, 1)[0];
        nonStoreSectionsChangedCopy.splice(newIndex, 0, item);
        dispatch(setNonStoreSectionsChanged(nonStoreSectionsChangedCopy));
        dispatch(setLinksChanged());
        findDiff();
    };
    const [section, setSection] = useState();

    useEffect(() => {
        setList(nonStoreSectionsChanged);
    }, [nonStoreSectionsChanged]);
    return (
        <>
            <MagicLink screen="LinkInBio" />
            <SortableList items={list} setSection={setSection} onSortEnd={onSortEnd} useDragHandle />
            <Modal
                showModal={isSectionPickModalOpen}
                alignItems={"center"}
                onClose={() => {
                    dispatch(closeSectionPickModal());
                    dispatch(setMagicLinkInput(""));
                }}
            >
                <div className={styles.sectionsListModal} onClick={(e) => e.stopPropagation()}>
                    <div className={styles.sectionsListHeading}>Choose a section</div>
                    <div className={styles.sectionsList}>
                        {nonStoreSectionsChanged
                            ?.filter((e) => e.templateType === nonStoreAddedTemplateType)
                            ?.map((e, i) => {
                                return (
                                    <div
                                        key={i}
                                        onClick={() => {
                                            addLinkToNonStoreSectionsWrapper(e, magicLinkResponse);
                                            dispatch(closeSectionPickModal());
                                        }}
                                    >
                                        <div className={styles.sectionsListItem} key={i}>
                                            {e?.title}
                                        </div>
                                        <div className={styles.sectionsListItemSaperator}></div>
                                    </div>
                                );
                            })}
                        <div>
                            <div
                                className={styles.sectionsListItem}
                                onClick={() => {
                                    dispatch(closeSectionPickModal());
                                    dispatch(openSectionCreateModal());
                                }}
                            >
                                + Add New Section
                            </div>
                            <div className={styles.sectionsListItemSaperator}></div>
                        </div>
                    </div>
                </div>
            </Modal>
            <Modal
                showModal={isSectionDeleteModalOpen}
                alignItems={"center"}
                onClose={() => {
                    dispatch(closeSectionDeleteModal());
                }}
            >
                <div className={styles.deleteModal} onClick={(e) => e.stopPropagation()}>
                    <div className={styles.sectionsListHeading}>Delete section</div>
                    <div className={styles.deleteModalContent}>
                        <div>Are you sure you want to delete this section?</div>
                        <div>This will be gone forever.</div>
                        <div className={styles.buttonGroup}>
                            <div className={styles.cancelButton} onClick={() => dispatch(closeSectionDeleteModal())}>
                                Cancel
                            </div>
                            <div
                                className={styles.deleteButton}
                                onClick={async () => {
                                    await deleteSection(section);
                                    message.success("Section Deleted");
                                    dispatch(closeSectionDeleteModal());
                                }}
                            >
                                Delete
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
            <Modal showModal={isSectionCreateModalOpen} alignItems={"center"} onClose={() => dispatch(closeSectionCreateModal())}>
                <div className={styles.sectionsListModal} onClick={(e) => e.stopPropagation()}>
                    <div className={styles.sectionsListHeading}>Add Section Title</div>
                    <div className={styles.titleInputContent}>
                        <input
                            className={styles.titleInput}
                            onClick={(e) => e.stopPropagation()}
                            placeholder="Enter Title"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                        />
                        <div className={styles.buttonGroup}>
                            <div className={styles.cancelButton} onClick={() => dispatch(closeSectionCreateModal())}>
                                Cancel
                            </div>
                            <div
                                className={styles.deleteButton}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    if (title.length > 0) {
                                        addNewLinkWithNewNonStoreSection(title);
                                        dispatch(closeSectionCreateModal());
                                        message.success("Added link to New Section");
                                    } else {
                                        message.info("Section Title is empty");
                                    }
                                }}
                            >
                                Done
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default index;
