// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Posts_postsContainer__p2s8i {\n    margin-top: 2.1875em;\n    margin-bottom: 2.1875em;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n}\n\n.Posts_qrCode__yu6Om {\n    height: 6em;\n    width: 6em;\n}\n\n.Posts_text__9CstI {\n    font-size: 1.25rem;\n    font-family: Gilroy-SemiBold;\n    height: 1.4em;\n    margin-top: 0.5em;\n    width: 80%;\n    text-align: center;\n}\n", "",{"version":3,"sources":["webpack://./src/components/Builder/LeftContainer/Posts/index.module.css"],"names":[],"mappings":"AAAA;IACI,oBAAoB;IACpB,uBAAuB;IACvB,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA;IACI,WAAW;IACX,UAAU;AACd;;AAEA;IACI,kBAAkB;IAClB,4BAA4B;IAC5B,aAAa;IACb,iBAAiB;IACjB,UAAU;IACV,kBAAkB;AACtB","sourcesContent":[".postsContainer {\n    margin-top: 2.1875em;\n    margin-bottom: 2.1875em;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n}\n\n.qrCode {\n    height: 6em;\n    width: 6em;\n}\n\n.text {\n    font-size: 1.25rem;\n    font-family: Gilroy-SemiBold;\n    height: 1.4em;\n    margin-top: 0.5em;\n    width: 80%;\n    text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"postsContainer": "Posts_postsContainer__p2s8i",
	"qrCode": "Posts_qrCode__yu6Om",
	"text": "Posts_text__9CstI"
};
export default ___CSS_LOADER_EXPORT___;
