import { useRef, useState } from "react";
import { message } from "antd";
import { Cropper, getCoordinates, getCanvas, CircleStencil, ImageRestriction } from "react-advanced-cropper";
import uploadImg from "../../../assets/uploadImg.png";
import closeIcon from "../../../assets/modalClose.png";
import "react-advanced-cropper/dist/style.css";
import "react-advanced-cropper/dist/themes/corners.css";
import styles from "./CropperModal.module.css";
import React from "react";

const CropperModal = ({ uploadImage, closeModal, uploadRef }) => {
    const [image, setImage] = useState(null);
    const [fileName, setFileName] = useState();
    const [coordinates, setCoordinates] = useState({});
    const cropperRef = useRef(null);
    const onUpload = () => {
        if (uploadRef.current) {
            uploadRef.current.click();
        }
    };
    const onLoadImage = (event) => {
        const { files } = event.target;
        const MIN_DIMENSION = 150;
        setFileName(files[0].name);
        if (files && files[0]) {
            const reader = new FileReader();
            reader.addEventListener("load", () => {
                const imageElement = new Image();
                const imageUrl = reader.result?.toString() || "";
                imageElement.src = imageUrl;
                imageElement.addEventListener("load", (e) => {
                    const { naturalWidth, naturalHeight } = e.currentTarget;
                    if (naturalWidth < MIN_DIMENSION || naturalHeight < MIN_DIMENSION) {
                        message.error("Image must be at least 150 x 150 pixels.");
                        return setImage(null);
                    }
                    if (Number(files?.size / (1024 * 1024)) > 5) {
                        message.error("Maximum image size(5MB) exceeded");
                        return;
                    }
                });
            });
            reader.readAsDataURL(files[0]);
            const blob = URL.createObjectURL(files[0]);
            setImage({
                src: blob,
                type: files[0].type
            });
        }
        event.target.value = "";
    };
    const onCrop = () => {
        if (cropperRef.current) {
            setCoordinates(() => ({
                ...cropperRef.current.getCoordinates()
            }));
            const canvas = cropperRef.current?.getCanvas();
            if (canvas) {
                canvas.toBlob((blob) => {
                    const myFile = new File([blob], fileName, {
                        type: blob.type
                    });
                    if (myFile) {
                        uploadImage(myFile);
                    }
                }, "image/jpeg");
            }
            setFileName("");
            closeModal();
        }
    };
    return (
        <div className={styles.container} onClick={(e) => e.stopPropagation()}>
            <div className={styles.heading}>
                {image ? "Move and Scale" : "Upload Image"}
                <img className={styles.closeIcon} src={closeIcon} alt="cross Icon" onClick={closeModal} />
            </div>

            {image ? (
                <div className={styles.cropper}>
                    <Cropper
                        ref={cropperRef}
                        className={styles.crop}
                        stencilComponent={CircleStencil}
                        stencilProps={{
                            aspectRatio: 1 / 1,
                            movable: true,
                            resizable: true,
                            grid: true
                        }}
                        defaultSize={{
                            width: 500,
                            height: 500
                        }}
                        imageRestriction={ImageRestriction.fitArea}
                        src={image && image.src}
                    />
                </div>
            ) : (
                <div className={`${styles.cropper}  ${styles.uploadImage}`} onClick={onUpload}>
                    <img src={uploadImg} alt="upload icon " style={{ height: "30px", width: "30px", marginBottom: "15px" }} />
                    <p>Select file to upload</p>
                    <input ref={uploadRef} accept=".png, .jpg, .jpeg" type="file" onChange={onLoadImage} />
                </div>
            )}

            <div>
                <div
                    style={{
                        alignSelf: "center",
                        margin: "10px",
                        display: "flex",
                        gap: "13px",
                        justifyContent: "space-around"
                    }}
                >
                    <button className={styles.button} onClick={closeModal}>
                        Cancel
                    </button>
                    <button
                        className={`${styles.button}  ${styles.UploadBtn}`}
                        onClick={onCrop}
                        styles={image ? {} : { opacity: "0.2", cursor: "not-allowed" }}
                    >
                        Upload
                    </button>
                </div>
            </div>
        </div>
    );
};
export default CropperModal;
