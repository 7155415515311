import { combineReducers } from "@reduxjs/toolkit";
import authReducer from "./slice/authSlice";
import analyticsReducer from "./slice/analyticsSlice";
import dashboardReducer from "./slice/dashboardSlice";
import wallReducer from "./slice/wallSlice";

const rootReducer = combineReducers({
    auth: authReducer,
    analytics: analyticsReducer,
    dashboard: dashboardReducer,
    wall: wallReducer
});

export default rootReducer;
