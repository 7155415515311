import { Row, Col } from "antd";
import LeftScreen from "./SignUpLeftScreen/LeftScreen";
import RightScreen from "./SignUpRightScreen/RightScreen";
import "./signUp.scss";
import { useEffect } from "react";
import { MixpanelEvents } from "../../constants/mixpanelEvents";
import { addEvent } from "../../Services/mixpanel";

const SignUp = () => {
    useEffect(() => {
        addEvent({}, MixpanelEvents.LOADED_SIGNUP_PAGE);
    }, []);
    return (
        <div className="signIn-container">
            <Row>
                <Col span={12}>
                    <LeftScreen />
                </Col>
                <Col span={12}>
                    <RightScreen />
                </Col>
            </Row>
        </div>
    );
};

export default SignUp;
