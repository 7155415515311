import React, { useState, useEffect } from "react";
import styles from "./index.module.css";
import errorImg from "../../../assets/wall-icons/errorImg.gif";
import faymLogo from "../../../assets/wall-icons/faym.png";
import { getAPIData } from "../../../Services/utils";

function Index() {
    const [data, setData] = useState([]);

    function getRedirectLink() {
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;

        if (/android/i.test(userAgent)) {
            return "https://play.google.com/store/search?q=faym&c=apps&hl=en";
        }

        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            return "https://apps.apple.com/in/app/faym/id6479986552";
        }

        return "https://faym.co/login";
    }

    function handleSignUpClick() {
        const link = getRedirectLink();
        window.location.href = link;
    }

    function handleCardClick(userName) {
        window.location.href = `${process.env.REACT_APP_URL}/i/${userName}`;
    }

    const getData = async () => {
        try {
            const resp = await getAPIData({ url: "v2/wall/usernotfound" });
            setData(resp.data);
        } catch (err) {
            console.error(err);
        }
    };

    useEffect(() => {
        getData();
    }, []);

    return (
        <div className={styles.outerContainer}>
            <div className={styles.innerContainer}>
                <div className={styles.imgContainer}>
                    <img src={faymLogo} alt="faymlogo" />
                </div>
                <img src={errorImg} alt="Error" className={styles.errorImg} />
                <div className={styles.heading}>The page you’re looking for can't be found.</div>
                <div className={styles.signUpContainer}>
                    <p>Want to monetize your content with Faym?</p>
                    <button onClick={handleSignUpClick}>Sign Up Now</button>
                </div>
                <p className={styles.reelHeading}>In the meantime, explore our amazing creators.</p>
                <div className={styles.scroller}>
                    {data?.map((item, index) => (
                        <div key={index} className={styles.card} onClick={() => handleCardClick(item.userName)}>
                            <video src={item.url} alt={item.userName} className={styles.video} muted controls={false} loop autoPlay />
                            <div className={styles.overlay}>
                                <img src={item.profilePic} alt={item.userName} className={styles.profilePic} />
                                <div className={styles.textContainer}>
                                    <p className={styles.userName}>@{item.userName}</p>
                                    <p className={styles.type}>{item.type}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default Index;
