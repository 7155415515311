import React from "react";
import styles from "./index.module.css";
import Preview from "./Preview";
import { useSelector } from "react-redux";
import { publishFaymWall } from "../../../Services/apiServices/publishService";

const index = () => {
    const { user } = useSelector((state) => state.root.auth);
    const { publishButtonEnabled } = useSelector((state) => state.root.dashboard);
    return (
        <div className={styles.rightContainer}>
            <Preview />
            <div className={styles.publishContainer}>
                <div className={styles.publish}>
                    <div className={styles.dropdownContainer}>
                        <div className={styles.dropdown}></div>
                    </div>
                    <div
                        className={styles.dropdownContent}
                        onClick={() => {
                            let wallUrl = `${process.env.REACT_APP_URL}/i/${user.userName}`;
                            window.open(wallUrl, "_blank");
                        }}
                    >
                        View Faym Wall
                    </div>
                    <div
                        className={styles.publishBtn}
                        style={{
                            background: publishButtonEnabled ? "#E9474C" : "#FFC3C5",
                            cursor: publishButtonEnabled ? "pointer" : "not-allowed"
                        }}
                        onClick={publishButtonEnabled ? async () => await publishFaymWall() : () => {}}
                    >
                        Publish Now
                    </div>
                </div>
            </div>
        </div>
    );
};

export default index;
