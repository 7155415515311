import React, { useEffect, useState } from "react";
import { Col, Radio, Row, Table } from "antd";
import { getAPIData, getLocalData } from "../../../Services/utils";
const columns = [
    {
        dataIndex: "Location",
        key: "Location"
    },
    {
        title: "Engagements",
        dataIndex: "Clicks",
        key: "Clicks",
        width: 200
    }
];
const TopLocations = ({ userId }) => {
    const [loading, setLoading] = useState(false);
    const [locationData, setLocationData] = useState([]);
    const [spaceName, setSpaceName] = useState("country");
    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 4
        }
    });
    const onChange = ({ target: { value } }) => {
        setSpaceName(value);
    };
    const options = [
        { label: "Country", value: "country" },
        { label: "City", value: "city" }
    ];
    const getDataByLocation = async () => {
        try {
            setLoading(true);
            const resp = await getAPIData({
                url: "api/analytics/" + spaceName + `?id=${userId}`
            });
            resp.data.sort((a, b) => b?.clickCounts - a?.clickCounts);
            setLocationData(resp.data);
        } catch (err) {
            console.error(err);
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        if (userId) getDataByLocation();
    }, [spaceName, userId]);
    const handleTableChange = (pagination, filters, sorter) => {
        setTableParams({
            pagination,
            filters,
            ...sorter
        });

        if (pagination.pageSize !== tableParams.pagination?.pageSize) {
            setLocationData([]);
        }
    };
    const convertedData = locationData?.map((item, index) => {
        return {
            key: (index + 1)?.toString(),
            Clicks: item?.clickCounts,
            Location: item?.cityName || item?.countryName
        };
    });
    return (
        <div className="top-location-container">
            <Row className="top-location-header-row">
                <Col span={12}>
                    <p className="top-location-heading">Top Locations</p>
                </Col>
                <Col span={12} className="top-location-filter">
                    <Radio.Group options={options} onChange={onChange} value={spaceName} optionType="button" />
                </Col>
            </Row>
            <Row className="top-location-table">
                <Table
                    columns={columns}
                    dataSource={convertedData}
                    pagination={tableParams?.pagination}
                    onChange={handleTableChange}
                    key={convertedData?._id}
                />
            </Row>
        </div>
    );
};

export default TopLocations;
