import { Button, Modal } from "antd";
import React, { useEffect, useState } from "react";
import "./index.css";
import { useNavigate } from "react-router-dom";
const TimerModal = ({ show, setShow = () => {}, timer, setTimer = () => {} }) => {
    const navigate = useNavigate();
    useEffect(() => {
        const timeOut = setTimeout(() => {
            setTimer((p) => p - 1);
        }, 1000);
        if (!timer) {
            clearTimeout(timeOut);
            setShow(false);
            navigate("/dashboard");
        }
    }, [timer]);

    return (
        <div>
            <Modal open={show} centered okText="Ok" wrapClassName="custom-modal" footer={null}>
                <p className="congratulations-text-modal">Congratulations</p>
                <p className="congratulations-related-text">You have made it &#128525;</p>
            </Modal>
        </div>
    );
};

export default TimerModal;
