import React from "react";
import infoIcon from "../../../assets/icons/popupInfo.svg";
import { Tooltip } from "antd";

const index = ({ info }) => {
    return (
        <Tooltip
            title={
                <span
                    dangerouslySetInnerHTML={{
                        __html: `<p>${info}</p>`
                    }}
                />
            }
        >
            <img onClick={(e) => e?.stopPropagation()} draggable={false} src={infoIcon} alt="info-icon" />
        </Tooltip>
    );
};

export default index;
