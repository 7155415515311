import axios from "axios";
import { createUserWithEmailAndPassword, signInWithEmailAndPassword } from "firebase/auth";
import auth from "../config/firebaseConfig";
// import { validLinksCount } from "../components/DashBoard/utils";
import appsIcon from "../assets/icons/templateIcons/apps.png";
import instaIcon from "../assets/icons/templateIcons/instagram.png";
import othersIcon from "../assets/icons/templateIcons/others.png";
import ytIcon from "../assets/icons/templateIcons/youtube.png";
import youtubeIcon from "../assets/youtubeIcon.png";
import storeIcon from "../assets/storeIconBg.png";
import { store } from "./../redux/store";
import { v4 as uuidv4 } from "uuid";
import snap from "../assets/wall-icons/snap.png";
import fb from "../assets/wall-icons/fb.png";
import insta from "../assets/wall-icons/insta.png";
import linkedin from "../assets/wall-icons/LinkedIn.png";
import pinterest from "../assets/wall-icons/Pinterest.png";
import reddit from "../assets/wall-icons/reddit.png";
import telegram from "../assets/wall-icons/Telegram.png";
import yt from "../assets/wall-icons/YT.png";
import whatsapp from "../assets/wall-icons/WhatsApp.png";
import gmail from "../assets/wall-icons/gmail.png";
import yahoo from "../assets/wall-icons/yahoo.png";
import outlook from "../assets/wall-icons/outlook.png";
import twitter from "../assets/wall-icons/Twitter.png";
import instagramIcon from "../assets/instagramIcon.png";
export const socialMediaImages = {
    instagram: {
        image: insta,
        backgroundColor: "#F96381",
        title: "Follow me on Instagram"
    },
    facebook: {
        image: fb,
        backgroundColor: "#3B5999",
        title: "Follow me on Facebook"
    },
    twitter: {
        image: twitter,
        backgroundColor: "#2CA3FF",
        title: "Check out My Latest Tweets"
    },
    snapchat: {
        image: snap,
        backgroundColor: "#EEE400",
        title: "Add me on Snapchat"
    },
    youtube: {
        image: yt,
        backgroundColor: "#DA3838",
        title: " Subscribe to My YouTube "
    },
    telegram: {
        image: telegram,
        backgroundColor: "#44A6DA",
        title: "Join me on Telegram"
    },
    linkedin: {
        image: linkedin,
        backgroundColor: "#1175AD",
        title: "Connect with me on LinkedIn"
    },
    pinterest: {
        image: pinterest,
        backgroundColor: "#E54141",
        title: " Check Out My Pinterest Board"
    },
    reddit: {
        image: reddit,
        backgroundColor: "#DF7C49",
        title: "Follow me on Reddit"
    },
    whatsapp: {
        image: whatsapp,
        backgroundColor: "#25D366",
        title: "Get in touch"
    },
    gmail: {
        image: gmail,
        backgroundColor: "#EA4335",
        title: "Get in touch"
    },
    outlook: {
        image: outlook,
        backgroundColor: "#0079D8",
        title: "Get in touch"
    },
    yahoo: {
        image: yahoo,
        backgroundColor: "#B153C9",
        title: "Get in touch"
    },
    wa: {
        image: whatsapp,
        backgroundColor: "#25D366",
        title: "Ping me on WhatsApp"
    },
    t: {
        image: telegram,
        backgroundColor: "#44A6DA",
        title: "Join me on Telegram"
    }
};

store.subscribe(listener);
function select(state) {
    return state.root.auth;
}
let userData;
function listener() {
    userData = select(store.getState());
}

const linkRegex = /^https?:\/\/([\da-z.-]+)\.([a-z.]{2,6})(\/[^\s]*)?$/;
export const emailRegex = /^([\w.-]+@[a-z.-]+\.[a-z]{2,6})$/i;
const socialMediaRegex =
    /^https?:\/\/(www\.)?(facebook\.com|fb\.me|fb\.watch|pin\.it|in\.pinterest\.com|t\.me|telegram\.me|youtube\.com|wa\.me|wa\.link|twitter\.com|linkedin\.com|instagram\.com|snapchat\.com|story\.snapchat\.com|lens\.snapchat\.com|reddit\.com|chat\.whatsapp\.com|whatsapp\.com|api\.whatsapp\.com)\/([a-zA-Z0-9_.-]+)(\/[^\/]+)?\/?$/;
const youtubeRegex = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=|shorts\/|channel\/|user\/\w+|@)([^#&?/]+).*/;

const youtubeShortsRegex = /(https?\:\/\/)?(www\.)?(youtube\.com)\/(shorts).*/;
const instaReelsRegex = /https?:\/\/(?:www\.)?instagram\.com\/(reel|reels|p)\/[^/?#]+/;
export const getInitials = (fullName) => {
    if (fullName.length > 0) {
        const nameArray = fullName?.split(" ");
        let initials = "";
        if (nameArray?.length > 1) {
            initials += nameArray[0]?.charAt(0);
            initials += nameArray[nameArray?.length - 1]?.charAt(0);
        } else if (nameArray?.length > 0) {
            initials += nameArray[0]?.charAt(0);
        }
        return initials;
    }
    return;
};
export const scrapperUrl = process.env.REACT_APP_SCRAPPER_URL + "/parse?url=";
export const baseUrl = process.env.REACT_APP_API_URL;

export const saveDataToLocal = (data, name) => {
    localStorage.setItem(name, JSON.stringify(data));
};
export const getLocalData = (name) => {
    let data = localStorage.getItem(name);
    data = data === "undefined" ? "" : data;
    return data ? JSON.parse(data) : "";
};
export const getAPIData = (data) => {
    return axios.get(baseUrl + "/" + data?.url, {
        params: {
            ...data.data
        },
        headers: {
            Authorization: `Bearer ${data.token ? data.token : userData?.idToken}`
            // refreshToken: `${userData?.refreshToken}`
        }
    });
};

export const postAPIData = (data, bool) => {
    return axios.post(
        baseUrl + "/" + data?.url,
        {
            ...data?.data
        },
        {
            headers: {
                Authorization: bool ? `Bearer ${userData?.idToken}` : ""
                // refreshToken: `${userData?.refreshToken}`
            }
        }
    );
};

export const postAPI = (data, bool) => {
    const form = data?.data;
    return axios(
        baseUrl + "/" + data?.url,
        {
            form
        },
        {
            headers: {
                Authorization: bool ? `Bearer ${getLocalData("IdToken")?.idToken}` : "",
                "Content-Type": `multipart/form-data; boundary=${form._boundary}`
            }
        }
    );
};

export const LoginUesr = (data) => {
    return signInWithEmailAndPassword(auth, data?.email, data?.password);
};

export const identifySocialMedia = (link) => {
    const socialMediaDomains = {
        "facebook.com": "Facebook",
        "twitter.com": "Twitter",
        "instagram.com": "Instagram",
        "youtube.com": "YouTube",
        "amzn.to": "Amazon",
        amazon: "Amazon"
    };

    for (const domain in socialMediaDomains) {
        if (link.includes(domain)) {
            return socialMediaDomains[domain];
        }
    }
    return "";
};

export const widgetTemplates = [
    {
        headingDesc: "Store",
        headingTitle: "Store",
        leftIcon: "storeIcon",
        templateType: "store",
        title: "Store",
        info: "List all product recommendations",
        iconImg: storeIcon,
        iconName: "Store",
        tag: "new",
        disable: "false",
        display: true
    },
    // {
    //     headingDesc: "YouTube Auth",
    //     headingTitle: "YouTube",
    //     leftIcon: "youtubeIcon",
    //     templateType: "youtube",
    //     title: "Youtube",
    //     info: "Automatically update Latest Videos & Shorts",
    //     iconImg: youtubeIcon,
    //     iconName: "Youtube",
    //     tag: "comingSoon",
    //     display: true
    // },
    {
        headingDesc: "Instagram",
        headingTitle: "Instagram",
        leftIcon: "youtubeIcon",
        templateType: "instagram",
        title: "Instagram",
        info: "Automated Instagram powered with Insta DMs",
        iconImg: instagramIcon,
        iconName: "Instagram",
        tag: "new",
        disable: false,
        display: true
    },
    {
        headingDesc: "Apps",
        headingTitle: "Apps",
        leftIcon: "addSocialIcon",
        templateType: "application",
        title: "My Recommended Apps",
        info: "Add your fav App Store or Play Store app recommendations here.",
        iconImg: appsIcon,
        iconName: "Applications",
        display: false
    },
    {
        headingDesc: "Youtube Shorts",
        headingTitle: "Youtube Shorts",
        leftIcon: "youtubeIcon",
        templateType: "youtube_shorts",
        title: "Must see YouTube Shorts",
        iconImg: ytIcon,
        iconName: "YT Shorts",
        display: false
    },
    {
        headingDesc: "Instagram Reels",
        headingTitle: "Instagram Reels",
        leftIcon: "affiliateIcon",
        templateType: "insta_reels",
        title: "Featured Instagram Reels",
        iconImg: instaIcon,
        iconName: "Insta Reels",
        display: false
    },
    {
        headingDesc: "Others",
        headingTitle: "Other Links",
        leftIcon: "affiliateIcon",
        templateType: "other",
        title: "Explore More",
        iconImg: othersIcon,
        iconName: "Others",
        display: false
    },
    {
        id: uuidv4(),
        headingDesc: "Affiliate Links",
        headingTitle: "Affiliate Links",
        leftIcon: "affiliateIcon",
        templateType: "affiliate_link",
        title: "My top Favourites",
        display: false,
        links: []
    }
];

export const SignUpUser = (data) => {
    return createUserWithEmailAndPassword(auth, data?.email, data?.password);
};

export const isValidLink = (link, templateType) => {
    if (templateType === "social_media") {
        if (link.includes("youtube") || link.includes("youtu.be")) {
            return youtubeRegex.test(link);
        }
        if (emailRegex.test(link)) {
            return true;
        }
        return socialMediaRegex.test(link);
    } else if (templateType === "insta_reels") {
        return instaReelsRegex.test(link);
    } else if (templateType === "youtube_shorts") {
        return youtubeShortsRegex.test(link);
    }
    if (emailRegex.test(link)) {
        return true;
    }
    return linkRegex.test(link);
};

// export const removeEmptyTemplates = (arr) => {
//     return arr.filter(
//         (e) =>
//             ["store", "social_media"].includes(e?.templateType) ||
//             (["youtube", "application", "youtube_shorts", "insta_reels", "other", "affiliate_link"].includes(e?.templateType) &&
//                 validLinksCount(e.links) > 0)
//     );
// };

export const getScrapperAPI = (data) => {
    return axios.get(process.env.REACT_APP_SCRAPPER_URL + "/" + data, {
        headers: { "api-key": process.env.REACT_APP_SCRAPPER_API_KEY }
    });
};

export const isValidYouTubeVideoURL = (url) => {
    if (!url) return false;
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=|shorts\/)([^#&?/]*).*/;

    const match = url.match(regExp);
    return match && match[2].length === 11;
};

export const deepCopy = (obj) => {
    if (obj === null || typeof obj !== "object") {
        return obj;
    }

    if (Array.isArray(obj)) {
        const arrCopy = [];
        for (const item of obj) {
            arrCopy.push(deepCopy(item));
        }
        return arrCopy;
    }

    const objCopy = {};
    for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
            objCopy[key] = deepCopy(obj[key]);
        }
    }
    return objCopy;
};
