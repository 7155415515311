import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
const firebaseConfig = {
    apiKey: "AIzaSyCprsqdh6gmVeeRr1iCVJZmXqJoHeGe2R8",
    authDomain: "faym-wall-builder.firebaseapp.com",
    projectId: "faym-wall-builder",
    storageBucket: "faym-wall-builder.appspot.com",
    messagingSenderId: "12137555081",
    appId: "1:12137555081:web:50ba80e362bd9a52bb5258",
    measurementId: "G-06SC91HS9R"
};
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
export default auth;
