import { Navigate } from "react-router-dom";
import { message } from "antd";
import { useSelector } from "react-redux";
const ProtectedRoute = ({ children }) => {
    const { user } = useSelector((state) => state.root.auth);
    if (!user) {
        message?.error("You are not authorized");
        return <Navigate to="/" />;
    }
    return children;
};

export default ProtectedRoute;
