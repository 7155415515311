import React from "react";
import styles from "./index.module.css";
import mobileFrame from "../../../../assets/mobileFrame.png";
import { useSelector } from "react-redux";
import notch from "../../../../assets/mobileFrameNotch.png";
import LinkInBio from "./LinkInBio";
import Store from "./Store";
import BottomNav from "./BottomNav";
import ProductPage from "./Store/CollectionPage";

const index = () => {
    const { selectedHeader, selectedCollection } = useSelector((state) => state.root.dashboard);
    return (
        <div className={styles.mobileFrameContainer}>
            <img src={mobileFrame} className={styles.mobileFrame} />
            <div className={styles.previewStyle}>
                <div className={styles.mobileContainer}>
                    <img src={notch} alt="iphone notch" className={styles.mobileNotch} />
                    <div className={styles.middleSection}>
                        {selectedCollection ? (
                            <ProductPage />
                        ) : selectedHeader === "Posts" || selectedHeader === "Collections" ? (
                            <Store />
                        ) : (
                            <LinkInBio />
                        )}
                        {!selectedCollection && <BottomNav />}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default index;
