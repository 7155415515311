import mobileDashboard from "./../../assets/mobileDashboard.jpg";
import mobileDashboardBg from "./../../assets/mobileDashboardBg.png";
import mobileDashboardContent from "./../../assets/mobileDashboardContent.png";

const MobileDashboard = () => {
    const clickHandler = () => {
        window.open("https://wa.me/918368393419?text=Hi%2C%0APlease%20help%20me%20build%20my%20FaymWall.%0AThanks", "_blank");
    };
    return (
        <div>
            <div
                style={{
                    backgroundImage: `url(${mobileDashboard})`,
                    backgroundRepeat: "no-repeat",
                    minHeight: "100svh",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    display: "flex",
                    justifyContent: "center"
                }}
            >
                <div
                    style={{
                        backgroundImage: `url(${mobileDashboardBg})`,
                        width: "25.5rem",
                        height: "26.34rem",
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        margin: "auto",
                        display: "flex",
                        flexDirection: "column"
                    }}
                >
                    <div
                        style={{
                            backgroundImage: `url(${mobileDashboardContent})`,
                            width: "19rem",
                            height: "11.97rem",
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                            margin: "auto",
                            marginTop: "5rem"
                        }}
                    ></div>
                    <div
                        style={{
                            background: "black",
                            color: "#FFF",
                            textAlign: "center",
                            fontFamily: "Sora",
                            fontSize: "14px",
                            fontStyle: "normal",
                            fontWeight: "400",
                            lineHeight: "24px",
                            width: "242px",
                            height: "44px",
                            borderRadius: "5px",
                            margin: "auto",
                            marginBottom: "5rem",
                            padding: "9px 0px 9px 0px"
                        }}
                        onClick={clickHandler}
                    >
                        Chat with us
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MobileDashboard;
