import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { addEvent } from "../../Services/mixpanel";
import { MixpanelEvents } from "../../constants/mixpanelEvents";

const ShortUrlComponent = () => {
    const { code } = useParams();
    const [url, setUrl] = useState(null);
    const [affiliateUrl, setAffiliateUrl] = useState(null);

    const getAsin = (url) => {
        try {
            const asinRegex = /\/dp\/([A-Z0-9]{10})/;
            const match = url.match(asinRegex);
            if (match && match[1]) {
                return match[1];
            } else {
                throw new Error("ASIN not found in the URL");
            }
        } catch (error) {
            console.error("Error extracting ASIN:", error.message);
            return null;
        }
    };
    const getUrlScheme = (url) => {
        const ua = navigator.userAgent || navigator.vendor;
        if (/iPad|iPhone|iPod/.test(ua)) {
            if (url.includes("flipkart")) {
                return `flipkart://fk.dl/de_wv_CL%7Casfasfd_--_${url.replace("https://", "")}`;
            } else if (url.includes("amazon")) {
                const query = url.split("?")[1];
                const asin = getAsin(url);
                return `com.amazon.mobile.shopping.web://amazon.in/dp/${asin}?${query}`;
            }
        } else if (/Mobile|mobile/.test(ua)) {
            return `intent:${url}#Intent;end`;
        }
        return url;
    };

    const openInApp = async (url) => {
        const appLink = getUrlScheme(url);
        // const target = appLink.startsWith("https://") ? "_blank" : "_self";
        const target = "_self";
        const newWindow = window.open(appLink, target);
        newWindow.focus();
    };

    const sendEventOnAffiliate = (url) => {
        setAffiliateUrl(url);
    };

    const fetchFullUrl = async (code) => {
        const backendUrl = process.env.REACT_APP_API_URL || "https://backend.faym.co";
        axios.get(`${backendUrl}/url/expand?url=${code}`).then((res) => {
            if (res.data) {
                addEvent(res.data, MixpanelEvents.SHARED_PRODUCT_PAGE_OPENED);
                sendEventOnAffiliate(res?.data?.affiliateUrl);
                if (
                    res?.data?.affiliateUrl?.includes("meesho.onelink.me") ||
                    res?.data?.affiliateUrl?.includes("virgio") ||
                    res?.data?.affiliateUrl?.includes("milton") ||
                    res?.data?.affiliateUrl?.includes("dennislingo") ||
                    res?.data?.affiliateUrl?.includes("foxtale") ||
                    res?.data?.affiliateUrl?.includes("theyellowdwelling")
                ) {
                    setUrl(res.data.affiliateUrl);
                } else {
                    setUrl(res.data.fullUrl);
                }
            }
            return res.data;
        });
    };

    useEffect(() => {
        fetchFullUrl(code);
    }, []);

    useEffect(() => {
        setTimeout(() => {
            if (url && url.length) {
                openInApp(url);
            }
        }, 100);
    }, [url]);

    return (
        <div>
            {affiliateUrl ? (
                <iframe title="Embedded Content" src={affiliateUrl} width="600" height="400" frameBorder="0" allowFullScreen />
            ) : (
                <></>
            )}
        </div>
    );
};

export default ShortUrlComponent;
